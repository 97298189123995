import config from "../../config";
import { authHeader, handleResponse } from "../../_helpers";
import { encData } from "../../_helpers/_helperFunctions";

export const holidayService = {
  getAllHolidays,
  getHolidayById,
  create,
  update,
  deleteHoliday,
};

function getAllHolidays() {
  const requestOptions = { method: "GET", headers: authHeader() };
  console.log("requestOptions: ", requestOptions);
  return fetch(`${config.apiUrl}/api/v1/holidays`, requestOptions).then(
    handleResponse
  );
}

function getHolidayById(id) {
  const requestOptions = { method: "GET", headers: authHeader() };
  return fetch(`${config.apiUrl}/api/v1/holidays/${id}`, requestOptions).then(
    handleResponse
  );
}

function create(data) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: encData(data),
  };
  return fetch(`${config.apiUrl}/api/v1/holidays/create`, requestOptions).then(
    handleResponse
  );
}

function update(data) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: encData(data),
  };
  return fetch(`${config.apiUrl}/api/v1/holidays/create`, requestOptions).then(
    handleResponse
  );
}

function deleteHoliday(holidayId, currentUserId) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: encData({ holidayId, currentUserId }),
  };
  return fetch(`${config.apiUrl}/api/v1/holidays/delete`, requestOptions).then(
    handleResponse
  );
}
