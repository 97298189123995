export const COUNSELLOR_TOTAL_LOCATIONS = [
  { value: "BR", label: "BR" },
  { value: "W", label: "W" },
  { value: "LBS", label: "LBS" },
  { value: "IRCC", label: "IRCC" },
  { value: "KTH", label: "KTH" },
  { value: "Pre-App", label: "Pre-App" },
  { value: "CL", label: "CL" },
  { value: "SM", label: "SM" },
  { value: "ALSO", label: "ALSO" },
  { value: "Other", label: "Other" },
];
