import config from "../../config";
import { authHeader, handleResponse } from "../../_helpers";
import { authenticationService } from "../authentication.service";
import { encData } from "../../_helpers/_helperFunctions";
import axios from "axios";
export const contentLibraryService = {
  getAll,
  getContentLibaryCategroryById,
  create,
  update,
  deleteContentCategory,
  getFilesByCategoryId,
  uploadFileToContentLibrary,
  getSignedLinkFromKey,
  deleteFile,
  updateFileDetails,
  saveFileInfo,
  reorderFiles,
};

function getAll() {
  const requestOptions = { method: "GET", headers: authHeader() };
  console.log("requestOptions: ", requestOptions);
  return fetch(
    `${config.apiUrl}/api/v1/content-library-categories`,
    requestOptions
  ).then(handleResponse);
}

function getContentLibaryCategroryById(id) {
  const requestOptions = { method: "GET", headers: authHeader() };
  return fetch(
    `${config.apiUrl}/api/v1/content-library-categories/${id}`,
    requestOptions
  ).then(handleResponse);
}

function create(formData) {
  const currentUser = authenticationService.currentUserValue;
  const requestOptions = {
    method: "POST",
    headers: {
      Authorization: `Bearer ${currentUser.token}`,
      isenc: localStorage.getItem("isenc")
        ? parseInt(localStorage.getItem("isenc"))
        : 0,
    },
    body: formData,
  };
  return fetch(
    `${config.apiUrl}/api/v1/content-library-categories/create`,
    requestOptions
  ).then(handleResponse);
}

function update(data) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: encData(data),
  };
  return fetch(
    `${config.apiUrl}/api/v1/content-library-categories/create`,
    requestOptions
  ).then(handleResponse);
}

function deleteContentCategory(categoryId, currentUserId) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: encData({ categoryId, currentUserId }),
  };
  return fetch(
    `${config.apiUrl}/api/v1/content-library-categories/delete`,
    requestOptions
  ).then(handleResponse);
}

function getFilesByCategoryId(id) {
  const requestOptions = { method: "GET", headers: authHeader() };
  return fetch(
    `${config.apiUrl}/api/v1/content-library-categories/getFiles/${id}`,
    requestOptions
  ).then(handleResponse);
}

// function uploadFileToContentLibrary(id,data){
//     const currentUser = authenticationService.currentUserValue;
//     const requestOptions = {
//         method: 'POST',
//         headers: {
//             Authorization: `Bearer ${currentUser.token}`,
//             isenc:localStorage.getItem('isenc')?parseInt(localStorage.getItem('isenc')):0
//         },
//         body: data
//     };
//     return fetch(`${config.apiUrl}/api/v1/content-library-categories/upload/${id}`, requestOptions).then(handleResponse);
// }

function uploadFileToContentLibrary(id, data) {
  const currentUser = authenticationService.currentUserValue;
  const headers = {
    Authorization: `Bearer ${currentUser.token}`,
    isenc: localStorage.getItem("isenc")
      ? parseInt(localStorage.getItem("isenc"))
      : 0,
  };
  return axios.post(
    `${config.apiUrl}/api/v1/content-library-categories/upload/${id}`,
    data,
    headers
  );
}

function getSignedLinkFromKey(data) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: encData(data),
  };
  return fetch(
    `${config.apiUrl}/api/v1/content-library-categories/get-file-url`,
    requestOptions
  ).then(handleResponse);
}

function deleteFile(data) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: encData(data),
  };
  return fetch(
    `${config.apiUrl}/api/v1/content-library-categories/delete-file`,
    requestOptions
  ).then(handleResponse);
}

function updateFileDetails(data) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: encData(data),
  };
  return fetch(
    `${config.apiUrl}/api/v1/content-library-categories/update-file-details`,
    requestOptions
  ).then(handleResponse);
}

function saveFileInfo(data) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: encData(data),
  };
  return fetch(
    `${config.apiUrl}/api/v1/content-library-categories/save-file-info`,
    requestOptions
  ).then(handleResponse);
}

function reorderFiles(data) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: encData(data),
  };
  return fetch(
    `${config.apiUrl}/api/v1/content-library-categories/reorder-files`,
    requestOptions
  ).then(handleResponse);
}
