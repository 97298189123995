import React, { Component } from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";

export default function IdleTimeOutModal({
  showModal,
  handleClose,
  handleLogout,
  remainingTime,
}) {
  return (
    <Modal isOpen={showModal} centered={true} toggle={handleClose}>
      <ModalHeader toggle={handleClose}>You Have Been Idle!</ModalHeader>
      <ModalBody>
        Your session is ending, do you want to extend the session?
      </ModalBody>
      <ModalFooter>
        <button className="btn btn-danger" onClick={handleLogout}>
          Logout
        </button>
        <button className="btn btn-success" onClick={handleClose}>
          Extend
        </button>
      </ModalFooter>
    </Modal>
  );
}
