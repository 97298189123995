import React, { Component } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";

export class EventStats extends Component {
  constructor(props) {
    super(props);

    this.state = {
      expandedRows: null,
    };
  }

  rowExpansionTemplate(data) {
    return (
      <>
        <div className="event-registrations-subtable">
          <h5>
            <strong>Registrations for {data.name}</strong>
          </h5>
          <DataTable
            value={data.registrationDetails}
            paginator
            className="p-datatable-responsive-demo"
            header="Responsive"
            paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
            rows={10}
            rowsPerPageOptions={[10, 20, 50, 100]}
          >
            <Column
              headerClassName="row-accessories"
              field="userName"
              header="User Name"
              sortable
            ></Column>
            <Column
              headerClassName="row-accessories"
              field="userEmail"
              header="User Email"
              sortable
            ></Column>
            <Column
              headerClassName="row-accessories"
              field="rating"
              header="Rating"
              sortable
            ></Column>
            <Column
              headerClassName="row-accessories"
              field="comment"
              header="Comment"
              sortable
            ></Column>
          </DataTable>
          <h5></h5>
        </div>
        {data.wishlistDetails.length > 0 && (
          <div className="event-registrations-subtable pt-3">
            <h5>
              <strong>WishList for {data.name}</strong>
            </h5>
            <DataTable
              value={data.wishlistDetails}
              paginator
              className="p-datatable-responsive-demo"
              header="Responsive"
              paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
              currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
              rows={10}
              rowsPerPageOptions={[10, 20, 50, 100]}
            >
              <Column
                headerClassName="row-accessories"
                field="userName"
                header="User Name"
                sortable
              ></Column>
              <Column
                headerClassName="row-accessories"
                field="userEmail"
                header="User Email"
                sortable
              ></Column>
            </DataTable>
            <h5></h5>
          </div>
        )}
      </>
    );
  }

  registrationCountBodyTemplt = (rowData) => {
    return rowData.registrationDetails.length;
  };

  wishlistCountBodyTemplt = (rowData) => {
    return rowData.wishlistDetails.length;
  };

  render() {
    return (
      <DataTable
        value={this.props.data}
        expandedRows={this.state.expandedRows}
        onRowToggle={(e) => this.setState({ expandedRows: e.data })}
        rowExpansionTemplate={this.rowExpansionTemplate}
        dataKey="_id"
        className="p-datatable-responsive-demo"
        header="Responsive"
        paginator
        paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
        rows={10}
        rowsPerPageOptions={[10, 20, 50, 100]}
      >
        <Column
          headerClassName="main_header"
          expander
          style={{ width: "3em" }}
        />
        <Column
          headerClassName="main_header"
          field="name"
          header="Event Name"
          sortable
        />
        <Column
          headerClassName="main_header"
          header="Registration Count"
          field="registrationDetails"
          sortable
          body={this.registrationCountBodyTemplt}
        />
        <Column
          headerClassName="main_header"
          header="WishList Count"
          field="wishlistDetails"
          sortable
          body={this.wishlistCountBodyTemplt}
        />
      </DataTable>
    );
  }
}

export default EventStats;
