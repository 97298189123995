import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import Preloader from "../../components/Preloader";
import HeaderAdmin from "../../components/HeaderAdmin";
import Sidebaar from "../../components/user/Sidebaar";
import FooterAdmin from "../../components/FooterAdmin";
import { setUserData } from "../../redux-store/action";
import { userService } from "../../_services/admin";
import { authenticationService } from "../../_services";
import { appointmentService } from "../../_services/staff/appointment.service";
import { Calendar } from "primereact/calendar";
import moment from "moment";
import MaskedInput from "react-text-mask";
import Select from "react-select";
import { Editor } from "primereact/editor";
import { NotificationManager } from "react-notifications";
import {
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";

import {
  capitalize,
  hasDaysPassed,
  holidayDateList,
} from "../../_helpers/_helperFunctions";
import { programType } from "../../_helpers";
import swal from "sweetalert";
function mapStateToProps(state) {
  return {
    holidayList: state.holidayList,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setUserData: (name) => {
      dispatch(setUserData(name));
    },
  };
}

function filterCounsellorsBasedOnfilterType(counsellorList, filterType) {
  switch (filterType) {
    case "BR":
      return counsellorList.filter((user) => {
        return user.counsellorType === "BR";
      });
    case "LBS":
      return counsellorList.filter((user) => {
        return user.counsellorType === "LBS";
      });
    case "IRCC":
      return counsellorList.filter((user) => {
        return user.counsellorType === "IRCC";
      });
    case "KTH":
      return counsellorList.filter((user) => {
        return user.counsellorType === "KTH";
      });
    case "W":
      return counsellorList.filter((user) => {
        return user.counsellorType === "W";
      });
    default:
      return counsellorList;
  }
  // if (filterType) {
  //   if (filterType === "BR") {
  //     return counsellorList.filter((user) => {
  //       return user.counsellorType === "BR";
  //     });
  //   } else {
  //     return counsellorList.filter((user) => {
  //       return user.counsellorType !== "BR";
  //     });
  //   }
  // } else {
  //   return counsellorList;
  // }
}

function getReasonLabel(reason) {
  switch (reason) {
    case "Employment-Services":
      return "ES";
    case "Youth-Programs":
      return "YJC";
    case "literacy-basic-skills":
      return "LBS";
    case "resource-information-centre":
      return "IRCC";
    case "keep-it-hot":
      return "KTH";
    default:
      return "ES";
  }
}

export class CounsellorAvailability extends Component {
  constructor(props) {
    super(props);

    this.state = {
      currentUser: authenticationService.currentUserValue,
      currentDepartmentName: authenticationService.currentDepartmentNameValue,
      userFromApi: null,
      loading: true,
      counsellorList: [],
      allCounsellor: [],
      date: new Date(),
      userModal: false,
      note: null,
      selectedUser: "",
      availabilities: [],
      allUesrs: [],
      selectedCode: null,
      bookingStatus: null,
      filterType: "",
      toAttend: null,
      counsellorId: "",
      counsellorTime: "",
      counsellorNote: null,
      showAvailableOnly: false,
      selectedAvalibilityId: "",
      updateAvailabilityArray: [],
      clickedItem: null,
      bookingData: {},
      bookingInfoPopup: false,
      cancelBookingPopup: false,
      activeTab: "1",
      repeatQuaterly: false,
      repeatQuaterlyModal: false,
    };
  }

  timing = [
    "9:00",
    "9:30",
    "10:00",
    "10:30",
    "11:00",
    "11:30",
    "1:00",
    "1:30",
    "2:00",
    "2:30",
    "3:00",
    "3:30",
    "4:00",
    "4:30",
    "5:00",
  ];
  componentDidMount() {
    console.log("tttttt", moment("09/18/2020").valueOf());
    const { currentUser } = this.state;
    userService.getUserById(currentUser.id).then((data) => {
      data.Data && this.props.setUserData(data.Data);
    });
    console.log(currentUser);
    this.getAllUsers();
    this.getAvailabilities();
  }

  toggleDetails = () => {
    this.setState({
      selectedUser: "",
      userModal: !this.state.userModal,
    });
  };

  visibilityFilter = (counsellorList, filter) => {
    let { availabilities } = this.state;
    let showAvailableOnly =
      typeof filter !== "undefined" ? filter : this.state.showAvailableOnly;
    if (showAvailableOnly && availabilities.length > 0) {
      return counsellorList.filter((user) => {
        let result = false;
        availabilities.forEach((availData) => {
          if (availData.counsellorId === user._id) {
            if (availData.availability.length > 0) {
              result = true;
            }
          }
        });
        return result;
      });
    } else {
      return counsellorList;
    }
  };

  filterUser = (filterType) => {
    this.setState((state) => {
      return {
        filterType: filterType,
        allCounsellor: filterCounsellorsBasedOnfilterType(
          state.counsellorList,
          filterType
        ),
      };
    });
  };

  toggleShowAvailableOnly = () => {
    this.setState((state) => {
      return {
        showAvailableOnly: !state.showAvailableOnly,
        allCounsellor: filterCounsellorsBasedOnfilterType(
          this.visibilityFilter(state.counsellorList, !state.showAvailableOnly),
          state.filterType
        ),
      };
    });
  };

  getBookingById = (id) => {
    appointmentService
      .getBookingById(id)
      .then((res) => {
        this.setState({
          bookingData: res.Data,
          counsellorNote: res.Data.notes,
        });
      })
      .catch((err) => console.log("Error: ", err));
  };

  editInput = (id, counsellor, time) => {
    console.log("ihihihhihhihiu", document.getElementById(id));

    if (document.getElementById(id).disabled == false) {
      document.getElementById(id).style.backgroundColor = "#e9ecef";
      document.getElementById(id).style.color = "black";
      document.getElementById(id).disabled = true;
      if (this.state.availabilities.length) {
        if (
          this.state.availabilities.filter(
            (elem) => elem.counsellorId === counsellor._id
          ).length
        ) {
          this.state.availabilities.forEach((item, index) => {
            if (item.counsellorId === counsellor._id) {
              const obj = this.state.availabilities;
              obj[index]["availability"].forEach((item2, i) => {
                if (item2.time === time) {
                  if (obj[index]["availability"].length > 1) {
                    obj[index]["availability"].splice(i, 1);
                    this.setState({
                      availabilities: obj,
                    });
                  } else {
                    obj[index]["availability"].splice(i, 1);
                    this.setState({
                      availabilities: obj,
                    });
                  }
                }
              });
            }
          });
        }
      }
      setTimeout(() => {
        console.log(this.state.availabilities);
      }, 2000);
    } else {
      document.getElementById(id).style.backgroundColor = "#a6ce39";
      document.getElementById(id).style.color = "white";
      document.getElementById(id).disabled = false;

      if (this.state.availabilities.length) {
        if (
          this.state.availabilities.filter(
            (elem) => elem.counsellorId === counsellor._id
          ).length
        ) {
          this.state.availabilities.forEach((item, index) => {
            if (item.counsellorId === counsellor._id) {
              const obj = this.state.availabilities;
              obj[index]["availability"].push({
                time: time,
              });
              this.setState({
                availabilities: obj,
              });
            }
          });
        } else {
          let data = {
            date: moment(this.state.date).format("MM/DD/YYYY"),
            day: moment(this.state.date).format("dddd"),
            availability: [
              {
                time: time,
              },
            ],
            counsellorId: counsellor._id,
          };
          this.setState({
            availabilities: [...this.state.availabilities, data],
          });
        }
      } else {
        let data = {
          date: moment(this.state.date).format("MM/DD/YYYY"),
          day: moment(this.state.date).format("dddd"),
          availability: [
            {
              time: time,
            },
          ],
          counsellorId: counsellor._id,
        };
        this.setState({
          availabilities: [...this.state.availabilities, data],
        });
      }
      setTimeout(() => {
        console.log(this.state.availabilities);
      }, 2000);
    }
  };

  addAvailability = (options) => {
    let postData = {
      availabilities: this.state.availabilities,
      currentUserId: this.state.currentUser.id,
      repeatQuaterly: options.repeatQuaterly ? true : false,
    };

    appointmentService.addAvailabilities(postData).then((data) => {
      this.toggleRepeatQuaterlyModal();
      swal("Availability saved!", "", "success");
      //NotificationManager.success("Availability saved!", null, 5000);
    });
  };

  getAvailabilities = (dt) => {
    this.setState({
      availabilities: [],
    });
    appointmentService
      .getAllAvailabilityByDate(moment(dt).valueOf())
      .then((data) => {
        console.log("heena", data.Data);
        if (data.Data?.[0]) {
          console.log("sdljnvlsdjnvljsnvljsnflvjn");
          this.setState({
            availabilities: data.Data,
          });
        }
      })
      .catch((err) => {
        this.setState({
          availabilities: [],
        });
      });
  };

  appointCounsellor = () => {
    let data = {
      bookingId: this.state.selectedUser.value._id,
      date: moment(this.state.date).format("MM/DD/YYYY"),
      time: this.state.counsellorTime,
      counsellorId: this.state.counsellorId,
      appointmentType: this.state.appointmentType?.value,
      currentUserId: this.state.currentUser.id,
      availabilityId: this.state.selectedAvalibilityId,
      codeStatus: this.state.selectedCode.label,
      bookingStatus: this.state.bookingStatus?.value
        ? this.state.bookingStatus?.value
        : "",
      toAttend: this.state.toAttend?.value ? this.state.toAttend?.value : "",
    };
    console.log("data: ", data);
    appointmentService.approveAppointment(data).then((res) => {
      this.toggleDetails();
      this.getAvailabilities(this.state.date);
    });
  };

  getAllUsers = () => {
    userService
      .getUsersByDepartmentId("5f212a4cc142bc3a2059bcd6")
      .then((data) => {
        console.log("GET USER TABLE data:-> ", data.Data);
        this.setState({
          allCounsellor: filterCounsellorsBasedOnfilterType(
            data.Data,
            this.state.filterType
          ),
          counsellorList: data.Data,
          loading: false,
        });
      });
    appointmentService.getAllBookings().then((data) => {
      console.log(data.Data);
      let filteredData = data.Data.filter((item) => {
        return (
          item.reason.includes("Employment-Services") ||
          item.reason.includes("Youth-Programs") ||
          item.reason.includes("literacy-basic-skills") ||
          item.reason.includes("resource-information-centre") ||
          item.reason.includes("keep-it-hot")
        );
      });
      this.setState({
        allUesrs: filteredData,
      });
    });
  };

  toggleCancelBooking = () => {
    this.setState((state) => {
      return { cancelBookingPopup: !state.cancelBookingPopup };
    });
  };

  cancelBooking = () => {
    let selectedBooking = this.state.clickedItem;
    let { selectedAvalibilityId } = this.state;
    let currentUserId = this.state.currentUser.id;

    let requestData = {
      bookingId: selectedBooking.bookingId,
      availabilityId: selectedAvalibilityId,
      currentUserId: currentUserId,
      time: selectedBooking.time,
    };

    appointmentService
      .cancelBooking(requestData)
      .then((data) => {
        if (data.Status && data.Data) {
          swal(data.Data.message, "", "success");
          //NotificationManager.success(data.Data.message,null,5000);
          this.toggleCancelBooking();
          this.toggleBookingInfoPopup();
          this.getAvailabilities(this.state.date);
        }
      })
      .catch((error) => {
        swal(error, "", "error");
        //NotificationManager.error(error,null,5000);
      });
  };

  toggleBookingInfoPopup = (id) => {
    this.setState((state) => {
      let stateObj = {
        bookingInfoPopup: !state.bookingInfoPopup,
      };
      if (state.bookingInfoPopup) {
        stateObj["bookingdata"] = {};
      } else {
        this.getBookingById(id);
      }
      return stateObj;
    });
  };

  updateBookingNotes = () => {
    let data = {
      bookingId: this.state.bookingData._id,
      notes: this.state.counsellorNote,
      toAttend: this.state.bookingData.toAttend,
    };
    appointmentService.updateBookingNotes(data).then((res) => {
      console.log(res);
      this.toggleBookingInfoPopup();
      this.setState({
        counsellorNote: null,
      });
    });
  };

  toggleTab = (tab) => {
    this.setState((state) => {
      if (state.activeTab !== tab) return { activeTab: tab };
    });
  };

  handleOnChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  appointWalkin = () => {
    let data = {
      date: moment(this.state.date).format("MM/DD/YYYY"),
      time: this.state.counsellorTime,
      counsellorId: this.state.counsellorId,
      appointmentType: this.state.appointmentType?.value,
      currentUserId: this.state.currentUser.id,
      availabilityId: this.state.selectedAvalibilityId,
      name: this.state.userName,
      phoneNumber: this.state.phoneNumber,
      email: this.state.email,
      codeStatus: this.state.selectedCode.label,
      reason: this.state.selectedCode.value,
      bookingStatus: this.state.bookingStatus?.value
        ? this.state.bookingStatus?.value
        : "",
      toAttend: this.state.toAttend?.value ? this.state.toAttend?.value : "",
    };

    appointmentService
      .assignWalkinAppointment(data)
      .then((res) => {
        this.toggleDetails();
        this.getAvailabilities(this.state.date);
      })
      .catch((error) => {
        swal(error, "", "error");
        //NotificationManager.error(error, null, 5000)
      });
  };

  toggleRepeatQuaterlyModal = () => {
    this.setState((state) => {
      return { repeatQuaterlyModal: !state.repeatQuaterlyModal };
    });
  };

  updateCounsellorType = (e, counsellorId) => {
    if (e.target.value) {
      let requestData = {
        counsellorId: counsellorId,
        currentUserId: this.state.currentUser.id,
        counsellorType: e.target.value,
      };
      console.log("requestData: ", requestData);

      userService.updateCounsellorType(requestData).then((data) => {
        if (data.Status) {
          swal("Counsellor Type updated", "", "success");
          //NotificationManager.success("Counsellor Type updated", null, 5000);
          this.getAllUsers();
        }
      });
    }
  };

  render() {
    return (
      <div id="main-wrapper" className="fix-header fix-sidebar card-no-border">
        {this.state.loading && <Preloader />}
        <HeaderAdmin />
        <Sidebaar />
        <div className="page-wrapper">
          <div className="container-fluid">
            <div className="row page-titles">
              <div className="col-md-5 col-8 align-self-center">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <a href="#">Home</a>
                  </li>
                  <li className="breadcrumb-item active">
                    Counsellor Availability
                  </li>
                </ol>
              </div>
            </div>

            <div className="row">
              <div className="col-md-12">
                <div className="pt-4">
                  <div className="card">
                    <div className="card-body">
                      <div className="d-flex justify-content-between">
                        <div className="align-self-center tbl-head">
                          <i className="mdi mdi-calendar-clock"></i>{" "}
                          <span>Counsellor Availability</span>
                        </div>
                      </div>
                      <hr />
                      {/* <MDBDataTable bordered hover data={this.state.counsellor} /> */}
                      <div className="d-flex space_between">
                        <div className="calendercls mb-4">
                          <Calendar
                            id="calenderElem"
                            className="with-holiday"
                            dateFormat="dd, M yy, DD"
                            // selectionMode="multiple"
                            value={this.state.date}
                            showIcon={true}
                            onChange={(e) => {
                              this.setState({
                                date: e.value,
                              });
                              this.getAvailabilities(e.value);
                            }}
                            disabledDates={holidayDateList(
                              this.props.holidayList
                            )}
                            readOnlyInput
                          ></Calendar>
                        </div>

                        <div className="mb-4 mr-2">
                          <div className="d-flex">
                            {this.state.currentDepartmentName ===
                            "IT Department" ? (
                              <Button
                                className="mr-2"
                                onClick={this.toggleRepeatQuaterlyModal}
                                color="primary"
                              >
                                Save
                              </Button>
                            ) : (
                              ""
                            )}
                            <Button
                              className="mr-2"
                              color="primary"
                              onClick={() => window.print()}
                            >
                              Print
                            </Button>
                            <Button
                              className="mr-2"
                              color="primary"
                              onClick={() => this.filterUser("BR")}
                            >
                              BR
                            </Button>
                            <Button
                              className="mr-2"
                              color="primary"
                              onClick={() => this.filterUser("LBS")}
                            >
                              LBS
                            </Button>
                            <Button
                              className="mr-2"
                              color="primary"
                              onClick={() => this.filterUser("IRCC")}
                            >
                              IRCC
                            </Button>
                            <Button
                              className="mr-2"
                              color="primary"
                              onClick={() => this.filterUser("KTH")}
                            >
                              KTH
                            </Button>
                            <Button
                              className="mr-2"
                              color="primary"
                              onClick={() => this.filterUser("W")}
                            >
                              W
                            </Button>
                            <Button
                              className="mr-2"
                              color="primary"
                              onClick={() => this.filterUser("")}
                            >
                              All
                            </Button>
                          </div>
                          {/* <div className="d-flex">
                                                <Button color="primary">Other</Button>
                                                <Button color="primary">Br</Button>
                                                </div> */}
                        </div>
                      </div>
                      <table className="table">
                        <thead className="thead-dark  text-center">
                          <tr>
                            <th scope="col"></th>
                            <th scope="col"></th>
                            <th scope="col">9:00</th>
                            <th scope="col">10:00</th>
                            <th scope="col">11:00</th>
                            <th scope="col">12:00</th>
                            <th scope="col">1:00</th>
                            <th scope="col">2:00</th>
                            <th scope="col">3:00</th>
                            <th scope="col">4:00</th>
                          </tr>
                        </thead>
                        {console.log(".....................", this.state.date)}
                        <tbody>
                          {this.state.allCounsellor?.[0] &&
                            this.state.allCounsellor.map((c, i) => (
                              <tr key={i}>
                                <th
                                  scope="row"
                                  data-toggle="tooltip"
                                  data-placement="left"
                                  title={c.firstName + " " + c.lastName}
                                >
                                  {c.firstName.slice(0, 1) +
                                    "" +
                                    c.lastName.slice(0, 1)}
                                </th>
                                <td>
                                  <select
                                    value={c.counsellorType}
                                    onChange={(e) =>
                                      this.updateCounsellorType(e, c._id)
                                    }
                                  >
                                    <option value=""></option>
                                    <option value="BR">BR</option>
                                    <option value="W">W</option>
                                    <option value="LBS">LBS</option>
                                    <option value="IRCC">IRCC</option>
                                    <option value="KTH">KTH</option>
                                  </select>
                                </td>
                                {this.timing.map((time, timeIndex) => (
                                  <td key={timeIndex}>
                                    {this.state.currentDepartmentName ===
                                    "IT Department" ? (
                                      <input
                                        id={i + "-" + timeIndex}
                                        style={{
                                          position: "initial",
                                          opacity: "unset",
                                          float: "right",
                                          cursor: "pointer",
                                        }}
                                        type="checkbox"
                                        name="name1"
                                        onClick={() =>
                                          this.editInput(
                                            "time-" + i + "-" + timeIndex,
                                            c,
                                            time
                                          )
                                        }
                                      />
                                    ) : (
                                      <input
                                        id={i + "-" + timeIndex}
                                        style={{
                                          position: "initial",
                                          opacity: "unset",
                                          display: "none",
                                          float: "right",
                                          cursor: "pointer",
                                        }}
                                        type="checkbox"
                                        name="name1"
                                        onClick={() =>
                                          this.editInput(
                                            "time-" + i + "-" + timeIndex,
                                            c,
                                            time
                                          )
                                        }
                                      />
                                    )}
                                    <div
                                      className="mt-3"
                                      onClick={() => {
                                        let domElement =
                                          document.getElementById(
                                            "time-" + i + "-" + timeIndex
                                          );
                                        let bookingId =
                                          domElement.getAttribute(
                                            "data-bookingid"
                                          );
                                        if (!domElement.disabled) {
                                          if (bookingId) {
                                            this.toggleBookingInfoPopup(
                                              bookingId
                                            );
                                            //this.toggleCancelBooking();
                                          } else {
                                            this.toggleDetails();
                                          }
                                          this.state.availabilities.map(
                                            (available) => {
                                              if (
                                                available.counsellorId === c._id
                                              ) {
                                                if (
                                                  available.availability.filter(
                                                    (availableItem) =>
                                                      availableItem.time ===
                                                      time
                                                  ).length
                                                ) {
                                                  const perticularAvailability =
                                                    available.availability.filter(
                                                      (availableItem) =>
                                                        availableItem.time ===
                                                        time
                                                    );
                                                  if (
                                                    perticularAvailability[0]
                                                      .bookingId
                                                  ) {
                                                    this.setState({
                                                      clickedItem:
                                                        perticularAvailability[0],
                                                    });
                                                  }
                                                }
                                              }
                                            }
                                          );
                                          this.setState({
                                            counsellorTime: time,
                                            counsellorId: c._id,
                                            selectedAvalibilityId:
                                              domElement.getAttribute(
                                                "data-id"
                                              ),
                                          });
                                        }
                                      }}
                                    >
                                      <textarea
                                        className="form-control feedCounsellerDetails"
                                        id={"time-" + i + "-" + timeIndex}
                                        style={{
                                          border: "none",
                                        }}
                                        disabled
                                      ></textarea>
                                      {this.state.availabilities?.length ? (
                                        <React.Fragment>
                                          {
                                            (setTimeout(() => {
                                              this.state.availabilities.map(
                                                (available) => {
                                                  if (
                                                    available.counsellorId ===
                                                    c._id
                                                  ) {
                                                    if (
                                                      available.availability.filter(
                                                        (availableItem) =>
                                                          availableItem.time ===
                                                          time
                                                      ).length
                                                    ) {
                                                      let domElement =
                                                        document.getElementById(
                                                          "time-" +
                                                            i +
                                                            "-" +
                                                            timeIndex
                                                        );
                                                      domElement.style.backgroundColor =
                                                        "#a6ce39";
                                                      domElement.style.color =
                                                        "white";
                                                      domElement.style.cursor =
                                                        "pointer";
                                                      domElement.disabled = false;
                                                      domElement.setAttribute(
                                                        "data-id",
                                                        available._id
                                                      );
                                                      document.getElementById(
                                                        i + "-" + timeIndex
                                                      ).checked = true;
                                                      const perticularAvailability =
                                                        available.availability.filter(
                                                          (availableItem) =>
                                                            availableItem.time ===
                                                            time
                                                        );
                                                      if (
                                                        perticularAvailability[0]
                                                          .bookingStatus
                                                      ) {
                                                        domElement.value =
                                                          perticularAvailability[0]
                                                            .codeStatus +
                                                          "-" +
                                                          perticularAvailability[0]
                                                            .name +
                                                          "\n" +
                                                          perticularAvailability[0]
                                                            .phoneNumber;
                                                        domElement.setAttribute(
                                                          "data-bookingid",
                                                          perticularAvailability[0]
                                                            .bookingId
                                                        );
                                                        if (
                                                          perticularAvailability[0]
                                                            .appointmentType ===
                                                          "in person"
                                                        ) {
                                                          domElement.style.backgroundColor =
                                                            "#5955a5";
                                                        }
                                                        //domElement.disabled = true
                                                      } else {
                                                        if (domElement.value) {
                                                          domElement.value = "";
                                                        }
                                                        if (
                                                          domElement.getAttribute(
                                                            "data-bookingid"
                                                          )
                                                        ) {
                                                          domElement.removeAttribute(
                                                            "data-bookingid"
                                                          );
                                                        }
                                                      }
                                                    }
                                                  }
                                                }
                                              );
                                            }, 1500),
                                            (<div></div>))
                                          }
                                        </React.Fragment>
                                      ) : (
                                        <React.Fragment>
                                          {
                                            (setTimeout(() => {
                                              document.getElementById(
                                                "time-" + i + "-" + timeIndex
                                              ).style.backgroundColor =
                                                "#e9ecef";
                                              document.getElementById(
                                                "time-" + i + "-" + timeIndex
                                              ).style.color = "black";
                                              document.getElementById(
                                                "time-" + i + "-" + timeIndex
                                              ).disabled = true;
                                              document.getElementById(
                                                i + "-" + timeIndex
                                              ).checked = false;
                                              document.getElementById(
                                                "time-" + i + "-" + timeIndex
                                              ).value = "";
                                            }, 1500),
                                            (<div></div>))
                                          }
                                        </React.Fragment>
                                      )}
                                    </div>
                                  </td>
                                ))}
                              </tr>
                            ))}
                        </tbody>
                      </table>

                      <div>
                        <Modal
                          isOpen={this.state.userModal}
                          centered
                          toggle={this.toggleDetails}
                        >
                          <ModalHeader toggle={this.toggleDetails}>
                            Schedule Appointment
                          </ModalHeader>
                          <ModalBody>
                            <Nav tabs>
                              <NavItem>
                                <NavLink
                                  className={
                                    this.state.activeTab === "1" ? "active" : ""
                                  }
                                  onClick={() => {
                                    this.toggleTab("1");
                                  }}
                                >
                                  Registered User
                                </NavLink>
                              </NavItem>
                              <NavItem>
                                <NavLink
                                  className={
                                    this.state.activeTab === "2" ? "active" : ""
                                  }
                                  onClick={() => {
                                    this.toggleTab("2");
                                  }}
                                >
                                  Walk in
                                </NavLink>
                              </NavItem>
                            </Nav>
                            <TabContent activeTab={this.state.activeTab}>
                              <TabPane tabId="1">
                                <div className="d-flex row mt-2 mb-3">
                                  <div className="mr-2 col-3">
                                    <strong>Code:</strong>
                                    <Select
                                      value={this.state.selectedCode}
                                      className="mt-1"
                                      onChange={(e) => {
                                        this.setState({
                                          selectedCode: e,
                                        });
                                      }}
                                      options={[
                                        {
                                          value: "employment-services",
                                          label: "ES",
                                        },
                                        {
                                          value: "youth-programs",
                                          label: "YJC",
                                        },
                                        {
                                          value: "literacy-basic-skills",
                                          label: "LBS",
                                        },
                                        {
                                          value: "resource-information-centre",
                                          label: "IRCC",
                                        },
                                        {
                                          value: "keep-it-hot",
                                          label: "KTH",
                                        },
                                        {
                                          value: "Pre-app",
                                          label: "Pre-app",
                                        },
                                      ]}
                                      placeholder="Code"
                                    />
                                  </div>
                                  <div className="mr-2 col-8">
                                    <strong>Select User :</strong>
                                    <Select
                                      className="mt-1"
                                      placeholder="Select User"
                                      onChange={(e) => {
                                        console.log(e);
                                        let res = {
                                          label: e.value.reason.includes(
                                            "Employment-Services"
                                          )
                                            ? "ES"
                                            : "YJC",
                                          value: e.value.reason,
                                        };
                                        this.setState({
                                          selectedUser: e,
                                          selectedCode: res,
                                        });
                                      }}
                                      // value={this.state.selectedUser}
                                      options={this.state.allUesrs?.map(
                                        (member, index) => ({
                                          label: member.name,
                                          value: member,
                                        })
                                      )}
                                    />
                                  </div>
                                </div>
                                {this.state.selectedUser && (
                                  <React.Fragment>
                                    <div className="mb-3">
                                      <strong>Reason :</strong>{" "}
                                      {this.state.selectedUser.value.reason
                                        .map((item) => {
                                          return programType[item];
                                        })
                                        .join(", ")}
                                    </div>

                                    <div className="mb-3">
                                      <strong>PhoneNumber :</strong>{" "}
                                      {
                                        this.state.selectedUser.value
                                          .phoneNumber
                                      }
                                      {!this.state.selectedUser.value.preferredMethodOfContact.includes(
                                        "text"
                                      ) &&
                                        this.state.selectedUser.value.preferredMethodOfContact?.includes(
                                          "phone"
                                        ) && (
                                          <span
                                            style={{
                                              color: "blue",
                                            }}
                                          >
                                            {" "}
                                            (Preferred: phone)
                                          </span>
                                        )}
                                      {!this.state.selectedUser.value.preferredMethodOfContact.includes(
                                        "phone"
                                      ) &&
                                        this.state.selectedUser.value.preferredMethodOfContact?.includes(
                                          "text"
                                        ) && (
                                          <span
                                            style={{
                                              color: "blue",
                                            }}
                                          >
                                            {" "}
                                            (Preferred: text){" "}
                                          </span>
                                        )}
                                      {this.state.selectedUser.value.preferredMethodOfContact?.includes(
                                        "phone"
                                      ) &&
                                        this.state.selectedUser.value.preferredMethodOfContact?.includes(
                                          "text"
                                        ) && (
                                          <span
                                            style={{
                                              color: "blue",
                                            }}
                                          >
                                            {" "}
                                            (Preferred: phone & text)
                                          </span>
                                        )}
                                    </div>
                                    <div className="mb-3">
                                      <strong>Email :</strong>{" "}
                                      {this.state.selectedUser.value.email}
                                      {this.state.selectedUser.value.preferredMethodOfContact?.includes(
                                        "email"
                                      ) && (
                                        <span
                                          style={{
                                            color: "blue",
                                          }}
                                        >
                                          {" "}
                                          (Preferred)
                                        </span>
                                      )}
                                    </div>
                                    <div className="mb-3">
                                      <strong>Preferred Day :</strong>
                                      {this.state.selectedUser.value.preferredDay.map(
                                        (day, ind) => (
                                          <span key={ind}>
                                            {" " + capitalize(day) + " "}
                                          </span>
                                        )
                                      )}
                                    </div>
                                    <div className="mb-3">
                                      <strong>Preferred Time :</strong>
                                      {this.state.selectedUser.value.preferredTime.map(
                                        (t, ind) => (
                                          <span key={ind}>
                                            {" " + capitalize(t) + " "}
                                          </span>
                                        )
                                      )}
                                    </div>
                                    <div className="row mb-3">
                                      <div className="col-4">
                                        <strong>Status :</strong>
                                        <Select
                                          className="mt-1"
                                          placeholder="Status"
                                          onChange={(e) => {
                                            this.setState({
                                              bookingStatus: e,
                                            });
                                          }}
                                          options={[
                                            {
                                              value: "WNI",
                                              label: "WNI",
                                            },
                                            {
                                              value: "WI",
                                              label: "WI",
                                            },
                                            {
                                              value: "I",
                                              label: "I",
                                            },
                                            {
                                              value: "NI",
                                              label: "NI",
                                            },
                                          ]}
                                        />
                                      </div>
                                      <div className="col-4">
                                        <strong>To Attend:</strong>
                                        <Select
                                          className="mt-1"
                                          placeholder="To Attend"
                                          onChange={(e) => {
                                            this.setState({
                                              toAttend: e,
                                            });
                                          }}
                                          options={[
                                            {
                                              value: "Yes",
                                              label: "Yes",
                                            },
                                            {
                                              value: "No",
                                              label: "No",
                                            },
                                          ]}
                                        />
                                      </div>
                                      <div className="col-4">
                                        <strong>Appoinment Type:</strong>
                                        <Select
                                          className="mt-1"
                                          placeholder=""
                                          onChange={(e) => {
                                            this.setState({
                                              appointmentType: e,
                                            });
                                          }}
                                          options={[
                                            {
                                              value: "virtual",
                                              label: "Virtual",
                                            },
                                            {
                                              value: "in person",
                                              label: "In person",
                                            },
                                          ]}
                                        />
                                      </div>
                                    </div>
                                    {/* <div className="mb-3">
                                                            <strong>Counsellers Note :</strong>
                                                            <div className="mt-1">
                                                                <Editor style={{height:'120px'}} value={this.state.note} onTextChange={(e) => this.setState({note: e.htmlValue})} />
                                                            </div>
                                                        </div> */}
                                  </React.Fragment>
                                )}
                              </TabPane>
                              <TabPane tabId="2">
                                <div className="d-flex row mt-2 mb-3">
                                  <div className="mr-2 col-3">
                                    <strong>Code:</strong>
                                    <Select
                                      value={this.state.selectedCode}
                                      className="mt-1"
                                      onChange={(e) => {
                                        this.setState({
                                          selectedCode: e,
                                        });
                                      }}
                                      options={[
                                        {
                                          value: "employment-services",
                                          label: "ES",
                                        },
                                        {
                                          value: "youth-programs",
                                          label: "YJC",
                                        },
                                        {
                                          value: "literacy-basic-skills",
                                          label: "LBS",
                                        },
                                        {
                                          value: "resource-information-centre",
                                          label: "IRCC",
                                        },
                                        {
                                          value: "keep-it-hot",
                                          label: "KTH",
                                        },
                                        {
                                          value: "Pre-app",
                                          label: "Pre-app",
                                        },
                                      ]}
                                      placeholder="Code"
                                    />
                                  </div>
                                  <div className="mr-2 col-8">
                                    <strong>User Name:</strong>
                                    <input
                                      name="userName"
                                      className="mt-1 form-control"
                                      value={this.state.userName}
                                      onChange={this.handleOnChange}
                                    />
                                  </div>
                                </div>

                                <div className="mb-3">
                                  <strong>Phone Number :</strong>
                                  {/* <input name="phoneNumber" className="mt-1 form-control" value={this.state.phoneNumber} onChange={this.handleOnChange}/> */}
                                  <MaskedInput
                                    mask={[
                                      "(",
                                      /[1-9]/,
                                      /\d/,
                                      /\d/,
                                      ")",
                                      " ",
                                      /\d/,
                                      /\d/,
                                      /\d/,
                                      "-",
                                      /\d/,
                                      /\d/,
                                      /\d/,
                                      /\d/,
                                    ]}
                                    name="phoneNumber"
                                    id="phoneNumber"
                                    onChange={this.handleOnChange}
                                    className={"mt-1 form-control"}
                                    placeholder="Phone Number"
                                    guide={false}
                                    type="text"
                                    value={this.state.phoneNumber}
                                    render={(ref, props) => (
                                      <input
                                        type="text"
                                        label="Phone Number"
                                        name="phoneNumber"
                                        ref={ref}
                                        value={this.state.phoneNumber}
                                        {...props}
                                      />
                                    )}
                                  />
                                </div>
                                <div className="mb-3">
                                  <strong>Email :</strong>
                                  <input
                                    name="email"
                                    className="mt-1 form-control"
                                    value={this.state.email}
                                    onChange={this.handleOnChange}
                                  />
                                </div>

                                <div className="row mb-3">
                                  <div className="col-4">
                                    <strong>Status :</strong>
                                    <Select
                                      className="mt-1"
                                      placeholder="Status"
                                      onChange={(e) => {
                                        this.setState({
                                          bookingStatus: e,
                                        });
                                      }}
                                      options={[
                                        {
                                          value: "WNI",
                                          label: "WNI",
                                        },
                                        {
                                          value: "WI",
                                          label: "WI",
                                        },
                                        {
                                          value: "I",
                                          label: "I",
                                        },
                                        {
                                          value: "NI",
                                          label: "NI",
                                        },
                                      ]}
                                    />
                                  </div>
                                  <div className="col-4">
                                    <strong>To Attend:</strong>
                                    <Select
                                      className="mt-1"
                                      placeholder="To Attend"
                                      onChange={(e) => {
                                        this.setState({
                                          toAttend: e,
                                        });
                                      }}
                                      options={[
                                        {
                                          value: "Yes",
                                          label: "Yes",
                                        },
                                        {
                                          value: "No",
                                          label: "No",
                                        },
                                      ]}
                                    />
                                  </div>
                                  <div className="col-4">
                                    <strong>Appoinment Type:</strong>
                                    <Select
                                      className="mt-1"
                                      placeholder=""
                                      onChange={(e) => {
                                        this.setState({
                                          appointmentType: e,
                                        });
                                      }}
                                      options={[
                                        {
                                          value: "virtual",
                                          label: "Virtual",
                                        },
                                        {
                                          value: "in person",
                                          label: "In person",
                                        },
                                      ]}
                                    />
                                  </div>
                                </div>
                              </TabPane>
                            </TabContent>
                          </ModalBody>
                          <ModalFooter>
                            {this.state.activeTab === "1" ? (
                              <Button
                                className="disableColor"
                                onClick={this.appointCounsellor}
                                color="primary"
                                disabled={!this.state.selectedUser}
                              >
                                Save
                              </Button>
                            ) : (
                              <Button
                                className="disableColor"
                                onClick={this.appointWalkin}
                                color="primary"
                              >
                                Save
                              </Button>
                            )}
                          </ModalFooter>
                        </Modal>
                      </div>
                      <div>
                        <Modal
                          isOpen={this.state.cancelBookingPopup}
                          centered
                          toggle={this.toggleCancelBooking}
                        >
                          <ModalHeader toggle={this.toggleCancelBooking}>
                            User Details
                          </ModalHeader>
                          <ModalBody>
                            <h4>
                              Are you sure you want to cancel the assigned
                              booking?
                            </h4>
                          </ModalBody>
                          <ModalFooter>
                            <Button
                              className="disableColor"
                              onClick={this.toggleCancelBooking}
                              color="secondary"
                            >
                              No
                            </Button>
                            <Button
                              className="disableColor"
                              onClick={this.cancelBooking}
                              color="primary"
                            >
                              Yes
                            </Button>
                          </ModalFooter>
                        </Modal>
                      </div>
                      <div>
                        <Modal
                          isOpen={this.state.bookingInfoPopup}
                          centered
                          toggle={this.toggleBookingInfoPopup}
                        >
                          <ModalHeader toggle={this.toggleBookingInfoPopup}>
                            User Details
                          </ModalHeader>
                          <ModalBody>
                            <div className="mb-3">
                              <strong>UserName :</strong>{" "}
                              {this.state.bookingData.name}
                            </div>
                            <div className="mb-3">
                              <strong>Code :</strong>{" "}
                              {this.state.bookingData.codeStatus}
                            </div>
                            <div className="mb-3">
                              <strong>PhoneNumber :</strong>{" "}
                              {this.state.bookingData.phoneNumber}
                            </div>
                            <div className="mb-3">
                              <strong>Email :</strong>{" "}
                              {this.state.bookingData.email}
                            </div>
                            <div className="mb-3">
                              <strong>Status :</strong>{" "}
                              {this.state.bookingData.bookingStatus}
                            </div>
                            <div className="mb-3">
                              <strong>To Attend :</strong>{" "}
                              {this.state.bookingData.toAttend}
                            </div>
                            <div className="mb-3">
                              <strong>Appoinment Type :</strong>{" "}
                              {capitalize(
                                this.state.bookingData.appointmentType
                              )}
                            </div>

                            <div className="mb-3">
                              <strong>Note :</strong>
                              <div className="mt-1">
                                <Editor
                                  style={{
                                    height: "120px",
                                  }}
                                  value={this.state.counsellorNote}
                                  onTextChange={(e) =>
                                    this.setState({
                                      counsellorNote: e.htmlValue,
                                    })
                                  }
                                  readOnly={
                                    this.state.currentDepartmentName ===
                                    "Frontend Staff"
                                      ? true
                                      : hasDaysPassed(
                                          this.state.bookingData.updatedAt,
                                          5
                                        )
                                      ? true
                                      : false
                                  }
                                />
                              </div>
                            </div>
                          </ModalBody>
                          <ModalFooter>
                            {hasDaysPassed(
                              this.state.bookingData.updatedAt,
                              5
                            ) ? null : (
                              <Button
                                className="disableColor"
                                onClick={this.toggleCancelBooking}
                                color="secondary"
                              >
                                Cancel Booking
                              </Button>
                            )}
                            {hasDaysPassed(
                              this.state.bookingData.updatedAt,
                              5
                            ) ? null : (
                              <Button
                                className="disableColor"
                                onClick={this.updateBookingNotes}
                                color="primary"
                                disabled={!this.state.counsellorNote}
                              >
                                Save Note
                              </Button>
                            )}

                            <Button
                              className="disableColor"
                              onClick={this.toggleBookingInfoPopup}
                              color="primary"
                            >
                              close
                            </Button>
                          </ModalFooter>
                        </Modal>
                      </div>
                      <div>
                        <Modal
                          isOpen={this.state.repeatQuaterlyModal}
                          centered
                          toggle={this.toggleRepeatQuaterlyModal}
                        >
                          <ModalHeader toggle={this.toggleRepeatQuaterlyModal}>
                            User Details
                          </ModalHeader>
                          <ModalBody>
                            <h4>
                              Do you want to repeat the availability for the
                              next quarter
                            </h4>
                          </ModalBody>
                          <ModalFooter>
                            <Button
                              className="disableColor"
                              onClick={this.addAvailability}
                              color="secondary"
                            >
                              No
                            </Button>
                            <Button
                              className="disableColor"
                              onClick={() =>
                                this.addAvailability({
                                  repeatQuaterly: true,
                                })
                              }
                              color="primary"
                            >
                              Yes
                            </Button>
                          </ModalFooter>
                        </Modal>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <FooterAdmin />
        </div>
      </div>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CounsellorAvailability);
