import React, { Component } from 'react';
import { connect } from 'react-redux';
import Preloader from '../../components/Preloader';
import HeaderAdmin from '../../components/HeaderAdmin';
import SidebarSuperAdmin from '../../components/admin/Sidebaar';
import FooterAdmin from '../../components/FooterAdmin';
import { Link } from "react-router-dom";
import {subDepartmentService, departmentService} from '../../_services/admin'
import { authenticationService } from '../../_services';
import {setUserData} from '../../redux-store/action';
import Moment from 'react-moment';
import { MDBDataTable } from 'mdbreact';
import moment from 'moment';
import swal from 'sweetalert';



function mapStateToProps(state) {
    return {

    };
}

function mapDispatchToProps(dispatch) {
    return {
        setUserData:(data)=>{dispatch(setUserData(data))}
    };
}

class SubDepartments extends Component {
    constructor(props) {
        super(props);
        this.delete = this.delete.bind(this);

        this.state = {
            currentUser: authenticationService.currentUserValue,
            department: [],
            loading: true
        };
    }
    componentDidMount () {
        this.props.match.params.id && this.getAllDepartments(this.props.match.params.id);
        this.props.setUserData(this.state.currentUser);
    }
    getAllDepartments(id) {
        departmentService.getSubDepartmentsByDepartmentId(id)
        .then(data =>{
            let rowsArray = [];
            data && data.Data && data.Data.map((item, i) => {
                    rowsArray.push({
                        key: i + 1,
                        // createdAt: <Moment format="MM/DD/YYYY">{item.createdAt}</Moment>,
                        createdAt: moment(item.createdAt,'x').format("MM/DD/YYYY"),
                        department: item.name,
                        action: <React.Fragment><Link to={"/admin/sub-department/edit/"+item._id} data-toggle="tooltip" data-original-title="Edit"> <i className="fas fa-pencil-alt text-inverse m-r-10"></i> </Link>
                                <a onClick={()=>this.delete(item._id)} data-toggle="tooltip" data-original-title="Delete"> <i className="fas fa-trash text-danger"></i> </a></React.Fragment>
                    
                    });
                    return true;
                });
            const tableData = {
                columns: [
                    // {
                    //     label: '#',
                    //     field: 'key',
                    //     sort: 'asc',
                    //     width: 270
                    // },
                    {
                        label: 'Department',
                        field: 'department',
                        sort: 'asc',
                        width: 150
                    },
                    {
                        label: 'Created At',
                        field: 'createdAt',
                        sort: 'asc',
                        width: 270
                    },
                    {
                        label: 'Action',
                        field: 'action',
                        sort: 'disabled',
                        width: 270
                    },
                ],
                rows: rowsArray
            }
            this.setState({ department: tableData, loading: false });
        } )
    }
    delete(departmentId) {
        subDepartmentService.deleteDepartment(departmentId,this.state.currentUser.id)
        .then((response)=>{
            response.Status && this.getAllDepartments(this.props.match.params.id)
            swal("Sub Department Deleted Succesfully!", "", "success");
        })
        .catch(err => {
            console.log(err)
            swal("Oops Something Went Wrong!", "", "error");
        })
    }
    render() {
        const {department} = this.state;
    
        return (
            <div id="main-wrapper" className="fix-header fix-sidebar card-no-border">
            {this.state.loading && <Preloader />}
            <HeaderAdmin />
            <SidebarSuperAdmin />
            <div className="page-wrapper">
                <div className="container-fluid">
                    <div className="row page-titles">
                        <div className="col-md-5 col-8 align-self-center">
                            <h3 className="text-themecolor m-b-0 m-t-0">Sub Department</h3>
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item"><a href="#">Home</a></li>
                                <li className="breadcrumb-item active">Sub Department</li>
                            </ol>
                        </div>
                        <div className="col-md-7 col-4 align-self-center text-right">
                            <Link to="/admin/sub-department/create" className="btn btn-primary" ><i className="mdi mdi-account-plus"></i> <span className="hide-menu">Create Sub Department</span></Link>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="pt-4">
                            <div className="card">
                                <div className="card-body">
                                <MDBDataTable
                                            striped
                                            bordered
                                            hover
                                            data={department}
                                        />
                                    {/* <table className="table table-bordered">
                                        <thead>
                                            <tr>
                                                <th>Department</th>
                                                <th>Access</th>
                                                <th>Created At</th>
                                                <th>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                department.map((dep, index) => 
                                                <tr key={index}>
                                                    <td>{dep.name}</td>
                                                    <td>{(dep.accessType === 1) ? 'Read' : 'ReadWrite'}</td>
                                                    <td><Moment format="YYYY-MM-DD hh:mm:ss a">{dep.createdAt}</Moment></td>
                                                    <td className="text-nowrap">
                                                        <Link to={"/admin/department/edit/"+dep._id} data-toggle="tooltip" data-original-title="Edit"> <i className="fas fa-pencil-alt text-inverse m-r-10"></i> </Link>
                                                        <a onClick={()=>this.delete(dep._id)} data-toggle="tooltip" data-original-title="Delete"> <i className="fas fa-trash text-danger"></i> </a>
                                                    </td>
                                                </tr>
                                                )
                                            }
                                        </tbody>
                                    </table> */}
                                </div>
                               
                            </div>
                            </div>
                        </div>
                    </div>
                </div>
                <FooterAdmin />
            </div>
        </div>
        );
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(SubDepartments);