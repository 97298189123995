import React, { Component } from "react";
import { connect } from "react-redux";
import Preloader from "../components/Preloader";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { authenticationService } from "../_services";
import { NotificationManager } from "react-notifications";
function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {};
}

class ForgotPasswordPage extends Component {
  componentDidMount() {
    let scripts = ["/assets/js/custom.min.js"];
    let host = window.location.origin;
    console.log("host", host);
    scripts.map((t) => {
      let script = document.createElement("script");
      script.src = host + t;
      script.async = false;
      document.body.appendChild(script);
      return true;
    });
  }
  render() {
    return (
      <React.Fragment>
        <Preloader />
        <section id="wrapper">
          <div class="login-register">
            <div class="login-box card">
              <div class="card-body">
                <Formik
                  initialValues={{
                    email: "",
                  }}
                  validationSchema={Yup.object().shape({
                    email: Yup.string().required("Email is required"),
                  })}
                  onSubmit={(formData, { setStatus, setSubmitting }) => {
                    setStatus();
                    authenticationService.forgotPassword(formData).then(
                      (result) => {
                        setSubmitting(false);
                        if (result.Status) {
                          NotificationManager.success(
                            "Reset Password instuctions has been sent to your email address",
                            "",
                            5000
                          );
                          const { from } = this.props.location.state || {
                            from: { pathname: "/" },
                          };
                          this.props.history.push(from);
                        } else {
                          NotificationManager.error(
                            "Something went wrong please contact UHC Support.",
                            "",
                            5000
                          );
                        }
                      },
                      (error) => {
                        setSubmitting(false);
                        setStatus(error);
                      }
                    );
                  }}
                  render={({ errors, status, touched, isSubmitting }) => (
                    <Form>
                      <div
                        className="form-horizontal form-material"
                        id="loginform"
                      >
                        <img
                          className="loginlogo"
                          src={
                            window.location.origin +
                            "/assets/images/UHC_logo.png"
                          }
                        />
                        <h3 className="box-title m-b-20">Recover Password</h3>
                        <div className="form-group ">
                          <div className="col-xs-12">
                            <Field
                              name="email"
                              type="text"
                              placeholder="Email"
                              className={
                                "form-control" +
                                (errors.email && touched.email
                                  ? " is-invalid"
                                  : "")
                              }
                            />
                            <ErrorMessage
                              name="email"
                              component="div"
                              className="invalid-feedback"
                            />
                          </div>
                        </div>
                        <div className="form-group text-center mb-0 m-t-20">
                          <div className="col-xs-12">
                            <button
                              type="submit"
                              disabled={isSubmitting}
                              className="btn btn-info btn-lg btn-block text-uppercase waves-effect waves-light"
                            >
                              Reset
                            </button>
                            {isSubmitting && (
                              <img src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
                            )}
                          </div>
                        </div>
                        {status && (
                          <div className={"alert alert-danger"}>{status}</div>
                        )}
                      </div>
                    </Form>
                  )}
                />
              </div>
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPasswordPage);
