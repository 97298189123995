import React, { Component } from "react";
import { connect } from "react-redux";
import Preloader from "../../components/Preloader";
import HeaderAdmin from "../../components/HeaderAdmin";
import Sidebaar from "../../components/user/Sidebaar";
import FooterAdmin from "../../components/FooterAdmin";
import { Form, Formik, Field, ErrorMessage } from "formik";
import { setUserData } from "../../redux-store/action";
import { authenticationService } from "../../_services";
import { contentLibraryService } from '../../_services/staff';
import Select from "react-select";
import { Link } from "react-router-dom";

import * as Yup from "yup";

function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {
    setUserData: (data) => {
      dispatch(setUserData(data));
    },
  };
}

class CreateContentLibrary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentUser: authenticationService.currentUserValue,
      contentLibrary:{},
      loading: true,
      staffOptions: [],
    };
  }

  componentDidMount() {
    this.props.setUserData(this.state.currentUser);
    
    this.props.match.params.id &&
    contentLibraryService
    .getContentLibaryCategroryById(this.props.match.params.id)
    .then((data) => {
      this.setState({ contentLibrary: data.Data, loading: false });
    });
    this.setState({ loading: false });
  }

  onChangeHandler = (event) => {
    console.log("event of uploading...", event.target.files);
    this.setState({selectedFiles: event.target.files})
  };

  render() {
    return (
      <div id="main-wrapper" className="fix-header fix-sidebar card-no-border">
        {/* <Preloader /> */}
        <HeaderAdmin />
        <Sidebaar />
        <div className="page-wrapper">
          <div className="container-fluid">
            <div className="row page-titles">
              <div className="col-md-5 col-8 align-self-center">
                <h3 className="text-themecolor m-b-0 m-t-0">
                  {!this.state.contentLibrary._id
                    ?
                  "Add Category"
                  : "Edit Category"}
                </h3>
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <a href="/">Home</a>
                  </li>

                  <li className="breadcrumb-item active">Category</li>
                  <li className="breadcrumb-item active">Add Category</li>
                </ol>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6">
                <div className="pt-4">
                  <div className="card">
                    <Formik
                      enableReinitialize={true}
                      initialValues={{
                        name: this.state.contentLibrary.name || "",
                        description: this.state.contentLibrary.description || "",
                        currentUserId: this.state.currentUser.id,
                        categoryId: this.state.contentLibrary._id || ""
                      }}
                      validationSchema={Yup.object().shape({
                        name: Yup.string().required(
                          "Category Name is required"
                        ),

                        description: Yup.string().required(
                          "Description is required"
                        ),
                      })}
                      onSubmit={(formData, { setStatus, setSubmitting }) => {
                        let data = new FormData();
                        data.append('name',formData.name);
                        data.append('description',formData.description);
                        data.append('currentUserId',formData.currentUserId);
                        data.append('categoryId',formData.categoryId);
                        if(this.state.selectedFiles){
                          for(var x = 0; x<this.state.selectedFiles.length; x++) {
                            data.append('files', this.state.selectedFiles[x])
                          }
                        }
                        
                        setStatus();
                        contentLibraryService.create(data).then(
                          (category) => {
                            const { from } = this.props.location.state || {
                              from: { pathname: "/staff/contents-library" },
                            };
                            this.props.history.push(from);
                        },
                        (error) => {
                          setSubmitting(false);
                          setStatus(error);
                        }
                        );
                      }}
                      render={({
                        errors,
                        status,
                        touched,
                        isSubmitting,
                        values,
                        setFieldValue,
                      }) => (
                        <Form>
                          <div className="card-body">
                            <div className="row">
                              <div className="col-md-12">
                                <div className="form-group required">
                                  <label className="control-label">
                                    Category Name
                                  </label>
                                  <Field
                                    name="name"
                                    type="text"
                                    placeholder="Category Name"
                                    className={
                                      "form-control" +
                                      (errors.name && touched.name
                                        ? " field-invalid"
                                        : "")
                                    }
                                  />
                                  <ErrorMessage
                                    name="name"
                                    component="div"
                                    className="invalid-feedback invalid-feedback-custom"
                                  />
                                </div>
                                <div className="form-group required">
                                  <label className="control-label">
                                    Description
                                  </label>
                                  <Field
                                    name="description"
                                    component="textarea"
                                    rows={5}
                                    placeholder="Description"
                                    className={
                                      "form-control" +
                                      (errors.description && touched.description
                                        ? " field-invalid"
                                        : "")
                                    }
                                  />
                                  <ErrorMessage
                                    name="description"
                                    component="div"
                                    className="invalid-feedback invalid-feedback-custom"
                                  />
                                </div>
                                {/* <div className="form-group required">
                                  <label className="control-label">
                                    Upload Files
                                  </label>
                                  <div>
                                    <label class="custom-file-upload mt-1">
                                      <input
                                        type="file"
                                        className="d-none"
                                        multiple
                                        onChange={this.onChangeHandler}
                                      />
                                      Attach File
                                    </label>
                                  </div>
                                  <div>
                                  <span>{this.state.selectedFiles? this.state.selectedFiles.length + ' File(s) selected':''}</span>
                                  </div>
                                </div> */}
                              </div>
                            </div>
                          </div>
                          <div className="card-footer accordionfooter d-flex flex-row-reverse">
                          
                          <button
                              type="submit"
                              disabled={isSubmitting}
                              className="btn btn-primary"
                            >
                              {/* {!this.state.department._id */}
                              {/* ?  */}
                              Save Category
                              {/* : "Update Category"}{" "} */}
                            </button>

                            <div style={{marginRight:"5px"}}>
                              <Link
                                to="/staff/contents-library"
                                className="btn btn-secondary"
                                style={{backgroundColor:"grey", color:"white"}}
                              >
                                {/* <i className="mdi mdi-chevron-left"></i> */}
                                <span className="hide-menu">Cancel</span>
                              </Link>
                            </div>
                          
                            {isSubmitting && (
                              <img src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
                            )}
                          </div>
                          {status && (
                            <div className={"alert alert-danger"}>{status}</div>
                          )}
                        </Form>
                      )}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <FooterAdmin />
        </div>
      </div>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateContentLibrary);
