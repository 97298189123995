/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from "react";
import { connect } from "react-redux";
import Preloader from "../../components/Preloader";
import HeaderAdmin from "../../components/HeaderAdmin";
import SidebarSuperAdmin from "../../components/admin/Sidebaar";
import Sidebaar from "../../components/user/Sidebaar";
import FooterAdmin from "../../components/FooterAdmin";
import { Link } from "react-router-dom";
import {
  userService,
  departmentService,
  subDepartmentService,
} from "../../_services/admin";
import { authenticationService } from "./../../_services";
import { setUserData } from "../../redux-store/action";
import Moment from "react-moment";
import { MDBDataTable } from "mdbreact";
import { setLoader } from "../../redux-store/action";
import Loader from "react-loader-spinner";
import moment from "moment";
import DeleteConfirmation from "../../shared/DeleteConfirmation";
import { Role } from "../../_helpers";
import swal from "sweetalert";
function mapStateToProps(state) {
  return {
    isLoading: state.isLoading,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setLoader: (data) => {
      dispatch(setLoader(data));
    },
    setUserData: (data) => {
      dispatch(setUserData(data));
    },
  };
}

function isPermitedToView(currentUser, givenUser) {
  if (currentUser.role === 1) {
    return true;
  } else {
    if (givenUser.role === 2) {
      return true;
    }
    return false;
  }
}

class ListofSuperAdmin extends Component {
  constructor(props) {
    super(props);
    this.delete = this.delete.bind(this);

    this.state = {
      currentUser: authenticationService.currentUserValue,
      users: [],
      loading: true,
      departments: [],
      subDepartments: [],
    };
  }
  componentDidMount() {
    departmentService
      .getAll()
      .then(
        (data) =>
          data &&
          data.Data &&
          (this.setState({ departments: data.Data }) || this.getAllUsers())
      );
    subDepartmentService
      .getAll()
      .then(
        (data) =>
          data &&
          data.Data &&
          (this.setState({ subDepartments: data.Data }) || this.getAllUsers())
      );
    this.props.setUserData(this.state.currentUser);
  }
  getDepartmentsName() {}
  getUserRole() {
    return this.state.currentUser.role === 1 ? "admin" : "staff";
  }
  getAllUsers() {
    this.props.setLoader(true);
    userService.getAllSuperUsers().then((data) => {
      console.log("data: ", data);
      let rowsArray = [];
      data &&
        data.Data &&
        data.Data.map((user, i) => {
          let departmentName = [];
          user.department.filter((k) => {
            this.state.departments.filter((t) => {
              if (t._id.indexOf(k) !== -1) departmentName.push(t.name);
            });
          });

          rowsArray.push({
            key: i + 1,
            email: user.email,
            role: user.role === 1 ? "Admin" : "Staff",
            access:
              user.accessType === 1
                ? "Read"
                : user.accessType === 2
                ? "ReadWrite"
                : "Admin",
            departmentName: departmentName.join(", "),
            createdAt: moment(user.createdAt, "x").format("MM/DD/YYYY"),
            action: (
              <React.Fragment>
                <Link
                  to={`/${this.getUserRole()}/user/edit/${user._id}`}
                  data-toggle="tooltip"
                  data-original-title="Edit"
                >
                  <i className="fas fa-pencil-alt text-inverse m-r-10"></i>{" "}
                </Link>
                {this.state.currentUser.id.toString() != user._id.toString() ? (
                  <a
                    onClick={() => document.getElementById(user._id).click()}
                    data-toggle="tooltip"
                    data-original-title="Delete"
                  >
                    <i className="fas fa-trash text-danger"></i>{" "}
                  </a>
                ) : (
                  <a
                    // onClick={() => document.getElementById(user._id).click()}
                    data-toggle="tooltip"
                    data-original-title="Delete"
                  >
                    <i className="fas fa-trash " style={{ color: "grey" }}></i>{" "}
                  </a>
                )}
                <DeleteConfirmation
                  id={user._id}
                  confirm={() => this.delete(user._id)}
                ></DeleteConfirmation>
              </React.Fragment>
            ),
          });

          return true;
        });
      const tableData = {
        columns: [
          // {
          //     label: '#',
          //     field: 'key',
          //     sort: 'asc',
          //     width: 270
          // },
          {
            label: "Email",
            field: "email",
            sort: "asc",
            width: 150,
          },
          {
            label: "Role",
            field: "role",
            sort: "asc",
            width: 270,
          },
          {
            label: "Access",
            field: "access",
            sort: "asc",
            width: 270,
          },
          {
            label: "Department",
            field: "departmentName",
            sort: "asc",
            width: 270,
          },
          // {
          //     label: 'Sub Department',
          //     field: 'subDepartmentName',
          //     sort: 'asc',
          //     width: 270
          // },
          // {
          //     label: 'Date Of Birth',
          //     field: 'dob',
          //     sort: 'asc',
          //     width: 270
          // },
          {
            label: "Created At",
            field: "createdAt",
            sort: "asc",
            width: 270,
          },
          {
            label: "Action",
            field: "action",
            sort: "disabled",
            width: 270,
          },
        ],
        rows: rowsArray,
      };
      this.setState({ users: tableData, loading: false });
      this.props.setLoader(false);
    });
  }
  delete(userId) {
    userService
      .deleteUser(userId, this.state.currentUser.id)
      .then((response) => {
        response.Status && this.getAllUsers();
        swal("User Deleted Succesfully!", "", "success");
      })
      .catch((err) => {
        console.log(err);
        swal("Opps Something Went Wrong!", "", "error");
      });
  }
  render() {
    const { users, currentUser } = this.state;
    const { isLoading } = this.props;
    const role = currentUser.role === 1 ? "admin" : "staff";
    return (
      <React.Fragment>
        {isLoading && (
          <div id="loaderRoot">
            <div className="loaderPositionsStyle">
              <div className="loaderPositionsStyleSub">
                <Loader
                  type="Rings"
                  sty
                  color="#6f42c1"
                  height={80}
                  width={80}
                />
              </div>
            </div>
          </div>
        )}
        {!isLoading && (
          <div
            id="main-wrapper"
            className="fix-header fix-sidebar card-no-border"
          >
            {this.state.loading && <Preloader />}
            <HeaderAdmin />
            {this.state.currentUser.role === Role.Admin ? (
              <SidebarSuperAdmin />
            ) : (
              <Sidebaar />
            )}
            <div className="page-wrapper">
              <div className="container-fluid">
                <div className="row page-titles">
                  <div className="col-md-5 col-8 align-self-center">
                    <h3 className="text-themecolor m-b-0 m-t-0">Super Users</h3>
                    <ol className="breadcrumb">
                      <li className="breadcrumb-item">
                        <a href="/">Home</a>
                      </li>
                      <li className="breadcrumb-item active">Super Users</li>
                    </ol>
                  </div>
                  <div className="col-md-7 col-4 align-self-center text-right">
                    <Link
                      to={`/${role}/user/create`}
                      className="btn btn-primary"
                    >
                      <i className="mdi mdi-account-plus"></i>{" "}
                      <span className="hide-menu">Create User</span>
                    </Link>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12">
                    <div className="pt-4">
                      <div className="card">
                        <div className="card-body">
                          {/* <div className="table-responsive"> */}
                          <MDBDataTable
                            responsive
                            striped
                            bordered
                            hover
                            data={users}
                          />
                          {/* </div> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <FooterAdmin />
            </div>
          </div>
        )}
      </React.Fragment>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ListofSuperAdmin);
