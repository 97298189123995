import React, { Component } from "react";
import { connect } from "react-redux";
import Preloader from "../../components/Preloader";
import { Link } from "react-router-dom";
import HeaderAdmin from "../../components/HeaderAdmin";
import Sidebaar from "../../components/user/Sidebaar";
import FooterAdmin from "../../components/FooterAdmin";
import { setUserData } from "../../redux-store/action";
import { authenticationService } from "../../_services";
import { contentLibraryService } from "../../_services/staff";
import Moment from "react-moment";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  FormGroup,
  Label,
  Input,
  FormText,
  Col,
  Progress,
} from "reactstrap";
import { FileIcon, defaultStyles } from "react-file-icon";
import ReactPlayer from "react-player";
import mime from "mime-types";
import ReactStars from "react-rating-stars-component";
import DeleteConfirmation from "../../shared/DeleteConfirmation";
import config from "../../config";
import axios from "axios";
import VideoThumbnail from "react-video-thumbnail";
import * as Yup from "yup";
import { Card } from "react-bootstrap";
import Swal from "sweetalert";
import {
  removeAllDotsButLast,
  getFileExtension,
} from "../../_helpers/_helperFunctions";
import slugify from "slugify";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import classNames from "classnames";
import { CustomModal } from "../../components/CustomModal";
import {
  AUDIO_FILES,
  VIDEO_FILES,
  DOCS_AND_IMAGES,
  IMAGE_TYPES,
} from "../../_helpers";
function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {
    setUserData: (data) => {
      dispatch(setUserData(data));
    },
  };
}

class CategoryDetail extends Component {
  constructor(props) {
    super(props);

    this.state = {
      currentUser: authenticationService.currentUserValue,
      loading: true,
      contentCategory: {},
      contentFiles: [],
      staffOptions: [],
      isModalOpen: false,
      search: "",
      contentFilesCopy: [],
      audioModal: false,
      videoModal: false,
      mediaUrl: "",
      updateFileModal: false,
      isLoading: false,
      filename: "",
      feedback: false,
      downloadOption: false,
      uploadProgress: 0,
      reorderMode: false,
    };

    this.toggleAddFileModal = this.toggleAddFileModal.bind(this);
  }
  componentDidMount() {
    this.props.setUserData(this.state.currentUser);
    this.getCategroryDetail();
    this.getFilesByCategoryId();
    this.setState({ loading: false });
  }

  toggleAudio = () => {
    this.setState({
      audioModal: !this.state.audioModal,
    });
  };

  toggleVideo = () => {
    this.setState({
      videoModal: !this.state.videoModal,
    });
  };

  getCategroryDetail() {
    this.props.match.params.id &&
      contentLibraryService
        .getContentLibaryCategroryById(this.props.match.params.id)
        .then((data) => {
          this.setState({ contentCategory: data.Data, loading: false });
        });
  }

  handleInputChange = (e) => {
    console.log("e.target.value: ", e.target.value);
    this.setState({ [e.target.name]: e.target.value });
  };

  handleCheckboxChange = (e) => {
    console.log("e.target.value: ", e.target.checked);
    this.setState({ [e.target.name]: e.target.checked });
  };
  getFilesByCategoryId() {
    this.props.match.params.id &&
      contentLibraryService
        .getFilesByCategoryId(this.props.match.params.id)
        .then((data) => {
          this.setState({
            contentFiles: data.Data,
            loading: false,
            contentFilesCopy: data.Data,
          });
        });
  }

  getFileFromKey(key, filename) {
    let requestBody = { key };
    contentLibraryService.getSignedLinkFromKey(requestBody).then((data) => {
      let fileExtension = getFileExtension(key);
      if (AUDIO_FILES.includes(fileExtension)) {
        this.setState({
          mediaUrl: data.Data.url,
          filename: filename,
        });
        this.toggleAudio();
      } else if (VIDEO_FILES.includes(fileExtension)) {
        this.setState({
          mediaUrl: data.Data.url,
          filename: filename,
        });
        this.toggleVideo();
      } else if (DOCS_AND_IMAGES.includes(fileExtension)) {
        this.setState({
          mediaUrl: data.Data.url,
          filename: filename,
          fileType: getFileExtension(key),
          selectedFileType: "file",
        });

        this.togglefileViewer();
      } else {
        window.open(data.Data.url, "_blank");
      }
    });
  }

  toggleUpdateFileModel = (
    fileId,
    title,
    description,
    feedback,
    downloadOption
  ) => {
    this.setState((state) => {
      return {
        updateFileModal: !state.updateFileModal,
        fileId,
        title,
        description,
        feedback,
        downloadOption,
      };
    });
  };

  togglefileViewer = () => {
    this.setState((state) => {
      return { fileViewer: !state.fileViewer };
    });
  };

  updateFileDetails = () => {
    let { fileId, title, description, feedback, downloadOption } = this.state;
    if (fileId && title) {
      let requestData = {
        fileId,
        title,
        description,
        feedback,
        downloadOption,
        currentUserId: this.state.currentUser.id,
      };
      contentLibraryService.updateFileDetails(requestData).then((data) => {
        if (data.Status) {
          this.toggleUpdateFileModel();
          this.getFilesByCategoryId();
        }
      });
    }
  };

  deleteFile(fileId) {
    Swal({
      title: "Are you sure!",
      text: "You want to delete this file?",
      buttons: ["No", "Yes"],
      icon: "warning",
      dangerMode: true,
    }).then((result) => {
      if (result) {
        let requestBody = {
          fileId: fileId,
          categoryId: this.state.contentCategory._id,
          currentUserId: this.state.currentUser.id,
        };
        contentLibraryService.deleteFile(requestBody).then((data) => {
          this.getFilesByCategoryId();
          this.getCategroryDetail();
        });
      }
    });
  }

  deleteCategrory() {
    if (this.state.contentCategory._id) {
      contentLibraryService
        .deleteContentCategory(
          this.state.contentCategory._id,
          this.state.currentUser.id
        )
        .then((data) => {
          const { from } = this.props.location.state || {
            from: { pathname: "/staff/contents-library" },
          };
          this.props.history.push(from);
        });
    }
  }

  toggleAddFileModal() {
    this.setState({
      isModalOpen: !this.state.isModalOpen,
      feedback: false,
      downloadOption: false,
    });
  }

  onChangeFileHandler = (event) => {
    this.setState({ selectedFiles: event.target.files });
  };

  reorderFiles = () => {
    const fileList = this.state.contentFiles;
    const categoryId = this.state.contentCategory._id;

    let requestObj = {
      categoryId,
      fileOrder: fileList.map((file, index) => {
        return {
          _id: file._id,
          index: index,
        };
      }),
    };

    contentLibraryService
      .reorderFiles(requestObj)
      .then((data) => {})
      .catch((err) => {
        console.log(err);
      });
  };

  uploadFile = () => {
    this.setState({
      isLoading: true,
    });
    if (this.state.contentCategory._id) {
      if (this.state.selectedFiles[0]) {
        let file = this.state.selectedFiles[0];
        // Split the filename to get the name and type
        let originalname = this.state.selectedFiles[0].name.toLowerCase();
        let fileName =
          "content-library/" +
          this.state.contentCategory.name +
          "/" +
          removeAllDotsButLast(slugify(originalname, { lower: true }));
        //let fileName = "file-upload-test/" + this.state.selectedFiles[0].name;

        let fileType = mime.lookup(originalname);
        console.log(fileName);
        console.log(fileType);
        console.log("Preparing the upload");

        axios
          .post(
            `${config.apiUrl}/api/v1/content-library-categories/get-file-uploadlink`,
            {
              fileName: fileName,
              fileType: fileType,
            },
            {
              headers: {
                Authorization: `Bearer ${this.state.currentUser.token}`,
                isenc: localStorage.getItem("isenc")
                  ? parseInt(localStorage.getItem("isenc"))
                  : 0,
              },
            }
          )
          .then((response) => {
            console.log(response.data);
            var returnData = response.data.Data;
            var signedRequest = returnData.signedRequest;
            var url = returnData.url;
            console.log("------> url: ", url);
            //this.setState({ url: url });
            console.log("Recieved a signed request " + signedRequest);

            // Put the fileType in the headers for the upload
            var options = {
              headers: {
                "Content-Type": fileType,
              },
              onUploadProgress: (ev) => {
                const progress = parseInt((ev.loaded / ev.total) * 100);
                this.setState({
                  uploadProgress: progress,
                });
              },
            };
            axios
              .put(signedRequest, file, options)
              .then((result) => {
                console.log("Response from s3", result);
                //this.setState({ pdfLink: url });
                let requestData = {
                  fileName: fileName,
                  originalname: originalname,
                  url: url,
                  categoryId: this.state.contentCategory._id,
                  currentUserId: this.state.currentUser.id,
                  title: this.state.selectedFileTitle,
                  description: this.state.selectedFileDescription,
                  feedback: this.state.feedback.toString(),
                  downloadOption: this.state.downloadOption,
                  fileType: fileType,
                };

                contentLibraryService.saveFileInfo(requestData).then((data) => {
                  if (data && data.Status) {
                    // file info save success.
                    this.toggleAddFileModal();
                    this.getFilesByCategoryId();
                    this.getCategroryDetail();
                    this.setState({
                      isLoading: false,
                      uploadProgress: 0,
                      selectedFileTitle: "",
                      selectedFileDescription: "",
                    });
                    Swal("File uploaded successfully", "", "success");
                  } else {
                    // file info save fail
                    Swal(
                      "File cannot be uploaded please try later",
                      "",
                      "error"
                    );
                  }
                });
              })
              .catch((error) => {
                console.log(error);
              });
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
      }
    } else {
      this.toggleAddFileModal();
      this.setState({
        uploadProgress: 0,
        isLoading: false,
      });
    }
  };

  filterCard = (event) => {
    if (event.target.value) {
      let filtered = this.state.contentFilesCopy.filter((value) => {
        console.log(value);
        return value.title
          .toLowerCase()
          .includes(event.target.value.toLowerCase());
      });
      this.setState({
        contentFiles: filtered,
      });
    } else {
      this.setState({
        contentFiles: this.state.contentFilesCopy,
      });
    }
    // this.setState({
    //   search: event.target.value
  };

  handleAddFileChanges = (event) => {
    if (event.target.name === "title") {
      this.setState({ selectedFileTitle: event.target.value });
    } else if (event.target.name === "description") {
      this.setState({ selectedFileDescription: event.target.value });
    } else {
      console.log("event.target.value yolo", event.target.value);
      this.setState({ selectedFileFeedback: event.target.value });
    }
  };

  getThumbnail = (file) => {
    let fileExtension = file.originalname.split(".");

    if (VIDEO_FILES.includes(fileExtension[1])) {
      return <VideoThumbnail videoUrl={file.location} />;
    } else if (IMAGE_TYPES.includes(fileExtension[1].toLowerCase())) {
      return (
        <Card.Img
          width="100%"
          src={file.location}
          alt={file.title ? file.title : file.originalname}
          className="cursor-pointer cardRatio"
          onClick={() => {
            this.getFileFromKey(
              file.key,
              file.title || file.originalname,
              file._id
            );
          }}
        />
      );
    } else {
      return (
        <FileIcon
          extension={fileExtension[1]}
          {...defaultStyles[fileExtension[1]]}
        />
      );
    }
  };

  onDragEnd = (result) => {
    const { destination, source, draggableId } = result;

    if (!destination) {
      return;
    }

    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) {
      return;
    }

    const fileList = this.state.contentFiles;
    const newFileList = Array.from(fileList);

    newFileList.splice(source.index, 1);
    newFileList.splice(destination.index, 0, fileList[source.index]);
    console.log("newFileList: ", newFileList);

    this.setState({ contentFiles: newFileList });
  };

  render() {
    let { contentFiles, contentCategory } = this.state;
    return (
      <div id="main-wrapper" className="fix-header fix-sidebar card-no-border">
        {this.state.loading ? <Preloader /> : ""}
        <HeaderAdmin />
        <Sidebaar />
        <div className="page-wrapper">
          <div className="container-fluid">
            <div className="row page-titles">
              <div className="col-md-5 col-8 align-self-center">
                <h3 className="text-themecolor m-b-0 m-t-0">Category Detail</h3>
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <a href="/">Home</a>
                  </li>

                  <li className="breadcrumb-item active">Resource Library</li>
                  <li className="breadcrumb-item active">Category Detail</li>
                </ol>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <div className="pt-4">
                  <div className="card">
                    <div className="row">
                      <div className="col-md-12">
                        <div className="card-body">
                          <div className="d-flex justify-content-between">
                            <div>
                              <Link
                                to="/staff/contents-library"
                                className="btn btn-primary"
                              >
                                <i className="mdi mdi-chevron-left"></i>
                                <span className="hide-menu">Back</span>
                              </Link>
                            </div>
                            <div className="d-flex">
                              <div className="mr-2">
                                <input
                                  type="text"
                                  onChange={this.filterCard}
                                  className="form-control"
                                  placeholder="search file"
                                />
                                <i className="mdi mdi-magnify search-icon"></i>
                              </div>
                              {this.state.currentUser.accessType == 2 && (
                                <div className="mr-2">
                                  <button
                                    type="button"
                                    className="btn btn-primary"
                                    onClick={() => this.toggleAddFileModal()}
                                  >
                                    <i className="mdi mdi-plus"></i>
                                    Add Files
                                  </button>

                                  <Modal
                                    isOpen={this.state.isModalOpen}
                                    toggle={this.toggleAddFileModal}
                                  >
                                    {/* {this.state.isLoading && <Preloader/>} */}

                                    <ModalHeader
                                      toggle={this.toggleAddFileModal}
                                    >
                                      Add File
                                    </ModalHeader>

                                    <ModalBody>
                                      <Form>
                                        <FormGroup row>
                                          <Col sm={12}>
                                            <Input
                                              type="file"
                                              name="file"
                                              id="exampleFile"
                                              onChange={
                                                this.onChangeFileHandler
                                              }
                                            />
                                          </Col>
                                          {this.state.uploadProgress > 0 && (
                                            <Col
                                              sm={12}
                                              style={{ marginTop: "10px" }}
                                            >
                                              <Progress
                                                style={{
                                                  height: "17px",
                                                  backgroundColor: "#5955a5",
                                                }}
                                                value={
                                                  this.state.uploadProgress
                                                }
                                              >
                                                <strong>
                                                  {this.state.uploadProgress} %
                                                </strong>
                                              </Progress>
                                            </Col>
                                          )}
                                          <Col sm={12}>
                                            <div className="pt-2">
                                              <Label>Title:</Label>
                                              <Input
                                                type="text"
                                                name="title"
                                                id="title"
                                                value={
                                                  this.state.selectedFileTitle
                                                }
                                                onChange={
                                                  this.handleAddFileChanges
                                                }
                                                disabled={
                                                  this.state.uploadProgress > 0
                                                }
                                              />
                                            </div>
                                          </Col>
                                          <Col sm={12}>
                                            <div className="pt-2">
                                              <Label>Description:</Label>
                                              <Input
                                                type="textarea"
                                                name="description"
                                                id="description"
                                                value={
                                                  this.state
                                                    .selectedFileDescription
                                                }
                                                onChange={
                                                  this.handleAddFileChanges
                                                }
                                                disabled={
                                                  this.state.uploadProgress > 0
                                                }
                                              />
                                            </div>
                                          </Col>
                                          <Col sm={12}>
                                            <div className="pt-2 form-check">
                                              <input
                                                type="checkbox"
                                                className="form-check-input"
                                                id="feedback"
                                                name="feedback"
                                                checked={this.state.feedback}
                                                onChange={
                                                  this.handleCheckboxChange
                                                }
                                                disabled={
                                                  this.state.uploadProgress > 0
                                                }
                                              />
                                              <label
                                                className="form-check-label"
                                                htmlFor="feedback"
                                              >
                                                Take Feedback
                                              </label>
                                            </div>
                                          </Col>
                                          <Col sm={12}>
                                            <div className="pt-2 form-check">
                                              <input
                                                type="checkbox"
                                                className="form-check-input"
                                                id="downloadOption"
                                                name="downloadOption"
                                                checked={
                                                  this.state.downloadOption
                                                }
                                                onChange={
                                                  this.handleCheckboxChange
                                                }
                                                disabled={
                                                  this.state.uploadProgress > 0
                                                }
                                              />
                                              <label
                                                className="form-check-label"
                                                htmlFor="downloadOption"
                                              >
                                                Give Download Option
                                              </label>
                                              <small
                                                id="downloadOptionHelp"
                                                class="form-text"
                                              >
                                                Only applicable for video and
                                                audio files.
                                              </small>
                                            </div>
                                          </Col>
                                        </FormGroup>
                                      </Form>
                                    </ModalBody>
                                    <ModalFooter>
                                      <Button
                                        color="primary"
                                        onClick={this.uploadFile}
                                        disabled={this.state.uploadProgress > 0}
                                      >
                                        Upload Files
                                      </Button>{" "}
                                      <Button
                                        color="secondary"
                                        onClick={this.toggleAddFileModal}
                                        disabled={this.state.uploadProgress > 0}
                                      >
                                        Cancel
                                      </Button>
                                    </ModalFooter>
                                  </Modal>
                                </div>
                              )}
                              {this.state.currentUser.accessType == 2 && (
                                <div className="mr-2">
                                  <button
                                    type="button"
                                    className="btn btn-primary"
                                    // onClick={() => this.deleteCategrory()}
                                    onClick={() =>
                                      document
                                        .getElementById(
                                          "deleteConfirmationButton"
                                        )
                                        .click()
                                    }
                                  >
                                    Delete Category
                                  </button>
                                  <DeleteConfirmation
                                    id={"deleteConfirmationButton"}
                                    confirm={() => this.deleteCategrory()}
                                  ></DeleteConfirmation>
                                </div>
                              )}
                              {this.state.currentUser.accessType == 2 && (
                                <div>
                                  <button
                                    type="button"
                                    className="btn btn-primary"
                                    // onClick={() => this.deleteCategrory()}
                                    onClick={() =>
                                      this.setState({
                                        reorderMode: !this.state.reorderMode,
                                      })
                                    }
                                  >
                                    Reorder files
                                  </button>
                                </div>
                              )}
                              {this.state.reorderMode ? (
                                <div className="ml-2">
                                  <button
                                    type="button"
                                    className="btn btn-primary"
                                    // onClick={() => this.deleteCategrory()}
                                    onClick={this.reorderFiles}
                                  >
                                    Save Order
                                  </button>
                                </div>
                              ) : null}
                            </div>
                          </div>

                          <div className="d-flex line-background justify-content-between">
                            <span className="">
                              {contentCategory.name ? contentCategory.name : ""}
                            </span>
                            <span className="">
                              {" "}
                              <i className="ti-timer"></i>{" "}
                              <Moment format="MM/DD/YYYY hh:mm A">
                                {contentCategory?.updatedAt}
                              </Moment>{" "}
                              <strong className="ml-2">Initials - </strong>
                              {contentCategory?.user?.firstName}{" "}
                            </span>
                          </div>

                          <div className="pt-3">
                            <div className="row">
                              {contentFiles.length ? (
                                this.state.reorderMode ? (
                                  <div className="col-md-12">
                                    <DragDropContext onDragEnd={this.onDragEnd}>
                                      <Droppable
                                        droppableId={
                                          this.state.contentCategory._id
                                        }
                                      >
                                        {(provided, snapshot) => (
                                          <div
                                            className={classNames({
                                              "droppable-container": true,
                                              "bg-skyblue":
                                                snapshot.isDraggingOver,
                                              "p-2": true,
                                            })}
                                            ref={provided.innerRef}
                                            {...provided.droppableProps}
                                          >
                                            {contentFiles.map((file, index) => {
                                              return (
                                                <Draggable
                                                  key={file._id}
                                                  draggableId={file._id}
                                                  index={index}
                                                >
                                                  {(provided, snapshop) => (
                                                    <div
                                                      className={classNames({
                                                        draggable: true,
                                                        "mb-2": true,
                                                        "p-3": true,
                                                        "draggable-bg":
                                                          snapshop.isDragging,
                                                      })}
                                                      {...provided.draggableProps}
                                                      {...provided.dragHandleProps}
                                                      ref={provided.innerRef}
                                                    >
                                                      {file.title} -{" "}
                                                      {file.contentType}
                                                    </div>
                                                  )}
                                                </Draggable>
                                              );
                                            })}
                                            {provided.placeholder}
                                          </div>
                                        )}
                                      </Droppable>
                                    </DragDropContext>
                                  </div>
                                ) : (
                                  contentFiles.map((file, index) => {
                                    let filename = file.originalname;
                                    let ext = filename.split(".");

                                    return (
                                      <div className="col-md-3" key={index}>
                                        <Card>
                                          <div className="image-parent">
                                            {/* <Card.Img top width="100%" src={(file.thumbnail) ? file.thumbnail : imageList[index] || defaultImage}
                                          alt={(file.title) ? file.title : file.originalname}
                                          className="cursor-pointer"
                                          onClick={() => { this.getFileFromKey(file.key, file.title || file.originalname, file._id) }}
                                        /> */}
                                            {this.getThumbnail(file)}
                                            {this.state.currentUser
                                              .accessType == 2 && (
                                              <div className="middle">
                                                <i
                                                  className="mdi mdi-pencil editIcon cursor-pointer"
                                                  onClick={() =>
                                                    this.toggleUpdateFileModel(
                                                      file._id,
                                                      file.title,
                                                      file.description,
                                                      file.feedback,
                                                      file.downloadOption
                                                    )
                                                  }
                                                ></i>
                                                <i
                                                  className="mdi mdi-delete deleteIcon cursor-pointer"
                                                  onClick={() =>
                                                    this.deleteFile(file._id)
                                                  }
                                                ></i>
                                              </div>
                                            )}
                                          </div>
                                          {/* <div className="image-parent">
                                        <FileIcon extension={ext[1]} {...defaultStyles[ext[1]]} />
                                        {(this.state.currentUser.accessType == 2) &&
                                          <div className="middle">
                                            <i className="mdi mdi-delete deleteIcon" onClick={() => this.deleteFile(file._id)}></i>
                                          </div>}
                                      </div> */}
                                          <Card.Body className="p-1 px-2 card-bg-color">
                                            <Card.Text
                                              className="cursor-pointer mb-0"
                                              onClick={() => {
                                                this.getFileFromKey(
                                                  file.key,
                                                  file.title ||
                                                    file.originalname,
                                                  file._id
                                                );
                                              }}
                                            >
                                              {file.title
                                                ? file.title
                                                : file.originalname}
                                            </Card.Text>
                                            <div>
                                              <ReactStars
                                                count={5}
                                                size={20}
                                                isHalf={false}
                                                activeColor="#ffd700"
                                                edit={false}
                                                value={
                                                  file.ratings
                                                    ? Math.round(file.ratings)
                                                    : 0
                                                }
                                              />
                                            </div>
                                          </Card.Body>
                                        </Card>
                                      </div>
                                    );
                                  })
                                )
                              ) : (
                                <div className="col-md-12 text-center">
                                  <p>No files have been uploaded.</p>
                                </div>
                              )}
                            </div>
                          </div>

                          <div>
                            <Modal
                              isOpen={this.state.audioModal}
                              centered="true"
                              toggle={this.toggleAudio}
                            >
                              <ModalHeader toggle={this.toggleAudio}>
                                {this.state.filename}
                              </ModalHeader>
                              <ModalBody>
                                <div className="player-wrapper">
                                  <ReactPlayer
                                    className="react-player"
                                    url={this.state.mediaUrl}
                                    controls
                                    width="100%"
                                    height="55px"
                                  />
                                </div>
                              </ModalBody>
                            </Modal>
                          </div>
                          <div>
                            <Modal
                              isOpen={this.state.videoModal}
                              centered={true}
                              toggle={this.toggleVideo}
                              size="lg"
                            >
                              <ModalHeader toggle={this.toggleVideo}>
                                {this.state.filename}
                              </ModalHeader>
                              <ModalBody className="p-0">
                                <ReactPlayer
                                  className="react-player"
                                  url={this.state.mediaUrl}
                                  // light = {true}
                                  controls
                                  width="100%"
                                  height="100%"
                                />
                              </ModalBody>
                              {/* <ModalFooter>
                                <Button
                                  color="primary"
                                  onClick={this.toggleVideo}
                                >
                                  Cancel
                                </Button>
                              </ModalFooter> */}
                            </Modal>
                          </div>
                          <div>
                            <Modal
                              isOpen={this.state.updateFileModal}
                              toggle={this.toggleUpdateFileModel}
                            >
                              <ModalHeader toggle={this.toggleUpdateFileModel}>
                                Edit File Details
                              </ModalHeader>
                              <ModalBody>
                                <Form>
                                  <FormGroup row>
                                    <Col sm={12}>
                                      <div className="pt-2">
                                        <Label>Title:</Label>
                                        <Input
                                          type="text"
                                          name="title"
                                          id="edit-title"
                                          value={this.state.title}
                                          onChange={this.handleInputChange}
                                        />
                                      </div>
                                    </Col>
                                    <Col sm={12}>
                                      <div className="pt-2">
                                        <Label>Description:</Label>
                                        <Input
                                          type="textarea"
                                          name="description"
                                          id="edit-description"
                                          value={this.state.description}
                                          onChange={this.handleInputChange}
                                        />
                                      </div>
                                    </Col>
                                    <Col sm={12}>
                                      <div className="pt-2 form-check">
                                        <input
                                          type="checkbox"
                                          className="form-check-input"
                                          id="feedback"
                                          name="feedback"
                                          checked={this.state.feedback}
                                          onChange={this.handleCheckboxChange}
                                          disabled={
                                            this.state.uploadProgress > 0
                                          }
                                        />
                                        <label
                                          className="form-check-label"
                                          htmlFor="feedback"
                                        >
                                          Take Feedback
                                        </label>
                                      </div>
                                    </Col>
                                    <Col sm={12}>
                                      <div className="pt-2 form-check">
                                        <input
                                          type="checkbox"
                                          className="form-check-input"
                                          id="downloadOption"
                                          name="downloadOption"
                                          checked={this.state.downloadOption}
                                          onChange={this.handleCheckboxChange}
                                          disabled={
                                            this.state.uploadProgress > 0
                                          }
                                        />
                                        <label
                                          className="form-check-label"
                                          htmlFor="downloadOption"
                                        >
                                          Give Download Option
                                        </label>
                                        <small
                                          id="downloadOptionHelp"
                                          class="form-text"
                                        >
                                          Only applicable for video and audio
                                          files.
                                        </small>
                                      </div>
                                    </Col>
                                  </FormGroup>
                                </Form>
                              </ModalBody>
                              <ModalFooter>
                                <Button
                                  color="primary"
                                  onClick={this.updateFileDetails}
                                >
                                  Save
                                </Button>{" "}
                                <Button
                                  color="secondary"
                                  onClick={this.toggleUpdateFileModel}
                                >
                                  Cancel
                                </Button>
                              </ModalFooter>
                            </Modal>
                          </div>
                          <div>
                            <CustomModal
                              open={this.state.fileViewer}
                              onCloseModal={this.togglefileViewer}
                              fileType={this.state.fileType}
                              filePath={this.state.mediaUrl}
                              fileName={this.state.filename}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <FooterAdmin />
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CategoryDetail);
