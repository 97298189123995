import React, { Component } from 'react';
import { connect } from 'react-redux';

function mapStateToProps(state) {
    return {

    };
}

class FooterAdmin extends Component {
    render() {
        return (
            <footer className="footer footer-color">
                Charitable Registration No.11927 5485 RR 0001 | © 2018 Unemployed Help
                Center of Windsor Inc.            
            </footer>
        );
    }
}

export default connect(
    mapStateToProps,
)(FooterAdmin);