import config from "../../config";
import { authHeader, handleResponse } from "../../_helpers";
import { authenticationService } from "../authentication.service";
import { encData } from "../../_helpers/_helperFunctions";
export const eventService = {
  getAllStaffMembers,
  createUpdateEvent,
  getAllEvents,
  deleteEvent,
  getEventById,
  getRegistered,
  getWishlisted,
  getEventsStats,
  getTotalRegistrationCount,
  getAllUpcomingEvents,
  getAllPastEvents,
  getAllStaffFacilitator,
};

function createUpdateEvent(data) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: encData(data),
  };
  return fetch(`${config.apiUrl}/api/v1/events`, requestOptions).then(
    handleResponse
  );
}

function getAllStaffMembers() {
  const requestOptions = { method: "GET", headers: authHeader() };
  return fetch(`${config.apiUrl}/api/v1/users/staff`, requestOptions).then(
    handleResponse
  );
}

function getAllEvents() {
  const requestOptions = { method: "GET", headers: authHeader() };
  return fetch(`${config.apiUrl}/api/v1/events`, requestOptions).then(
    handleResponse
  );
}

function deleteEvent(data) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: encData(data),
  };
  return fetch(`${config.apiUrl}/api/v1/events/delete`, requestOptions).then(
    handleResponse
  );
}

function getEventById(id) {
  const requestOptions = { method: "GET", headers: authHeader() };
  return fetch(`${config.apiUrl}/api/v1/events/${id}`, requestOptions).then(
    handleResponse
  );
}

function getRegistered(id) {
  const requestOptions = { method: "GET", headers: authHeader() };
  return fetch(
    `${config.apiUrl}/api/v1/events/registrations/${id}`,
    requestOptions
  ).then(handleResponse);
}

function getWishlisted(id) {
  const requestOptions = { method: "GET", headers: authHeader() };
  return fetch(
    `${config.apiUrl}/api/v1/events/wishlist/${id}`,
    requestOptions
  ).then(handleResponse);
}

function getEventsStats() {
  const requestOptions = { method: "GET", headers: authHeader() };
  return fetch(
    `${config.apiUrl}/api/v1/events/getEventsStats`,
    requestOptions
  ).then(handleResponse);
}

function getTotalRegistrationCount() {
  const requestOptions = { method: "GET", headers: authHeader() };
  return fetch(
    `${config.apiUrl}/api/v1/events/getTotalRegistrationCount`,
    requestOptions
  ).then(handleResponse);
}

function getAllUpcomingEvents(currentTime, type = "workshop") {
  const requestOptions = { method: "GET", headers: authHeader() };
  return fetch(
    `${config.apiUrl}/api/v1/events/current/${currentTime}?type=${type}`,
    requestOptions
  ).then(handleResponse);
}

function getAllPastEvents(currentTime, days, type = "workshop") {
  const requestOptions = { method: "GET", headers: authHeader() };
  return fetch(
    `${config.apiUrl}/api/v1/events/past/${currentTime}/${days}?type=${type}`,
    requestOptions
  ).then(handleResponse);
}

function getAllStaffFacilitator() {
  const requestOptions = { method: "GET", headers: authHeader() };
  return fetch(
    `${config.apiUrl}/api/v1/users/staff-facilitator`,
    requestOptions
  ).then(handleResponse);
}
