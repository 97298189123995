import React, { Component } from "react";
import { connect } from "react-redux";
import { Router, Route } from "react-router-dom";
import "./App.css";
import LoginPage from "./pages/LoginPage";
import ForgotPasswordPage from "./pages/ForgotPasswordPage";
import ContentLibrary from "./pages/user/ContentLibrary";
import CreateContentLibrary from "./pages/user/CreateContentLibrary";
import CategoryDetail from "./pages/user/CategoryDetail";
import ClientDashboard from "./pages/user/ClientDashboard";
import CounsellorCalender from "./pages/user/CounsellorCalender";
import { apiUrl } from "./config";
import changePassword from "./pages/user/changePassword";
import Holidays from "./pages/user/Holidays";
import CounsellorAvailability from "./pages/user/CounsellorAvailability";
import CounsellorAvailabilityOld from "./pages/user/CounsellorAvailabilityOld";
import Availability from "./pages/user/Availability";
import UserAppointment from "./pages/user/UserAppointment";
//import Appointments from "./pages/user/Appointments";
import Workshops from "./pages/user/Workshops";
import Events from "./pages/user/Events";
import AddEvent from "./pages/user/AddEvent";
import AddWorkshop from "./pages/user/AddWorkshop";
import WorkshopHistory from "./pages/user/WorkshopHistory";

import CreateUser from "./pages/admin/CreateUser";
import Users from "./pages/admin/User";
import RegisteredUser from "./pages/admin/RegisteredUserList";
import Analytics from "./pages/admin/Analytics";
import UserAnalytics from "./pages/admin/UserAnalytics";
import ListofSuperAdmin from "./pages/admin/ListofSuperUser";

import { history, Role } from "./_helpers";
import { authenticationService } from "./_services";
import { PrivateRoute } from "./components/PrivateRoute";
import Departments from "./pages/admin/Department";
import CreateDepartment from "./pages/admin/CreateDepartment";
import SubDepartments from "./pages/admin/SubDepartment";
import CreateSubDepartment from "./pages/admin/CreateSubDepartment";
import SiteLocation from "./pages/admin/SiteLocation";
import CreateSiteLocation from "./pages/admin/CreateSiteLocation";
import { fetchHolidays, setScoket } from "./redux-store/action";
import jQuery from "jquery";
import IdleTimer from "react-idle-timer";
import IdleTimeOutModal from "./components/IdleTimeOutModal";
import socketIOClient from "socket.io-client";
import { updateAppointment } from "./redux-store/action";
import ResetPasswordPage from "./pages/ResetPasswordPage";
window.jQuery = jQuery;

function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {
    fetchHolidays: () => {
      dispatch(fetchHolidays());
    },

    setScoket: (data) => {
      dispatch(setScoket(data));
    },

    updateAppointment: (data) => {
      dispatch(updateAppointment(data));
    },
  };
}

class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      currentUser: null,
      isAdmin: false,
      timeout: 1000 * 60 * 20,
      showModal: false,
      userLoggedIn: false,
      isTimedOut: false,
      isConnected: false,
    };
  }

  componentWillMount() {
    if (!localStorage.getItem("isenc")) {
      localStorage.setItem("isenc", "0");
    }
    document.addEventListener("keydown", keyDownTextField, false);

    function keyDownTextField(e) {
      var keyCode = e.keyCode;
      if (e.ctrlKey && e.altKey && e.key === "e") {
        let encMode = localStorage.getItem("isenc")
          ? localStorage.getItem("isenc")
          : "0";
        if (encMode !== "1") {
          localStorage.setItem("isenc", "1");
          alert("Enc Mode On");
        } else {
          localStorage.setItem("isenc", "0");
          alert("Enc Mode Off");
        }
      }
    }
    if (!localStorage.getItem("pemPublic")) {
      fetch("./security/public_client.pem", { mode: "no-cors" })
        .then((response) => response.text())
        .then((data) => {
          console.log("file content", data);
          fetch("./security/private_client.pem", { mode: "no-cors" })
            .then((response1) => response1.text())
            .then((data1) => {
              localStorage.setItem("pemPrivate", data1);
              localStorage.setItem("pemPublic", data);
            });
        })
        .catch((error) => console.error(error));
    }
  }
  onConnect(user) {
    this.setState({ isConnected: true })
    if (this.state.isConnected) {
      this.socket.emit("storeClientInfo", { userId: user.id });
    }
  }

  onDisconnect() {
    this.setState({ isConnected: false })
  }

  handleClose = () => {
    this.setState({ showModal: false });
  };

  handleLogout = () => {
    this.setState({ showModal: false });
    authenticationService.logout();
    history.push("/login");
  };

  onAction = (e) => {
    this.setState({ isTimedOut: false });
  };

  onActive = (e) => {
    this.setState({ isTimedOut: false });
  };

  onIdle = (e) => {
    const isTimedOut = this.state.isTimedOut;
    if (isTimedOut) {
      history.push("/login");
    } else {
      if (history.location.pathname !== "/login") {
        this.setState({ showModal: true });
        this.idleTimer.reset();
        this.setState({ isTimedOut: true });
      }
    }
  };

  render() {
    return (
      <>
        <IdleTimer
          ref={(ref) => {
            this.idleTimer = ref;
          }}
          element={document}
          onActive={this.onActive}
          onIdle={this.onIdle}
          onAction={this.onAction}
          debounce={250}
          timeout={this.state.timeout}
        />

        <Router history={history} onUpdate={(a) => console.log(a)}>
          {/* Common Routes */}
          <Route path="/login" component={LoginPage} />
          <Route path="/forgot-password" component={ForgotPasswordPage} />
          <Route path="/reset/:token" component={ResetPasswordPage} />
          {/* <Route path="/verification/:token" component={AccountVerification} /> */}

          {/* Staff's Routes */}
          <PrivateRoute
            exact
            path="/"
            roles={{ role: Role.Staff }}
            component={ClientDashboard}
          />
          <PrivateRoute
            exact
            path="/staff/contents-library"
            roles={{ role: Role.Staff }}
            component={ContentLibrary}
          />
          <PrivateRoute
            path="/staff/content-library/create"
            roles={{ role: Role.Staff }}
            component={CreateContentLibrary}
          />
          <PrivateRoute
            path="/staff/content-library/edit/:id?"
            roles={{ role: Role.Staff }}
            component={CreateContentLibrary}
          />
          <PrivateRoute
            path="/staff/category-detail/:id"
            roles={{ role: Role.Staff }}
            component={CategoryDetail}
          />
          <PrivateRoute
            path="/staff/change-password"
            roles={{ role: Role.Staff }}
            component={changePassword}
          />
          <PrivateRoute
            path="/staff/counsellor-availability"
            roles={{ role: Role.Staff }}
            component={CounsellorAvailability}
          />
          <PrivateRoute
            path="/staff/counsellor-availability-old"
            roles={{ role: Role.Staff }}
            component={CounsellorAvailabilityOld}
          />
          <PrivateRoute
            path="/staff/availability/:name/:id"
            roles={{ role: Role.Staff }}
            component={Availability}
          />
          <PrivateRoute
            path="/staff/userAppointment"
            roles={{ role: Role.Staff }}
            component={UserAppointment}
          />
          <PrivateRoute
            path="/staff/user-analytics"
            roles={{ role: Role.Staff }}
            component={UserAnalytics}
          />
          <PrivateRoute
            path="/staff/appointments"
            roles={{ role: Role.Staff }}
            component={CounsellorCalender}
          />
          <PrivateRoute
            path="/staff/workshops/list"
            roles={{ role: Role.Staff }}
            component={Workshops}
          />
          <PrivateRoute
            path="/staff/events/list"
            roles={{ role: Role.Staff }}
            component={Events}
          />
          <PrivateRoute
            exact
            path="/staff/users"
            roles={{ role: Role.Staff }}
            component={Users}
          />

          <PrivateRoute
            exact
            path="/staff/user/create"
            roles={{ role: Role.Staff }}
            component={CreateUser}
          />
          <PrivateRoute
            exact
            path="/staff/user/edit/:id?"
            roles={{ role: Role.Staff }}
            component={CreateUser}
          />
          <PrivateRoute path="/staff/events/add" component={AddEvent} />
          <PrivateRoute path="/staff/workshops/add" component={AddWorkshop} />
          <PrivateRoute
            path="/staff/workshops/edit/:id"
            component={AddWorkshop}
          />
          <PrivateRoute path="/staff/events/edit/:id" component={AddEvent} />
          <PrivateRoute
            path="/staff/workshops/history/:id"
            component={WorkshopHistory}
          />
          <PrivateRoute path="/staff/holidays" component={Holidays} />
          {/* <PrivateRoute path="/staff/counsellorCalender"  component={CounsellorCalender} /> */}

          {/* Admin's Routes */}
          <PrivateRoute
            exact
            path="/admin"
            roles={{ role: Role.Admin }}
            component={Analytics}
          />
          <PrivateRoute
            exact
            path="/admin/users"
            roles={{ role: Role.Admin }}
            component={Users}
          />
          <PrivateRoute
            exact
            path="/admin/registered-users"
            roles={{ role: Role.Admin }}
            component={RegisteredUser}
          />
          <PrivateRoute
            exact
            path="/admin/user/create"
            roles={{ role: Role.Admin }}
            component={CreateUser}
          />
          <PrivateRoute
            exact
            path="/admin/user/edit/:id?"
            roles={{ role: Role.Admin }}
            component={CreateUser}
          />
          <PrivateRoute
            path="/admin/departments"
            roles={{ role: Role.Admin }}
            component={Departments}
          />
          <PrivateRoute
            path="/admin/department/:id/sub-departments"
            roles={{ role: Role.Admin }}
            component={SubDepartments}
          />
          <PrivateRoute
            path="/admin/department/create"
            roles={{ role: Role.Admin }}
            component={CreateDepartment}
          />
          <PrivateRoute
            path="/admin/sub-department/create"
            roles={{ role: Role.Admin }}
            component={CreateSubDepartment}
          />
          <PrivateRoute
            path="/admin/department/edit/:id?"
            roles={{ role: Role.Admin }}
            component={CreateDepartment}
          />
          <PrivateRoute
            path="/admin/sub-department/edit/:id?"
            roles={{ role: Role.Admin }}
            component={CreateSubDepartment}
          />
          <PrivateRoute
            path="/admin/site-locations"
            roles={{ role: Role.Admin }}
            component={SiteLocation}
          />
          <PrivateRoute
            path="/admin/site-location/create"
            roles={{ role: Role.Admin }}
            component={CreateSiteLocation}
          />
          <PrivateRoute
            path="/admin/site-location/edit/:id?"
            roles={{ role: Role.Admin }}
            component={CreateSiteLocation}
          />
          <PrivateRoute
            path="/admin/change-password"
            roles={{ role: Role.Admin }}
            component={changePassword}
          />
          <PrivateRoute
            path="/admin/analytics"
            roles={{ role: Role.Admin }}
            component={UserAnalytics}
          />
          <PrivateRoute
            path="/admin/super-admin"
            roles={{ role: Role.Admin }}
            component={ListofSuperAdmin}
          />
        </Router>
        <IdleTimeOutModal
          showModal={this.state.showModal}
          handleClose={this.handleClose}
          handleLogout={this.handleLogout}
        />
      </>
    );
  }
  componentDidMount() {
    authenticationService.currentUser.subscribe((user) => {

      if (user && user.id) {
        this.setState({
          currentUser: user,
          isAdmin: user && user.role === Role.Admin,
        });

        this.socket = socketIOClient(apiUrl, {
          transports: ['websocket', 'polling'],
          withCredentials: true
        });
        this.props.setScoket(this.socket)
        this.socket.on("connect", this.onConnect(user));
        this.socket.on('disconnect', this.onDisconnect());

        this.socket.on("appointment", (data) => {
          if (data?.action) {
            this.props.updateAppointment(data?.action);
          }
        });
      }
    });

    let host = window.location.origin;

    let cssFiles = [];
    cssFiles.map((t) => {
      let link = document.createElement("link");
      link.href = host + t;
      link.rel = "stylesheet";
      document.head.appendChild(link);
      return true;
    });
    let scripts = [
      host + "/assets/plugins/jquery/jquery.min.js",
      // 'https://cdnjs.cloudflare.com/ajax/libs/jquery.mask/1.14.16/jquery.mask.min.js',
      "https://cdnjs.cloudflare.com/ajax/libs/jquery.inputmask/5.0.4-beta.33/bindings/inputmask.binding.min.js",
      host + "/assets/plugins/popper/popper.min.js",
      host + "/assets/plugins/bootstrap/js/bootstrap.min.js",
      host + "/assets/js/jquery.slimscroll.js",
      host + "/assets/js/waves.js",
      host + "/assets/js/sidebarmenu.js",
      host + "/assets/plugins/sticky-kit-master/dist/sticky-kit.min.js",
      host + "/assets/plugins/sparkline/jquery.sparkline.min.js",
      host + "/assets/plugins/styleswitcher/jQuery.style.switcher.js",
      host + "/assets/plugins/bootstrap-datepicker/bootstrap-datepicker.min.js",
      host + "/assets/plugins/select2/dist/js/select2.full.min.js",
      //host + "/assets/js/custom.min.js",
    ];

    scripts.map((t) => {
      let script = document.createElement("script");
      script.src = t;
      script.async = false;
      document.body.appendChild(script);
      return true;
    });

    this.props.fetchHolidays();

    // window.addEventListener("storage", function (e) {
    //   console.log("storage e: ", e);
    //   if (e.key === "currentUser") {
    //     if (e.newValue === null) {
    //       authenticationService.syncLogout();
    //     }
    //   }
    // });

    // if (
    //   window.location.hostname !== "localhost" &&
    //   window.location.hostname !== "127.0.0.1"
    // ) {
    //   window.addEventListener("beforeunload", function (e) {
    //     authenticationService.logout();
    //   });
    // }
  }


}

export default connect(mapStateToProps, mapDispatchToProps)(App);
