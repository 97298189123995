import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import Preloader from "../../components/Preloader";
import HeaderAdmin from "../../components/HeaderAdmin";
import Sidebaar from "../../components/user/Sidebaar";
import FooterAdmin from "../../components/FooterAdmin";
import { setUserData } from "../../redux-store/action";
import { userService } from "../../_services/admin";
import { authenticationService } from "../../_services";
import { MDBDataTable } from "mdbreact";
import moment from "moment";
import { eventService } from "../../_services/staff";

function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {
    setUserData: (name) => {
      dispatch(setUserData(name));
    },
  };
}

export class WorkshopHistory extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentUser: authenticationService.currentUserValue,
      userFromApi: null,
      events: [],
      registerflag: false,
      wishlistflag: false,
      wishlistedevents: [],
      eventDetails: [],
      loading: true,
      modal: false,
    };
  }

  componentDidMount() {
    console.log(this.props.match.params.id);
    const { currentUser } = this.state;
    userService.getUserById(currentUser.id).then((data) => {
      data.Data && this.props.setUserData(data.Data);
    });
    console.log(currentUser);

    this.getRegisteredEvents();
    this.getWishlistedEvents();
  }

  getDetails = (event) => {
    this.setState({
      eventDetails: event,
    });
    this.toggle();
  };

  getRegisteredEvents() {
    this.props.match.params.id &&
      eventService.getRegistered(this.props.match.params.id).then((data) => {
        if (data.Data.length) {
          this.setState({
            registerflag: true,
          });
        }
        console.log(data.Data);
        let rowsArray = [];
        data &&
          data.Data &&
          data.Data.forEach((item, i) => {
            rowsArray.push({
              key: i + 1,
              username: item.userName,
              useremail: item.userEmail,
              // date: moment(item.date? item.date[0]: item.startTime).format('MM/DD/YYYY'),
              // starttime: moment(item.startTime).format('hh:mm A'),
              // endtime: moment(item.endTime).format('hh:mm A'),
              // participants: item.participantCount + "/" + item.participantsCapacity,
            });
          });
        let tableData = {
          columns: [
            {
              label: "No.",
              field: "key",
              sort: "asc",
              width: 270,
            },
            {
              label: "User Name",
              field: "username",
              sort: "asc",
              width: 270,
            },
            {
              label: "User Email",
              field: "useremail",
              sort: "asc",
              width: 270,
            },
            // {
            //     label: "Date",
            //     field: "date",
            //     sort: "asc",
            //     width: 270,
            // },
            // {
            //     label: "Start Time",
            //     field: "starttime",
            //     sort: "asc",
            //     width: 150,
            // },
            // {
            //     label: "End Time",
            //     field: "endtime",
            //     sort: "asc",
            //     width: 150,
            // },
            // {
            //     label: "Participants",
            //     field: "participants",
            //     sort: "disabled",
            //     width: 150,
            // },
          ],
          rows: rowsArray,
        };

        this.setState({ events: tableData, loading: false });
      });
  }

  getWishlistedEvents() {
    this.props.match.params.id &&
      eventService.getWishlisted(this.props.match.params.id).then((data) => {
        if (data.Data.length) {
          this.setState({
            wishlistflag: true,
          });
        }
        let rowsArray = [];
        data &&
          data.Data &&
          data.Data.forEach((item, i) => {
            rowsArray.push({
              key: i + 1,
              username: item.userName,
              useremail: item.userEmail,
            });
          });
        let tableData1 = {
          columns: [
            {
              label: "No.",
              field: "key",
              sort: "asc",
              width: 270,
            },
            {
              label: "User Name",
              field: "username",
              sort: "asc",
              // width: 270,
            },
            {
              label: "User Email",
              field: "useremail",
              sort: "asc",
              // width: 150,
            },
          ],
          rows: rowsArray,
        };

        this.setState({ wishlistedevents: tableData1, loading: false });
      });
  }

  render() {
    const { events, wishlistedevents } = this.state;
    return (
      <div id="main-wrapper" className="fix-header fix-sidebar card-no-border">
        {this.state.loading && <Preloader />}
        <HeaderAdmin />
        <Sidebaar />
        <div className="page-wrapper">
          <div className="container-fluid">
            <div className="row page-titles">
              <div className="col-md-5 col-8 align-self-center">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <a href="#">Home</a>
                  </li>
                  <li className="breadcrumb-item active">Workshops</li>
                  <li className="breadcrumb-item active">History</li>
                </ol>
              </div>
            </div>

            <div className="row">
              <div className="col-md-12">
                <div className="pt-4">
                  <div className="card">
                    <div className="card-body">
                      <div className="d-flex justify-content-between">
                        <div className="align-self-center tbl-head">
                          <i className="mdi mdi-calendar-text mr-2"></i>
                          <span>Registered</span>
                        </div>
                        <div>
                          <Link
                            to="/staff/workshops/list"
                            className="btn btn-primary"
                          >
                            <i className="mdi mdi-chevron-left"></i>
                            <span className="hide-menu">Back</span>
                          </Link>
                        </div>
                      </div>
                      <hr />
                      {this.state.registerflag ? (
                        <MDBDataTable
                          id="event_history"
                          striped
                          bordered
                          hover
                          data={events}
                        />
                      ) : (
                        <h3 style={{ textAlign: "center" }}>
                          No Registered Users
                        </h3>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-12">
                <div className="pt-4">
                  <div className="card">
                    <div className="card-body">
                      <div className="d-flex justify-content-between">
                        <div className="align-self-center tbl-head">
                          <i className="mdi mdi-calendar-text mr-2"></i>
                          <span>Wishlisted</span>
                        </div>
                      </div>
                      <hr />
                      {this.state.wishlistflag ? (
                        <MDBDataTable
                          id="event_history"
                          striped
                          bordered
                          hover
                          data={wishlistedevents}
                        />
                      ) : (
                        <h3 style={{ textAlign: "center" }}>
                          No Wishlisted Users
                        </h3>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <FooterAdmin />
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(WorkshopHistory);
