import React, { Component } from "react";
import { connect } from "react-redux";
import Preloader from "../../components/Preloader";
import HeaderAdmin from "../../components/HeaderAdmin";
import SidebarSuperAdmin from "../../components/admin/Sidebaar";
import Sidebaar from "../../components/user/Sidebaar";
import FooterAdmin from "../../components/FooterAdmin";
import { Form, Formik, Field, ErrorMessage } from "formik";
import Select from "react-select";
import MaskedInput from "react-text-mask";
import { setUserData } from "../../redux-store/action";
import { authenticationService } from "../../_services";
import {
  departmentService,
  userService,
  subDepartmentService,
} from "../../_services/admin";
import * as Yup from "yup";
import PasswordMask from "react-password-mask";
import config from "../../config";
import axios from "axios";
import mime from "mime-types";
import { Link } from "react-router-dom";
import { ACCESS_MODULES, Role } from "../../_helpers";
import { COUNSELLOR_TOTAL_LOCATIONS } from "../../_helpers/counsellorTotalLocations";
import swal from "sweetalert";
function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {
    setUserData: (data) => {
      dispatch(setUserData(data));
    },
  };
}

function validateEmail(value) {
  let error;
  if (!value) {
    error = "Required";
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)) {
    error = "Invalid email address";
  }
  return error;
}

function getAccessModules(modules) {
  if (modules && modules.length) {
    let modulesList = [];
    modules.forEach((elem) => {
      let key = Object.keys(ACCESS_MODULES).find(
        (key) => ACCESS_MODULES[key] === elem
      );
      if (key) {
        modulesList.push({ label: key, value: elem });
      }
    });
    return modulesList;
  } else {
    return [];
  }
}

class CreateUser extends Component {
  constructor(props) {
    super(props);

    this.state = {
      currentUser: authenticationService.currentUserValue,
      user: {},
      department: [],
      subDepartment: [],
      userList: [],
      selectedDepartment: [],
      selectedSubDepartment: [],
      loading: true,
      profilepicture: "",
      url: "",
      success: false,
      imageLoading: false,
      showImgError: false,
    };
  }

  componentDidMount() {
    let scripts = ["/assets/js/custom.min.js"];
    let host = window.location.origin;
    scripts.map((t) => {
      let script = document.createElement("script");
      script.src = host + t;
      script.async = true;
      document.body.appendChild(script);
      return true;
    });
    this.getAllDepartments();
    this.getAllUsers();
    this.props.setUserData(this.state.currentUser);
    this.props.match.params.id &&
      userService.getUserById(this.props.match.params.id).then((data) => {
        if (data.Data.profileImageUrl) {
          this.setState({
            user: data.Data,
            accessModules: getAccessModules(data.Data.accessModules),
            loading: false,
            profilepicture: data.Data.profileImageUrl,
            url: data.Data.profileImageUrl,
          });
        } else {
          this.setState({
            user: data.Data,
            loading: false,
            accessModules: getAccessModules(data?.Data?.accessModules),
            profilepicture: data?.Data?.profileImageUrl,
            url: data?.Data?.profileImageUrl,
          });
        }
        setTimeout(() => {
          this.state.department.forEach((item) => {
            this.state.user.department.forEach((depres) => {
              if (item._id === depres) {
                let res = [
                  ...this.state.selectedDepartment,
                  {
                    label: item.name,
                    value: item._id,
                  },
                ];
                this.setState({
                  selectedDepartment: res,
                });
              }
            });
          });
        }, 500);
        setTimeout(() => {
          this.state.subDepartment.forEach((item) => {
            this.state.user.subDepartment.forEach((subdepres) => {
              if (item._id === subdepres) {
                let res = [
                  ...this.state.selectedSubDepartment,
                  {
                    label: item.name,
                    value: item._id,
                  },
                ];
                this.setState({
                  selectedSubDepartment: res,
                });
              }
            });
          });
        }, 600);

        setTimeout(() => {
          let supervisorId = this.state.user.supervisor;
          this.state.userList.forEach((item) => {
            if (item._id === supervisorId) {
              this.setState({
                selectedSupervisor: {
                  label: item.firstName + " " + item.lastName,
                  value: item._id,
                },
              });
            }
          });
        }, 600);
      });
    this.setState({ loading: false });
  }
  getAllDepartments() {
    departmentService
      .getAll()
      .then((data) => this.setState({ department: data.Data }));

    subDepartmentService
      .getAll()
      .then((data) => this.setState({ subDepartment: data.Data }));
  }

  getSubDepartments(id) {
    if (id.length > 0) {
      let data = {
        departmentIdList: id,
      };
      departmentService
        .getSubDepartmentsByDepartmentIdList(data)
        .then((data) => this.setState({ subDepartment: data.Data }));
    } else {
      this.setState({ subDepartment: [] });
    }
  }

  getAllUsers = () => {
    userService.getUserList().then((data) => {
      if (data.Data) {
        this.setState({ userList: data.Data });
      }
    });
  };

  getUserRole() {
    return this.state.currentUser.role === 1 ? "admin" : "staff";
  }

  profileImageEdit = () => {
    this.setState({
      profilepicture: "",
      url: "",
    });
  };

  onChangeFileHandler = (event) => {
    this.setState({
      profilepicture: "",
      url: "",
      imageLoading: true,
      showImgError: false,
    });
    if (event.target.files[0]) {
      document.getElementById("img_url").src = (
        window.URL ? URL : window.webkitURL
      ).createObjectURL(event.target.files[0]);
      let file = event.target.files[0];
      // Split the filename to get the name and type
      let fileName = "user-profile/" + event.target.files[0].name;
      let fileType = mime.lookup(event.target.files[0].name);

      axios
        .post(
          `${config.apiUrl}/api/v1/users/profile-image`,
          {
            fileName: fileName,
            fileType: fileType,
          },
          {
            headers: {
              Authorization: `Bearer ${this.state.currentUser.token}`,
              isenc: localStorage.getItem("isenc")
                ? parseInt(localStorage.getItem("isenc"))
                : 0,
            },
          }
        )
        .then((response) => {
          var returnData = response.data.Data;
          var signedRequest = returnData.signedRequest;
          var url = returnData.url;
          this.setState({ url: url });

          // Put the fileType in the headers for the upload
          var options = {
            headers: {
              "Content-Type": fileType,
            },
          };
          axios
            .put(signedRequest, file, options)
            .then((result) => {
              this.setState({ success: true, imageLoading: false });
            })
            .catch((error) => {
              alert("ERROR " + JSON.stringify(error));
            });
        })
        .catch((error) => {
          alert(JSON.stringify(error));
        });
    } else {
      document.getElementById("img_url").src = "";
      this.setState({
        url: "",
        imageLoading: false,
        showImgError: true,
      });
      // document.getElementById("validimage").innerHTML = "Choose Your Image"
    }
  };

  checkimg = () => {
    if (this.state.url) {
      this.setState({
        showImgError: false,
      });
    } else {
      this.setState({
        showImgError: true,
      });
    }
  };

  getAdminRoleOption = () => {
    if (this.state.currentUser.role === 1) {
      return <option value="1">Admin</option>;
    }
    return null;
  };

  getAdminAccessTypeOption = () => {
    if (this.state.currentUser.role === 1) {
      return <option value="3">Admin</option>;
    }
    return null;
  };

  render() {
    const departments = this.state.department;
    const subDepartment = this.state.subDepartment;
    const departmentList = departments.map((department, index) => (
      <option key={index} value={department._id}>
        {department.name}
      </option>
    ));
    const subDepartmentList = subDepartment.map((subDepartment, index) => (
      <option key={index} value={subDepartment._id}>
        {subDepartment.name}
      </option>
    ));

    return (
      <div id="main-wrapper" className="fix-header fix-sidebar card-no-border">
        {this.state.loading && <Preloader />}
        <HeaderAdmin />
        {this.state.currentUser.role === Role.Admin ? (
          <SidebarSuperAdmin />
        ) : (
          <Sidebaar />
        )}
        <div className="page-wrapper">
          <div className="container-fluid">
            <div className="row page-titles">
              <div className="col-md-5 col-8 align-self-center">
                <h3 className="text-themecolor m-b-0 m-t-0">
                  {!this.state.user._id ? "Create User" : "Edit User"}
                </h3>
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <a href="/">Home</a>
                  </li>
                  <li className="breadcrumb-item active">Create User</li>
                </ol>
              </div>
            </div>
            <div className="row mt-4row justify-content-center mt-4">
              <div className="col-md-6">
                <div className="card">
                  <Formik
                    enableReinitialize={true}
                    initialValues={{
                      userId: this.state.user._id || null,
                      firstName: this.state.user.firstName || "",
                      lastName: this.state.user.lastName || "",
                      password: "",
                      email: this.state.user.email || "",
                      dob: this.state.user.dob || "",
                      phoneNumber: this.state.user.phoneNumber || "",
                      alternativeNumber:
                        this.state.user.alternativeNumber || "",
                      homeAddress: this.state.user.homeAddress || "",
                      role: this.state.user.role || "",
                      staffType: this.state.user.staffType || "",
                      accessType: this.state.user.accessType || "",
                      currentUserId: this.state.currentUser.id,
                      department: this.state.user.department || [],
                      subDepartment: this.state.user.subDepartment || [],
                      supervisor: this.state.user.supervisor || [],
                      isActive: this.state.user.isActive || "",
                      accessModules: this.state.accessModules || [],
                      // url: this.state.user.profileImageUrl || "",
                      counsellorTotalLocations:
                        this.state.user?.counsellorTotalLocations?.length > 0
                          ? this.state.user?.counsellorTotalLocations?.map(
                              (item) => ({
                                label: item,
                                value: item,
                              })
                            )
                          : [],
                    }}
                    validationSchema={Yup.object().shape({
                      firstName: Yup.string().required("Name is required"),
                      lastName: Yup.string().required("Lastname is required"),
                      password: Yup.string().when({
                        is: (val) => (this.state.user._id ? true : false),
                        then: Yup.string().when({
                          is: (inVal) =>
                            inVal && inVal.length > 0 ? true : false,
                          then: Yup.string()
                            .matches(
                              /^(?=.*[a-z])(?=.*[A-Z])/,
                              "Must have both uppercase and lowercase letters"
                            )
                            .matches(/^(?=.*[0-9])/, "Must consist of a number")
                            .matches(
                              /^(?=.{8,})/,
                              "Must be atleast 8 character long"
                            )
                            .required("Password is required"),
                        }),
                        otherwise: Yup.string()
                          .matches(
                            /^(?=.*[a-z])(?=.*[A-Z])/,
                            "Must have both uppercase and lowercase letters"
                          )
                          .matches(/^(?=.*[0-9])/, "Must consist of a number")
                          .matches(
                            /^(?=.{8,})/,
                            "Must be atleast 8 character long"
                          )
                          .required("Password is required"),
                      }),
                      //   .required('Password is required'),
                      email: Yup.string().required("Email is required"),
                      role: Yup.string().required("Role is required"),
                      dob: Yup.string().required("DOB is Required"),
                      phoneNumber: Yup.string()
                        .required("Phone Number is Required")
                        .min(
                          14,
                          "Phone Number should contain minimum of 10 digits"
                        )
                        .max(
                          14,
                          "Phone Number should contain maximum of 10 digits"
                        ),
                      alternativeNumber: Yup.string()
                        // .required('Alternative Number is Required')
                        .min(
                          14,
                          "Alternative Number should contain minimum of 10 digits"
                        )
                        .max(
                          14,
                          "Alternative Number should contain maximum of 10 digits"
                        ),
                      // homeAddress: Yup.string().required('Home Address is Required'),
                      // accessType: Yup.string().required(
                      //   "Access type is required"
                      // ),
                      department: Yup.string().required(
                        "Department is required"
                      ),
                      // subDepartment: Yup.string().required('Sub Department is required'),
                      // url: Yup.string().required('Profile Picture is required')
                      counsellorTotalLocations: Yup.array()
                        .of(Yup.object())
                        .min(1, "Counsellor location required"),
                    })}
                    onSubmit={(formData, { setStatus, setSubmitting }) => {
                      formData.counsellorTotalLocations =
                        formData?.counsellorTotalLocations?.map(
                          (elem) => elem.value
                        );
                      formData.profileImageUrl = this.state.url;
                      formData.accessModules = formData.accessModules.map(
                        (elem) => elem.value
                      );
                      userService
                        .create(formData)
                        .then(async (user) => {
                          const message = !this.state.user._id
                            ? "User Addedd Successfully!"
                            : "User Updated Successfully!";
                          await swal(message, "", "success");
                          const { from } = this.props.location.state || {
                            from: { pathname: "/admin/users" },
                          };
                          this.props.history.push(from);
                        })
                        .catch((error) => {
                          setSubmitting(false);
                          setStatus(error);
                        });
                    }}
                    render={({
                      errors,
                      status,
                      touched,
                      isSubmitting,
                      setFieldValue,
                      values,
                      handleChange,
                    }) => (
                      <Form>
                        <div className="card-body">
                          <div className="row form-material">
                            <div className="col-md-12">
                              <div className="form-group required">
                                <label className="control-label">
                                  First Name
                                </label>
                                <Field
                                  name="firstName"
                                  type="text"
                                  placeholder="Name"
                                  className={
                                    "form-control" +
                                    (errors.firstName && touched.firstName
                                      ? " is-invalid"
                                      : "")
                                  }
                                />
                                <ErrorMessage
                                  name="firstName"
                                  component="div"
                                  className="invalid-feedback"
                                />
                              </div>
                              <div className="form-group required">
                                <label className="control-label">
                                  {" "}
                                  Last Name
                                </label>
                                <Field
                                  name="lastName"
                                  type="text"
                                  placeholder="Last Name"
                                  className={
                                    "form-control" +
                                    (errors.lastName && touched.lastName
                                      ? " is-invalid"
                                      : "")
                                  }
                                />
                                <ErrorMessage
                                  name="lastName"
                                  component="div"
                                  className="invalid-feedback"
                                />
                              </div>
                              <div className="form-group required">
                                <label className="control-label">Email</label>
                                <Field
                                  name="email"
                                  type="text"
                                  placeholder="Email"
                                  validate={validateEmail}
                                  className={
                                    "form-control" +
                                    (errors.email && touched.email
                                      ? " is-invalid"
                                      : "")
                                  }
                                />
                                <ErrorMessage
                                  name="email"
                                  component="div"
                                  className="invalid-feedback"
                                />
                              </div>
                              <div
                                className={
                                  !this.state.user._id
                                    ? "form-group required"
                                    : "form-group"
                                }
                              >
                                <label className="control-label">
                                  Password
                                </label>
                                <Field
                                  name="password"
                                  placeholder="Password"
                                  autoComplete="off"
                                  render={({ field }) => (
                                    <PasswordMask
                                      inputStyles={{ border: "none" }}
                                      buttonStyles={{
                                        top: "5px",
                                        backgroundColor: "transparent",
                                      }}
                                      showButtonContent={
                                        <i
                                          className="ti-eye"
                                          style={{
                                            color: "#ccc",
                                            backgroundColor: "transparent",
                                          }}
                                        ></i>
                                      }
                                      hideButtonContent={
                                        <i
                                          className="ti-eye"
                                          style={{
                                            backgroundColor: "transparent",
                                          }}
                                        ></i>
                                      }
                                      onChange={(password) =>
                                        password === null
                                          ? setFieldValue("password", "")
                                          : setFieldValue("password", password)
                                      }
                                      {...field}
                                      className={
                                        "form-control" +
                                        (errors.password && touched.password
                                          ? " is-invalid"
                                          : "")
                                      }
                                    />
                                  )}
                                />
                                <ErrorMessage
                                  name="password"
                                  component="div"
                                  className="invalid-feedback"
                                />
                              </div>
                              <div className="form-group required">
                                <label className="control-label">Role</label>
                                <Field
                                  name="role"
                                  as="select"
                                  className={
                                    "form-control" +
                                    (errors.role && touched.role
                                      ? " is-invalid"
                                      : "")
                                  }
                                  placeholder="Select Role"
                                >
                                  <option value="">Select Role</option>
                                  {this.getAdminRoleOption()}
                                  <option value="2">Staff</option>
                                </Field>
                                <ErrorMessage
                                  name="role"
                                  component="div"
                                  className="invalid-feedback"
                                />
                              </div>
                              <div className="form-group required">
                                <label className="control-label">
                                  Counsellor Location
                                </label>
                                <Select
                                  autoComplete="off"
                                  className={
                                    "form-control" +
                                    (errors.counsellorTotalLocations &&
                                    touched.counsellorTotalLocations
                                      ? " is-invalid"
                                      : "")
                                  }
                                  name="counsellorTotalLocations"
                                  id="counsellorTotalLocations"
                                  value={values?.counsellorTotalLocations}
                                  onChange={(selectedLocationOption) => {
                                    const locationArray = [];
                                    selectedLocationOption &&
                                      selectedLocationOption.forEach((item) => {
                                        locationArray.push(item.label);
                                      });
                                    setFieldValue(
                                      "counsellorTotalLocations",
                                      selectedLocationOption || []
                                    );
                                  }}
                                  placeholder="Select Location"
                                  options={COUNSELLOR_TOTAL_LOCATIONS}
                                  isSearchable
                                  isClearable
                                  isMulti
                                />
                                <ErrorMessage
                                  name="counsellorTotalLocations"
                                  component="div"
                                  className="invalid-feedback"
                                />
                              </div>
                              <div className="form-group required">
                                <label className="control-label d-inline-block w-100">
                                  Department
                                </label>
                                <Field
                                  name="department"
                                  type="text"
                                  autoComplete="off"
                                  className={
                                    "form-control" +
                                    (errors.department && touched.department
                                      ? " is-invalid"
                                      : "")
                                  }
                                  render={({ field }) => (
                                    <Select
                                      isMulti
                                      isSearchable
                                      onChange={(e) => {
                                        let departmentIdList = [];
                                        if (e) {
                                          e.forEach((item) => {
                                            departmentIdList.push(item.value);
                                          });
                                        }
                                        this.setState({
                                          selectedDepartment: e,
                                        });
                                        setFieldValue(
                                          "department",
                                          departmentIdList
                                        );
                                        this.getSubDepartments(
                                          departmentIdList
                                        );
                                      }}
                                      className={" "}
                                      value={this.state.selectedDepartment}
                                      options={this.state.department?.map(
                                        (department, index) => ({
                                          label: department.name,
                                          value: department._id,
                                        })
                                      )}
                                      placeholder="Select Department"
                                    />
                                  )}
                                />
                                <div>
                                  {touched.department && errors.department && (
                                    <div
                                      style={{
                                        width: "100%",
                                        marginTop: ".25rem",
                                        fontSize: "80%",
                                        color: "#dc3545",
                                      }}
                                    >
                                      {errors.department}
                                    </div>
                                  )}
                                </div>
                                <ErrorMessage
                                  name="department"
                                  component="div"
                                  className="invalid-feedback"
                                />
                              </div>

                              <div className="form-group">
                                <label className="control-label d-inline-block w-100">
                                  Supervisor
                                </label>
                                <Field
                                  name="supervisor"
                                  className={
                                    "form-control" +
                                    (errors.supervisor && touched.supervisor
                                      ? " is-invalid"
                                      : "")
                                  }
                                  render={({ field }) => (
                                    <Select
                                      isSearchable
                                      onChange={(e) => {
                                        this.setState({
                                          selectedSupervisor: e,
                                        });
                                        setFieldValue("supervisor", e.value);
                                      }}
                                      value={this.state.selectedSupervisor}
                                      options={this.state.userList?.map(
                                        (user, index) => ({
                                          label:
                                            user.firstName +
                                            " " +
                                            user.lastName,
                                          value: user._id,
                                        })
                                      )}
                                      placeholder="Select Supervisor"
                                    />
                                  )}
                                />
                                <div>
                                  {touched.supervisor && errors.supervisor && (
                                    <div
                                      style={{
                                        width: "100%",
                                        marginTop: ".25rem",
                                        fontSize: "80%",
                                        color: "#dc3545",
                                      }}
                                    >
                                      {errors.supervisor}
                                    </div>
                                  )}
                                </div>

                                {/* <ErrorMessage name="subDpartment" component="div" className="invalid-feedback" /> */}
                              </div>

                              <div className="form-group required">
                                <label className="control-label">
                                  Access Type
                                </label>
                                <Field
                                  name="accessType"
                                  as="select"
                                  className={
                                    "form-control" +
                                    (errors.accessType && touched.accessType
                                      ? " is-invalid"
                                      : "")
                                  }
                                  placeholder="Select Access type"
                                >
                                  <option value="">Select Access type</option>
                                  <option value="1">Read</option>
                                  <option value="2">ReadWrite</option>
                                  {this.getAdminAccessTypeOption()}
                                </Field>
                                <ErrorMessage
                                  name="accessType"
                                  component="div"
                                  className="invalid-feedback"
                                />
                              </div>
                              <div className="form-group required">
                                <label className="control-label"> DOB</label>
                                {/* <Field name="dob" type="text" placeholder="DOB"
                                                         className={'form-control' + (errors.dob && touched.dob ? ' is-invalid' : '')} 
                                                         
                                                         /> */}
                                <MaskedInput
                                  mask={[
                                    /\d/,
                                    /\d/,
                                    "/",
                                    /\d/,
                                    /\d/,
                                    "/",
                                    /\d/,
                                    /\d/,
                                    /\d/,
                                    /\d/,
                                  ]}
                                  name="dob"
                                  onChange={handleChange}
                                  className={
                                    "form-control" +
                                    (errors.dob && touched.dob
                                      ? " is-invalid"
                                      : "")
                                  }
                                  placeholder="MM/DD/YYYY"
                                  guide={false}
                                  type="text"
                                  value={values.dob}
                                  render={(ref, props) => (
                                    <input
                                      type="text"
                                      label="Date of Birth"
                                      name="dob"
                                      ref={ref}
                                      value={values.dob}
                                      {...props}
                                    />
                                  )}
                                />
                                <ErrorMessage
                                  name="dob"
                                  component="div"
                                  className="invalid-feedback"
                                />
                              </div>

                              <div className="form-group required">
                                <label className="control-label">
                                  {" "}
                                  Phone Number
                                </label>
                                <MaskedInput
                                  mask={[
                                    "(",
                                    /[1-9]/,
                                    /\d/,
                                    /\d/,
                                    ")",
                                    " ",
                                    /\d/,
                                    /\d/,
                                    /\d/,
                                    "-",
                                    /\d/,
                                    /\d/,
                                    /\d/,
                                    /\d/,
                                  ]}
                                  name="phoneNumber"
                                  onChange={handleChange}
                                  className={
                                    "form-control" +
                                    (errors.phoneNumber && touched.phoneNumber
                                      ? " is-invalid"
                                      : "")
                                  }
                                  placeholder="Phone Number"
                                  guide={false}
                                  type="text"
                                  value={values.phoneNumber}
                                  render={(ref, props) => (
                                    <input
                                      type="text"
                                      label="Phone Number"
                                      name="phoneNumber"
                                      ref={ref}
                                      value={values.phoneNumber}
                                      {...props}
                                    />
                                  )}
                                />
                                <ErrorMessage
                                  name="phoneNumber"
                                  component="div"
                                  className="invalid-feedback"
                                />
                              </div>

                              <div className="form-group">
                                <label className="control-label">
                                  {" "}
                                  Alternative Number
                                </label>
                                <MaskedInput
                                  mask={[
                                    "(",
                                    /[1-9]/,
                                    /\d/,
                                    /\d/,
                                    ")",
                                    " ",
                                    /\d/,
                                    /\d/,
                                    /\d/,
                                    "-",
                                    /\d/,
                                    /\d/,
                                    /\d/,
                                    /\d/,
                                  ]}
                                  name="alternativeNumber"
                                  onChange={handleChange}
                                  className={
                                    "form-control" +
                                    (errors.alternativeNumber &&
                                    touched.alternativeNumber
                                      ? " is-invalid"
                                      : "")
                                  }
                                  placeholder="Phone Number"
                                  guide={false}
                                  type="text"
                                  value={values.alternativeNumber}
                                  render={(ref, props) => (
                                    <input
                                      type="text"
                                      label="Phone Number"
                                      name="alternativeNumber"
                                      ref={ref}
                                      value={values.alternativeNumber}
                                      {...props}
                                    />
                                  )}
                                />
                                <ErrorMessage
                                  name="alternativeNumber"
                                  component="div"
                                  className="invalid-feedback"
                                />
                              </div>

                              <div className="form-group">
                                <label className="control-label">
                                  {" "}
                                  Address
                                </label>
                                <Field
                                  name="homeAddress"
                                  type="text"
                                  placeholder="Address"
                                  className={
                                    "form-control" +
                                    (errors.homeAddress && touched.homeAddress
                                      ? " is-invalid"
                                      : "")
                                  }
                                />
                                <ErrorMessage
                                  name="homeAddress"
                                  component="div"
                                  className="invalid-feedback"
                                />
                              </div>

                              <div className="form-group">
                                <div>
                                  <label className="control-label">
                                    {" "}
                                    Profile Picture
                                  </label>
                                </div>
                                {/* <Field
                                                                    name="url"
                                                                    className={'form-control' + (errors.url && touched.url ? ' is-invalid' : '')}
                                                                    render={({ field }) => ( */}
                                <div
                                  className="d-flex"
                                  style={{ marginTop: "5px" }}
                                >
                                  <div
                                    className="d-flex"
                                    style={{ flexDirection: "column" }}
                                  >
                                    <input
                                      name="url"
                                      className="fullWidth"
                                      // className={(errors.url && touched.url ? ' is-invalid' : '')}
                                      type="file"
                                      onChange={this.onChangeFileHandler}
                                      onClick={this.profileImageEdit}
                                    />
                                    {this.state.showImgError && (
                                      <span
                                        style={{
                                          color: "#dc3545",
                                          marginTop: ".25rem",
                                          fontSize: "80%",
                                        }}
                                      >
                                        Profile Picture is Required
                                      </span>
                                    )}
                                  </div>
                                  {this.state.imageLoading && (
                                    <img
                                      style={{ height: "27px" }}
                                      src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA=="
                                    />
                                  )}
                                  {this.state.profilepicture ? (
                                    <img
                                      src={this.state.profilepicture}
                                      id="img_url"
                                      alt="your image"
                                    ></img>
                                  ) : (
                                    <img
                                      src=""
                                      id="img_url"
                                      alt="your image"
                                    ></img>
                                  )}
                                </div>
                                {/* <ErrorMessage name="url" component="div" className="invalid-feedback" /> */}
                              </div>

                              <div className="form-group">
                                <label className="control-label d-inline-block w-100">
                                  Access Modules
                                </label>
                                <Field
                                  name="accessModules"
                                  type="text"
                                  autoComplete="off"
                                  className={
                                    "form-control" +
                                    (errors.accessModules &&
                                    touched.accessModules
                                      ? " is-invalid"
                                      : "")
                                  }
                                  render={({ field }) => (
                                    <Select
                                      isMulti
                                      isSearchable
                                      onChange={(e) => {
                                        if (e) {
                                          setFieldValue("accessModules", e);
                                        } else {
                                          setFieldValue("accessModules", []);
                                        }
                                      }}
                                      className={" "}
                                      value={values.accessModules}
                                      options={Object.keys(ACCESS_MODULES).map(
                                        (key) => {
                                          return {
                                            label: key,
                                            value: ACCESS_MODULES[key],
                                          };
                                        }
                                      )}
                                      placeholder="Select Modules"
                                    />
                                  )}
                                />
                                <div>
                                  {touched.accessModules &&
                                    errors.accessModules && (
                                      <div
                                        style={{
                                          width: "100%",
                                          marginTop: ".25rem",
                                          fontSize: "80%",
                                          color: "#dc3545",
                                        }}
                                      >
                                        {errors.accessModules}
                                      </div>
                                    )}
                                </div>
                                <ErrorMessage
                                  name="accessModules"
                                  component="div"
                                  className="invalid-feedback"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="card-footer accordionfooter d-flex">
                          <div style={{ marginRight: "5px" }}>
                            <Link
                              to="/admin/users"
                              className="btn btn-secondary"
                              style={{
                                backgroundColor: "grey",
                                color: "white",
                              }}
                            >
                              {/* <i className="mdi mdi-chevron-left"></i> */}
                              <span className="">Cancel</span>
                            </Link>
                          </div>
                          <button
                            type="submit"
                            disabled={isSubmitting}
                            className="btn btn-primary"
                          >
                            {" "}
                            {!this.state.user._id
                              ? "Save User"
                              : "Update User"}{" "}
                          </button>
                          {isSubmitting && (
                            <img src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
                          )}
                        </div>
                        {status && (
                          <div className={"alert alert-danger"}>{status}</div>
                        )}
                      </Form>
                    )}
                  />
                </div>
              </div>
            </div>
          </div>
          <FooterAdmin />
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateUser);
