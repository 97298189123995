export const IMAGE_TYPES = ["jpg", "jpeg", "png", "svg", "gif", "webp"];
export const AUDIO_FILES = [
  "mp3",
  "wav",
  "ogg",
  "aac",
  "flac",
  "alac",
  "dsd",
  "aiff",
  "m4a",
  "wma",
];
export const VIDEO_FILES = [
  "mp4",
  "wmv",
  "ogv",
  "avi",
  "flv",
  "3gp",
  "mov",
  "webm",
  "3g2",
  "mpg",
  "mkv",
  "m4v",
  "m4a",
  "f4v",
  "f4a",
  "m4b",
  "m4r",
  "f4b",
  "mpeg",
];

export const DOCS_AND_IMAGES = [
  "csv",
  "xlsx",
  "docx",
  "png",
  "jpg",
  "jpeg",
  "webp",
  "svg",
];
