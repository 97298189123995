import React, { Component } from "react";
import { connect } from "react-redux";
import Preloader from "../../components/Preloader";
import HeaderAdmin from "../../components/HeaderAdmin";
import Sidebar from "../../components/user/Sidebaar";
import FooterAdmin from "../../components/FooterAdmin";
import { Link } from "react-router-dom";
import { holidayService } from "../../_services/staff";
import { authenticationService } from "../../_services";
import { setUserData, fetchHolidays } from "../../redux-store/action";
import { MDBDataTable } from "mdbreact";
import moment from "moment";
import DeleteConfirmation from "../../shared/DeleteConfirmation";
import swal from "sweetalert";
import { Calendar } from "primereact/calendar";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { holidayDateList } from "../../_helpers/_helperFunctions";
function mapStateToProps(state) {
  return {
    holidayList: state.holidayList,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setUserData: (data) => {
      dispatch(setUserData(data));
    },
    fetchHolidays: () => dispatch(fetchHolidays()),
  };
}

class Holidays extends Component {
  constructor(props) {
    super(props);
    this.delete = this.delete.bind(this);

    this.state = {
      currentUser: authenticationService.currentUserValue,
      holidays: {},
      loading: true,
      holidayModal: false,
      date: null,
      holidayId: "",
    };
  }

  componentDidMount() {
    this.getAllHolidays();
  }

  getAllHolidays() {
    holidayService.getAllHolidays().then((data) => {
      let rowsArray = [];
      data &&
        data.Data &&
        data.Data.map((item, i) => {
          rowsArray.push({
            key: i + 1,
            date: moment(item.date, "MM/DD/YYYY").format("dddd, MMMM Do YYYY"),
            createdAt: moment(item.createdAt, "x").format("MM/DD/YYYY"),
            action: (
              <React.Fragment>
                <button
                  className="btn btn-link"
                  data-toggle="tooltip"
                  data-original-title="Edit"
                  onClick={(e) => this.toggleHolidayModal(item)}
                >
                  <i className="fas fa-pencil-alt text-inverse m-r-10"></i>
                </button>
                <a
                  onClick={() => document.getElementById(item._id).click()}
                  className="cursor-pointer"
                  data-toggle="tooltip"
                  data-original-title="Delete"
                >
                  {" "}
                  <i className="fas fa-trash text-danger"></i>{" "}
                </a>
                <DeleteConfirmation
                  id={item._id}
                  confirm={() => this.delete(item._id)}
                ></DeleteConfirmation>
              </React.Fragment>
            ),
          });
          return true;
        });
      const tableData = {
        columns: [
          // {
          //   label: "#",
          //   field: "key",
          //   sort: "asc",
          //   width: 270,
          // },
          {
            label: "Date",
            field: "date",
            sort: "asc",
            width: 150,
          },
          // {
          //   label: "Access",
          //   field: "access",
          //   sort: "asc",
          //   width: 270,
          // },
          {
            label: "Created At",
            field: "createdAt",
            sort: "asc",
            width: 270,
          },
          {
            label: "Action",
            field: "action",
            sort: "disabled",
            width: 270,
          },
        ],
        rows: rowsArray,
      };
      this.setState({ holidays: tableData, loading: false });
    });
  }

  delete(holydayId) {
    holidayService
      .deleteHoliday(holydayId, this.state.currentUser.id)
      .then((response) => {
        response.Status && this.getAllHolidays();
        this.props.fetchHolidays();
        swal("Holiday Deleted Succesfully!", "", "success");
      })
      .catch((err) => {
        console.log(err);
        swal("Opps Something Went Wrong!", "", "error");
      });
  }

  toggleHolidayModal = (item) => {
    if (item) {
      this.setState({
        holidayModal: !this.state.holidayModal,
        holidayId: item._id,
        date: moment(item.date).toDate(),
      });
    } else {
      this.setState({ holidayModal: !this.state.holidayModal, holidayId: "" });
    }
  };

  saveHoliday = () => {
    let requestData = {
      currentUserId: this.state.currentUser.id,
      date: moment(this.state.date).format("MM/DD/YYYY"),
    };

    if (this.state.holidayId) {
      requestData.holidayId = this.state.holidayId;
    }

    holidayService.create(requestData).then((data) => {
      if (data.Status) {
        swal("Holiday Saved Succesfully!", "", "success");
        this.toggleHolidayModal();
        this.getAllHolidays();
        this.props.fetchHolidays();
      }
    });
  };

  render() {
    const { holidays } = this.state;

    return (
      <div id="main-wrapper" className="fix-header fix-sidebar card-no-border">
        {this.state.loading && <Preloader />}
        <HeaderAdmin />
        <Sidebar />
        <div className="page-wrapper">
          <div className="container-fluid">
            <div className="row page-titles">
              <div className="col-md-5 col-8 align-self-center">
                {/* <h3 className="text-themecolor m-b-0 m-t-0">Department</h3> */}
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <a href="/">Home</a>
                  </li>
                  <li className="breadcrumb-item active">Holidays</li>
                </ol>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <div className="pt-4">
                  <div className="card">
                    <div className="card-body">
                      <div className="d-flex justify-content-between">
                        <div className="align-self-center tbl-head">
                          <i className="mdi mdi-calendar-text mr-2"></i>
                          <span>Holidays</span>
                        </div>
                        {this.state.currentUser.accessType == 2 && (
                          <button
                            className="btn btn-primary"
                            onClick={this.toggleHolidayModal}
                          >
                            Add Holiday
                          </button>
                        )}
                      </div>
                      <hr />
                      {holidays.rows && holidays.rows.length ? (
                        <MDBDataTable striped bordered hover data={holidays} />
                      ) : (
                        <div className="text-center">
                          <h3>No Holidays Added</h3>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Modal
            isOpen={this.state.holidayModal}
            toggle={this.toggleHolidayModal}
          >
            <ModalHeader toggle={this.toggleHolidayModal}>
              Add/Edit Holiday
            </ModalHeader>
            <ModalBody>
              <Calendar
                id="calenderElem"
                className="with-holiday"
                dateFormat="dd, M yy, DD"
                // selectionMode="multiple"
                value={this.state.date}
                showIcon={true}
                onChange={(e) => {
                  this.setState({
                    date: e.value,
                  });
                }}
                disabledDates={holidayDateList(this.props.holidayList)}
                readOnlyInput
              ></Calendar>
            </ModalBody>
            <ModalFooter>
              <Button color="primary" onClick={this.saveHoliday}>
                Save
              </Button>{" "}
              <Button color="secondary" onClick={this.toggleHolidayModal}>
                Cancel
              </Button>
            </ModalFooter>
          </Modal>
          <FooterAdmin />
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Holidays);
