import React, { Component } from "react";
import { appointmentService } from "../../_services/staff/appointment.service";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
am4core.useTheme(am4themes_animated);
export default class ITDepartmentDashboard extends Component {
  state = {
    assignedBookingCount: 0,
    pendingBookingCount: 0,
    showBarChart: true,
  };
  componentDidMount() {
    // let scripts = [
    //   "/assets/plugins/echarts/echarts-all.js",
    //   "/assets/plugins/echarts/echarts-init.js",
    //   "/assets/js/custom.min.js",
    // ];
    // let host = window.location.origin;
    // console.log("host", host);
    // scripts.map((t) => {
    //   let script = document.createElement("script");
    //   script.src = host + t;
    //   script.async = false;

    //   document.body.appendChild(script);
    //   return true;
    // });
    // console.log("compend");

    appointmentService
      .getCounsellorAppointmentsStats(new Date().getTime())
      .then((data) => {
        console.log("data: ", data);
        if (data && data.Status) {
          if (data.Data.assignedCount) {
            let counsellorData = data.Data.counsellors.map((counsellor) => {
              return {
                name: counsellor.firstName,
                total: counsellor.bookings.length,
                attended: counsellor.bookings.filter((booking) => {
                  return booking.toAttend === "Yes";
                }).length,
              };
            });

            let chart5 = am4core.create(
              "barchartAssignedBookings",
              am4charts.XYChart
            );

            chart5.data = counsellorData;

            // Create axes
            let categoryAxis5 = chart5.xAxes.push(new am4charts.CategoryAxis());
            categoryAxis5.dataFields.category = "name";
            categoryAxis5.renderer.grid.template.location = 0;
            categoryAxis5.renderer.minGridDistance = 5;
            categoryAxis5.renderer.labels.template.rotation = 45;
            categoryAxis5.renderer.labels.template.fontSize = 13;
            categoryAxis5.renderer.labels.template.wrap = true;
            categoryAxis5.renderer.labels.template.maxWidth = 150;
            // categoryAxis5.renderer.labels.template.verticalCenter = "middle";
            categoryAxis5.renderer.labels.template.horizontalCenter = "left";
            // categoryAxis5.renderer.labels.template.adapter.add("dy", function(dy, target) {
            //     if (target.dataItem && target.dataItem.index & 2 == 2) {
            //         return dy ;
            //     }
            //     return dy;
            // });

            let title5 = chart5.titles.create();
            title5.text = `Appoinment Assignment Counsellors`;
            title5.fontSize = 18;

            let valueAxis5 = chart5.yAxes.push(new am4charts.ValueAxis());
            // valueAxis5.renderer.inside = true;
            // valueAxis5.renderer.labels.template.disabled = true;
            valueAxis5.title.text = "No of Appointments";

            valueAxis5.min = 0;

            // Create series
            const createSeries5 = (field, name, colorset) => {
              // Set up series
              let series1 = chart5.series.push(new am4charts.ColumnSeries());
              series1.name = name;
              series1.dataFields.valueY = field;
              series1.dataFields.categoryX = "name";
              series1.sequencedInterpolation = true;
              series1.fill = am4core.color(colorset);
              // Make it stacked
              series1.stacked = true;

              // Configure columns
              series1.columns.template.width = am4core.percent(60);
              series1.columns.template.tooltipText =
                "[bold]{name}[/]\n[font-size:14px]{categoryX}: {valueY}";

              // Add label
              let labelBullet1 = series1.bullets.push(
                new am4charts.LabelBullet()
              );
              labelBullet1.label.text = "{valueY}";
              labelBullet1.locationY = 0.5;
              labelBullet1.label.hideOversized = true;

              return series1;
            };

            createSeries5("total", "Assigned", "#67B7DB");
            createSeries5("attended", "Attended", "#6691D7");

            // Legend
            chart5.legend = new am4charts.Legend();
            chart5.exporting.menu = new am4core.ExportMenu();
            //
            this.chart5 = chart5;

            // Events Analysis End
          } else {
            this.setState({ showBarChart: false });
          }

          this.setState({
            pendingBookingCount: data.Data.pendingCount,
            assignedBookingCount: data.Data.assignedCount,
          });
        }
      });
  }

  componentWillUnmount() {
    if (this.chart5) {
      this.chart5.dispose();
    }
  }

  render() {
    return (
      <div className="row">
        <div className="col-md-12">
          <div className="pt-4">
            <div className="card">
              <div className="card-body">
                <div className="row" style={{ justifyContent: "center" }}>
                  <div className="col-sm-4">
                    <div className="card">
                      <div className="card-body d-flex">
                        <div className="round round-lg align-self-center round-info">
                          <i className="mdi mdi-book-open"></i>
                        </div>
                        <div className="m-l-10 align-self-center dashboard_cardTitle">
                          <h4 className="card-title">Appointment Requests</h4>
                          <p className="card-text dashboard_cardtext">
                            {this.state.pendingBookingCount}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  {this.state.showBarChart ? (
                    <div
                      id="barchartAssignedBookings"
                      style={{ width: "100%", height: "400px" }}
                    ></div>
                  ) : (
                    <div className="text-center">
                      <h3>
                        Currently there are no appointments assigned to the
                        counselors
                      </h3>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
