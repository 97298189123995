import { holidayService } from "../_services/staff";
// We speciify the name of the action as a variable
export const SET_USERDATA = "SET_USERDATA";
export const IS_LOADING = "IS_LOADING";
export const SET_DEPARTMENTS = "SET_DEPARTMENTS";
export const SET_SELECTED_FORM = "SET_SELECTED_FORM";
export const SET_TAB_NAME = "SET_TAB_NAME";
export const SET_CHILD_FORM = "SET_CHILD_FORM";
export const SET_HOLIDAYS = "SET_HOLIDAYS";
export const UPDATE_APPOINTMENT = "UPDATE_APPOINTMENT";
export const SET_SELECTED_DEPARTMENT = "SET_SELECTED_DEPARTMENT";
export const SET_SOCKET = "SET_SOCKET";
export const setUserData = (data) => {
  return {
    type: SET_USERDATA,
    payload: data,
  };
};
export const setDepartmentsData = (data) => {
  return {
    type: SET_DEPARTMENTS,
    payload: data,
  };
};
export const setLoader = (data) => {
  return {
    type: IS_LOADING,
    payload: data,
  };
};
export const setSelectedForm = (data) => {
  return {
    type: SET_SELECTED_FORM,
    payload: data,
  };
};
export const setChildForm = (data) => {
  return {
    type: SET_CHILD_FORM,
    payload: data,
  };
};
export const setTabName = (data) => {
  return {
    type: SET_TAB_NAME,
    payload: data,
  };
};

export const setHolidays = (data) => {
  return {
    type: SET_HOLIDAYS,
    payload: data,
  };
};

export const fetchHolidays = (forPerson) => {
  return function (dispatch) {
    return holidayService.getAllHolidays().then((data) => {
      if (data && data.Status) {
        dispatch(setHolidays(data.Data));
      }
    });
  };
};

export const updateAppointment = (data) => {
  return {
    type: UPDATE_APPOINTMENT,
    payload: data,
  };
};

export const setSelectedDepartment = (data) => {
  return {
    type: SET_SELECTED_DEPARTMENT,
    payload: data,
  };
};

export const setScoket = (data) => {
  return {
    type: SET_SOCKET,
    payload: data,
  };
};
