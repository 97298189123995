import React, { Component } from "react";
import { connect } from "react-redux";
import Preloader from "../../components/Preloader";
import HeaderAdmin from "../../components/HeaderAdmin";
import SidebarSuperAdmin from "../../components/admin/Sidebaar";
import FooterAdmin from "../../components/FooterAdmin";
import { Link } from "react-router-dom";
import { siteLocationService } from "../../_services/admin";
import { authenticationService } from "../../_services";
import { setUserData } from "../../redux-store/action";
import Moment from "react-moment";
import { MDBDataTable } from "mdbreact";
import moment from "moment";
import DeleteConfirmation from "../../shared/DeleteConfirmation";
import swal from "sweetalert";
function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {
    setUserData: (data) => {
      dispatch(setUserData(data));
    },
  };
}

class SiteLocation extends Component {
  constructor(props) {
    super(props);
    this.delete = this.delete.bind(this);

    this.state = {
      currentUser: authenticationService.currentUserValue,
      siteLocation: [],
      loading: true,
    };
  }

  componentDidMount() {
    this.getAllSiteLocations();
    this.props.setUserData(this.state.currentUser);
  }

  getAllSiteLocations() {
    siteLocationService.getAll().then((data) => {
      let rowsArray = [];
      data &&
        data.Data &&
        data.Data.map((item, i) => {
          rowsArray.push({
            key: i + 1,
            // createdAt: <Moment format="MM/DD/YYYY">{item.createdAt}</Moment>,
            siteLocation: item.name,
            createdAt: moment(item.createdAt, "x").format("MM/DD/YYYY"),
            action: (
              <React.Fragment>
                <Link
                  to={"/admin/site-location/edit/" + item._id}
                  data-toggle="tooltip"
                  data-original-title="Edit"
                >
                  {" "}
                  <i className="fas fa-pencil-alt text-inverse m-r-10"></i>{" "}
                </Link>
                <a
                  onClick={() => document.getElementById(item._id).click()}
                  data-toggle="tooltip"
                  data-original-title="Delete"
                >
                  {" "}
                  <i className="fas fa-trash text-danger"></i>{" "}
                </a>
                <DeleteConfirmation
                  id={item._id}
                  confirm={() => this.delete(item._id)}
                ></DeleteConfirmation>
              </React.Fragment>
            ),
          });
          return true;
        });
      const tableData = {
        columns: [
          // {
          //   label: "#",
          //   field: "key",
          //   sort: "asc",
          //   width: 270,
          // },
          {
            label: "Site Location",
            field: "siteLocation",
            sort: "asc",
            width: 150,
          },
          // {
          //   label: "Access",
          //   field: "access",
          //   sort: "asc",
          //   width: 270,
          // },
          {
            label: "Created At",
            field: "createdAt",
            sort: "asc",
            width: 270,
          },
          {
            label: "Action",
            field: "action",
            sort: "disabled",
            width: 270,
          },
        ],
        rows: rowsArray,
      };
      this.setState({ siteLocation: tableData, loading: false });
    });
  }

  delete(siteLocationId) {
    siteLocationService
      .deleteSiteLocation(siteLocationId, this.state.currentUser.id)
      .then((response) => {
        response.Status && this.getAllSiteLocations();
        swal("Site Location Deleted Succesfully!", "", "success");
      })
      .catch((err) => {
        console.log(err);
        swal("Opps Something Went Wrong!", "", "error");
      });
  }

  render() {
    const { siteLocation } = this.state;

    return (
      <div id="main-wrapper" className="fix-header fix-sidebar card-no-border">
        {this.state.loading && <Preloader />}
        <HeaderAdmin />
        <SidebarSuperAdmin />
        <div className="page-wrapper">
          <div className="container-fluid">
            <div className="row page-titles">
              <div className="col-md-5 col-8 align-self-center">
                {/* <h3 className="text-themecolor m-b-0 m-t-0">Department</h3> */}
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <a href="#">Home</a>
                  </li>
                  <li className="breadcrumb-item active">Site Location</li>
                </ol>
              </div>
              <div className="col-md-7 col-4 align-self-center text-right">
                <Link
                  to="/admin/site-location/create"
                  className="btn btn-primary"
                >
                  <i className="mdi mdi-account-plus"></i>{" "}
                  <span className="hide-menu">Create Site Location</span>
                </Link>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <div className="pt-4">
                  <div className="card">
                    <div className="card-body">
                      <MDBDataTable
                        responsive
                        striped
                        bordered
                        hover
                        data={siteLocation}
                      />
                      {/* <table className="table table-bordered">
                        <thead>
                          <tr>
                            <th>#</th>
                            <th>Site Location</th>
                            <th>Created At</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {siteLocation.map((site, index) => (
                            <tr key={index}>
                              <td>{site.siteLocation}</td>
                              
                              <td>
                                <Moment format="YYYY-MM-DD hh:mm:ss a">
                                  {site.createdAt}
                                </Moment>
                              </td>
                              <td className="text-nowrap">
                              
                                <a
                                  onClick={() => this.delete(site._id)}
                                  data-toggle="tooltip"
                                  data-original-title="Delete"
                                >
                                  {" "}
                                  <i className="fas fa-trash text-danger"></i>{" "}
                                </a>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <FooterAdmin />
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SiteLocation);
