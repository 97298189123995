export const programType = {
  "youth-programs": "Youth Programs",
  "employment-services": "Employment Services",
  "Youth-Programs": "Youth Programs",
  "Employment-Services": "Employment Services",
  "newcomer-services": "Newcomer Services",
  "energy-assistance": "Energy Assistance",
  lbs: "LBS",
  ged: "GED",
  "pre-apprenticeship": "Pre-Apprenticeship",
  "employment-workshops": "Employment Workshops",
  "computer-workshops": "Computer Workshops",
  "food-assistance": "Food Assistance",
  "cooking-classes": "Cooking Classes",
  "ri-workshops": "Ri Workshops",
  "second-career": "Second Career",
  "resource-information-centre": "Resource and Information Centre",
  "literacy-basic-skills": "Literacy and Basic Skills",
  workshops: "Workshops",
  other: "Other",
};
