import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import Preloader from "../../components/Preloader";
import HeaderAdmin from "../../components/HeaderAdmin";
import Sidebaar from "../../components/user/Sidebaar";
import FooterAdmin from "../../components/FooterAdmin";
import { setUserData } from "../../redux-store/action";
import { userService } from "../../_services/admin";
import { authenticationService } from "../../_services";
import { appointmentService } from "../../_services/staff/appointment.service";
import { MDBDataTable } from "mdbreact";
import moment from "moment";
import MaskedInput from "react-text-mask";
import $ from "jquery";
import { Form, Formik, Field, ErrorMessage, FieldArray } from "formik";
import * as Yup from "yup";
import DatePicker from "react-date-picker";
import { capitalizeAndJoin } from "../../_helpers/_helperFunctions";
import {
  Button,
  FormGroup,
  Label,
  Input,
  FormFeedback,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import { programType } from "../../_helpers";
import Swal from "sweetalert";

function mapStateToProps(state) {
  return {
    appointmentChangeId: state.appointmentChangeId,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setUserData: (name) => {
      dispatch(setUserData(name));
    },
  };
}
export class UserAppointment extends Component {
  constructor(props) {
    super(props);
    const AM = "AM";
    const PM = "PM";
    this.state = {
      currentUser: authenticationService.currentUserValue,
      userFromApi: null,
      loading: true,
      appointments: [],
      counsellors: [],
      modal: false,
      rejectModal: false,
      counsellorId: "",
      bookingid: "",
      userid: "",
      appointmentDetails: {},
      modalLoding: false,
      appointmentsHistory: {},
      detailsModal: false,
      timeOptions: [
        //AM times
        { name: " ", value: "" },
        // {name:'12:00 '+AM,value:'00:00'},
        // {name:'12:30 '+AM,value:'00:30'},
        // {name:'01:00 '+AM,value:'01:00'},
        // {name:'01:30 '+AM,value:'01:30'},
        // {name:'02:00 '+AM,value:'02:00'},
        // {name:'02:30 '+AM,value:'02:30'},
        // {name:'03:00 '+AM,value:'03:00'},
        // {name:'03:30 '+AM,value:'03:30'},
        // {name:'04:00 '+AM,value:'04:00'},
        // {name:'04:30 '+AM,value:'04:30'},
        // {name:'05:00 '+AM,value:'05:00'},
        // {name:'05:30 '+AM,value:'05:30'},
        // {name:'06:00 '+AM,value:'06:00'},
        // {name:'06:30 '+AM,value:'06:30'},
        // {name:'07:00 '+AM,value:'07:00'},
        // {name:'07:30 '+AM,value:'07:30'},
        // {name:'08:00 '+AM,value:'08:00'},
        // {name:'08:30 '+AM,value:'08:30'},
        { name: "09:00 " + AM, value: "09:00" },
        // {name:'09:30 '+AM,value:'09:30'},
        { name: "10:00 " + AM, value: "10:00" },
        // {name:'10:30 '+AM,value:'10:30'},
        { name: "11:00 " + AM, value: "11:00" },
        // {name:'11:30 '+AM,value:'11:30'},
        //PM times
        { name: "12:00 " + PM, value: "12:00" },
        // {name:'12:30 '+PM,value:'12:30'},
        { name: "01:00 " + PM, value: "13:00" },
        // {name:'01:30 '+PM,value:'13:30'},
        { name: "02:00 " + PM, value: "14:00" },
        // {name:'02:30 '+PM,value:'14:30'},
        { name: "03:00 " + PM, value: "15:00" },
        // {name:'03:30 '+PM,value:'15:30'},
        { name: "04:00 " + PM, value: "16:00" },
        // {name:'04:30 '+PM,value:'16:30'},
        // {name:'05:00 '+PM,value:'17:00'},
        // {name:'05:30 '+PM,value:'17:30'},
        // {name:'06:00 '+PM,value:'18:00'},
        // {name:'06:30 '+PM,value:'18:30'},
        // {name:'07:00 '+PM,value:'19:00'},
        // {name:'07:30 '+PM,value:'19:30'},
        // {name:'08:00 '+PM,value:'20:00'},
        // {name:'08:30 '+PM,value:'20:30'},
        // {name:'09:00 '+PM,value:'21:00'},
        // {name:'09:30 '+PM,value:'21:30'},
        // {name:'10:00 '+PM,value:'22:00'},
        // {name:'10:30 '+PM,value:'22:30'},
        // {name:'11:00 '+PM,value:'23:00'},
        // {name:'11:30 '+PM,value:'23:30'},
      ],
      staffOptions: [],
      selectedStartTime: "",
      selectedEndTime: "",
      date: "",
      calenderDate: "",
      username: "",
      phoneNumber: "",
      detail: "",
      isFormSubmitting: false,
      counsellorAvailable: false,
      counsellorUnavailable: false,
      previousCounsellor: [],
    };
  }

  componentDidMount() {
    const { currentUser } = this.state;
    userService.getUserById(currentUser.id).then((data) => {
      data.Data && this.props.setUserData(data.Data);
    });
    this.getAllUsers();
    this.getAllBookingHistory();
  }

  componentDidUpdate(prevProps) {
    let prevAppointment = prevProps.appointmentChangeId;
    let newAppointment = this.props.appointmentChangeId;
    if (prevAppointment !== newAppointment) {
      //alert("Update state now!!");
      this.getAllUsers();
    }
  }

  showModal = () => {
    //create new button clicked
    this.setState({
      end: "",
      start: "",
      room: "",
      staff_contact: "",
      detail: "",
      reccurance: false,
      reccuranceEnd: "",
      frequency: "",
    });
    $(document).ready(function () {
      window.$("#basicExampleModal").modal("show");
    });
  };

  toggle = () => {
    this.setState({
      modal: !this.state.modal,
    });
  };

  toggleReject = () => {
    this.setState({
      rejectModal: !this.state.rejectModal,
    });
  };

  approve = (user) => {
    console.log(user);
    this.toggle();
    this.setState({
      modalLoding: true,
    });
    let data = {
      date: user.date,
      timeSlot: user.timeSlot[0],
      userId: user.user,
    };
    appointmentService.getAvailableCounsellors(data).then((data) => {
      if (data.Data.availableCounsellors[0]) {
        this.setState({
          bookingid: user._id,
          userid: user.user,
          counsellors: data.Data.availableCounsellors,
          counsellorId: data.Data.availableCounsellors[0]["_id"],
          modalLoding: false,
        });
      } else {
        this.setState({
          counsellorId: "",
          modalLoding: false,
        });
      }
    });
  };

  reject = (bookingid) => {
    this.setState({
      bookingid: bookingid,
    });
    this.toggleReject();
  };

  getCounsellorName = (event) => {
    this.setState({
      counsellorId: event.target.value,
    });
  };

  approveAppointment = () => {
    let data = {
      bookingId: this.state.bookingid,
      counsellorId: this.state.counsellorId,
      currentUserId: this.state.userid,
    };
    console.log(data);
    appointmentService.approveAppointment(data).then((data) => {
      console.log(data.Data);
      this.toggle();
      this.getAllUsers();
      this.setState({
        bookingid: "",
        userid: "",
        counsellors: [],
        counsellorId: "",
      });
    });
  };

  handleInput = () => {
    if (document.getElementById("reason").value) {
      document.getElementById("fieldRequired").innerHTML = "";
    } else {
      document.getElementById("fieldRequired").style.color = "red";
      document.getElementById("fieldRequired").style.fontSize = "small";
      document.getElementById("fieldRequired").innerHTML = "Reason is required";
    }
  };

  rejectAppointment = () => {
    let data = {
      bookingId: this.state.bookingid,
      currentUserId: this.state.currentUser.id,
    };

    appointmentService.rejectAppointment(data).then((data) => {
      this.toggleReject();
      this.getAllUsers();
      this.getAllBookingHistory();
      this.setState({
        bookingid: "",
      });
    });
  };

  getAllUsers = () => {
    appointmentService.getAllBookings().then((data) => {
      let rowsArray = [];
      data &&
        data.Data &&
        data.Data.map((user, index) => {
          rowsArray.push({
            key: index + 1,
            username: user.name,
            preferredDay: user.preferredDay
              ? capitalizeAndJoin(user.preferredDay)
              : "-",
            // time: moment(user.time?.startTime, "HH:mm").format("hh:mm A") + " - " + moment(user.time?.endTime, "HH:mm").format("hh:mm A"),
            preferredTime: user.preferredTime
              ? capitalizeAndJoin(user.preferredTime)
              : "-",
            reason: user.reason
              .map((item) => {
                return programType[item];
              })
              .join(", "),
            phoneNumber: user.phoneNumber,
            email: user.email,
            contactStatus: (
              <>
                <select
                  key={user._id}
                  value={user.contactStatus}
                  onChange={(e) => this.setContactStatus(e, user._id)}
                >
                  <option value=""></option>
                  <option value="left-message">Left message</option>
                  <option value="contacted">Contacted</option>
                  <option value="lost-contact">Lost Contact</option>
                </select>
              </>
            ),
            action: (
              <React.Fragment>
                <button
                  onClick={() => this.getDetails(user)}
                  className="btn btn-sm btn-info"
                >
                  Preference
                </button>{" "}
                <button
                  onClick={() => this.reject(user._id)}
                  className="btn btn-sm btn-danger"
                >
                  Cancel request
                </button>
              </React.Fragment>
            ),
          });
        });

      let tableData = {
        columns: [
          // {
          //     label: "No.",
          //     field: "key",
          //     sort: "asc",
          //     width: 270,
          // },
          {
            label: "User Name",
            field: "username",
            sort: "asc",
            width: 150,
          },

          // {
          //   label: "Preferred Day",
          //   field: "preferredDay",
          //   sort: "disabled",
          //   width: 270,
          // },

          // {
          //   label: "Preferred Time",
          //   field: "preferredTime",
          //   sort: "disabled",
          //   width: 270,
          // },

          {
            label: "Reason",
            field: "reason",
            sort: "disabled",
            width: 270,
          },

          {
            label: "Contact",
            field: "phoneNumber",
            sort: "disabled",
            width: 150,
          },

          {
            label: "Email",
            field: "email",
            sort: "disabled",
            width: 270,
          },
          {
            label: "Contact Status",
            field: "contactStatus",
            sort: "disabled",
            width: 270,
          },
          {
            label: "Action",
            field: "action",
            sort: "disabled",
            width: 270,
          },
        ],
        rows: rowsArray,
      };
      this.setState({ appointments: tableData, loading: false });
    });
  };

  getDetails = (user) => {
    this.setState({
      appointmentDetails: user || {},
      detailsModal: !this.state.detailsModal,
    });
    //this.toggle();
  };

  getAllBookingHistory = () => {
    appointmentService.getAllBookingsHistory().then((data) => {
      let rowsArray = [];
      data &&
        data.Data &&
        data.Data.map((user, index) => {
          rowsArray.push({
            key: index + 1,
            username: user.name,
            preferredDay: user.preferredDay
              ? capitalizeAndJoin(user.preferredDay)
              : "-",
            // time: moment(user.time?.startTime, "HH:mm").format("hh:mm A") + " - " + moment(user.time?.endTime, "HH:mm").format("hh:mm A"),
            preferredTime: user.preferredTime
              ? capitalizeAndJoin(user.preferredTime)
              : "-",
            reason: user.reason
              .map((item) => {
                return programType[item];
              })
              .join(", "),
            phoneNumber: user.phoneNumber,
            email: user.email,
            bookingstatus: (
              <React.Fragment>
                {user.status == 1 && (
                  <div className="btn-success status px-1">Pending</div>
                )}
                {user.status == 2 && (
                  <div className="btn-primary status px-1">Approved</div>
                )}
                {user.status == 3 && (
                  <div className="btn-danger status px-1">Rejected</div>
                )}
                {user.status == 4 && (
                  <div
                    style={{
                      backgroundColor: "lightcoral",
                    }}
                    className="btn-danger status px-1"
                  >
                    Cancelled
                  </div>
                )}
                {user.status == 5 && (
                  <div className="btn-danger status px-1">Lost Contact</div>
                )}
              </React.Fragment>
            ),
            action: (
              <React.Fragment>
                <button
                  onClick={() => this.getDetails(user)}
                  className="btn btn-sm btn-info"
                >
                  Preference
                </button>
              </React.Fragment>
            ),
          });
        });

      let tableData = {
        columns: [
          // {
          //     label: "No.",
          //     field: "key",
          //     sort: "asc",
          //     width: 270,
          // },
          {
            label: "User Name",
            field: "username",
            sort: "asc",
            width: 150,
          },

          // {
          //   label: "Preferred Day",
          //   field: "preferredDay",
          //   sort: "disabled",
          //   width: 270,
          // },

          // {
          //   label: "Preferred Time",
          //   field: "preferredTime",
          //   sort: "disabled",
          //   width: 270,
          // },

          {
            label: "Reason",
            field: "reason",
            sort: "disabled",
            width: 270,
          },

          {
            label: "Contact",
            field: "phoneNumber",
            sort: "disabled",
            width: 150,
          },

          {
            label: "Email",
            field: "email",
            sort: "disabled",
            width: 270,
          },
          {
            label: "Status",
            field: "bookingstatus",
            sort: "disabled",
            width: 270,
          },
          {
            label: "Action",
            field: "action",
            sort: "disabled",
            width: 270,
          },
        ],
        rows: rowsArray,
      };
      this.setState({ appointmentsHistory: tableData, loading: false });
    });
  };

  clearForm = () => {
    this.setState({
      isFormSubmitting: false,
      selectedStartTime: [],
      selectedEndTime: "",
      date: "",
      detail: "",
      calenderDate: "",
      username: "",
      phoneNumber: "",
      counsellors: [],
    });
  };

  submitForm = () => {
    let requestData = {
      date: moment(this.state.calenderDate).format("MM/DD/yyyy"),
      day: moment(this.state.calenderDate).format("dddd"),
      time: {
        startTime: this.state.selectedStartTime,
        endTime: this.state.selectedEndTime,
      },
      name: document.getElementById("username").value,
      reason: document.getElementById("detail").value,
      phoneNumber: document.getElementById("phoneNumber").value,
      counsellorId: this.state.counsellorId,
    };
    // console.log(requestData)
    appointmentService.bookAppointment(requestData).then((data) => {
      this.clearForm();
      document.getElementById("close-modal").click();
      this.getAllUsers();
    });
  };

  setContactStatus = (e, bookingId) => {
    if (e.target.value) {
      let value = e.target.value;
      console.log("target: ", value);
      Swal({
        title: "Are you sure!",
        text: "You want to change the status?",
        buttons: ["No", "Yes"],
      }).then((result) => {
        if (result) {
          let requestData = {
            bookingId,
            contactStatus: value,
          };

          appointmentService.setContactStatus(requestData).then((data) => {
            if (data && data.Status) {
              this.getAllUsers();
              this.getAllBookingHistory();
              Swal("Status Changed!", "", "success");
            }
          });
        }
      });
    }
  };

  render() {
    return (
      <div id="main-wrapper" className="fix-header fix-sidebar card-no-border">
        {this.state.loading && <Preloader />}
        <HeaderAdmin />
        <Sidebaar />
        <div className="page-wrapper">
          <div className="container-fluid">
            <div className="row page-titles">
              <div className="col-md-5 col-8 align-self-center">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <a href="#">Home</a>
                  </li>
                  <li className="breadcrumb-item active">User Appointment</li>
                </ol>
              </div>
            </div>

            <div className="row">
              <div className="col-md-12">
                <div className="pt-4">
                  <div className="card">
                    <div className="card-body">
                      <div className="d-flex justify-content-between">
                        <div className="align-self-center tbl-head">
                          <i className="mdi mdi-calendar-check"></i>{" "}
                          <span>User Appointment</span>
                        </div>
                      </div>

                      <Formik
                        enableReinitialize={true}
                        initialValues={{
                          startDate: this.state.start || "",
                          startTimeApointment:
                            this.state.selectedStartTime || [],
                          // endDate: this.state.end || '',
                          endTimeApointment: "",
                          detail: this.state.detail || "",
                          username: this.state.username || "",
                          phoneNumber: this.state.phoneNumber || "",
                        }}
                        validationSchema={Yup.object().shape({
                          username: Yup.string().required(
                            "username is required"
                          ),
                          phoneNumber: Yup.string().required(
                            "Phone Number is required"
                          ),
                          detail: Yup.string().required("Detail is required"),
                          // startDate: Yup.string().required('Start date is required'),
                          startTimeApointment: Yup.string().required(
                            "Start Time is required"
                          ),
                          // endDate: Yup.string().required('End date is required'),
                          endTimeApointment: Yup.string().required(
                            "End time is required"
                          ),
                        })}
                        onSubmit={(formData, { setSubmitting }) => {}}
                        render={({
                          formData,
                          errors,
                          status,
                          touched,
                          isSubmitting,
                          values,
                          setFieldValue,
                          handleChange,
                        }) => (
                          <Form>
                            {this.state.isFormSubmitting && <Preloader />}
                            <div
                              className="modal fade"
                              className="modal fade"
                              id="basicExampleModal"
                              tabIndex="-1"
                              role="dialog"
                              aria-labelledby="exampleModalLabel"
                              aria-hidden="true"
                            >
                              <div
                                className="modal-dialog lg modal-lg"
                                role="document"
                              >
                                <div className="modal-content">
                                  <div className="modal-header">
                                    <h5
                                      className="modal-title"
                                      id="exampleModalLabel"
                                    >
                                      {this.state.editMode
                                        ? "Edit Apointment"
                                        : "Appointment Request"}
                                    </h5>
                                    <button
                                      type="button"
                                      className="close"
                                      data-dismiss="modal"
                                      aria-label="Close"
                                    >
                                      <span aria-hidden="true">&times;</span>
                                    </button>
                                  </div>
                                  <div className="modal-body form-material">
                                    {/* <div className="titlestrip_appointment">
                                                                                    <h2 className="titlestrip_appointment">UHC Counsellor</h2>
                                                                                </div> */}
                                    <div className="form-group required">
                                      <label className="control-label">
                                        Name
                                      </label>
                                      <Field
                                        id="username"
                                        name="username"
                                        type="text"
                                        autoComplete="off"
                                        placeholder="Add username"
                                        className={
                                          "form-control" +
                                          (errors.username && touched.username
                                            ? " is-invalid"
                                            : "")
                                        }
                                      />
                                      <ErrorMessage
                                        name="username"
                                        component="div"
                                        className="invalid-feedback"
                                      />
                                    </div>

                                    <div className="form-group required">
                                      <label className="control-label">
                                        Phone Number
                                      </label>
                                      {/* <Field id="phonenumber" name="phonenumber" type="text" autoComplete="off" placeholder="Add Phone Number" className={'form-control' + (errors.phonenumber && touched.phonenumber ? ' is-invalid' : '')} /> */}

                                      <MaskedInput
                                        mask={[
                                          "(",
                                          /[1-9]/,
                                          /\d/,
                                          /\d/,
                                          ")",
                                          " ",
                                          /\d/,
                                          /\d/,
                                          /\d/,
                                          "-",
                                          /\d/,
                                          /\d/,
                                          /\d/,
                                          /\d/,
                                        ]}
                                        name="phoneNumber"
                                        id="phoneNumber"
                                        onChange={handleChange}
                                        className={
                                          "form-control" +
                                          (errors.phoneNumber &&
                                          touched.phoneNumber
                                            ? " is-invalid"
                                            : "")
                                        }
                                        placeholder="Phone Number"
                                        guide={false}
                                        type="text"
                                        value={values.phoneNumber}
                                        render={(ref, props) => (
                                          <input
                                            type="text"
                                            label="Phone Number"
                                            name="phoneNumber"
                                            ref={ref}
                                            value={values.phoneNumber}
                                            {...props}
                                          />
                                        )}
                                      />

                                      <ErrorMessage
                                        name="phoneNumber"
                                        component="div"
                                        className="invalid-feedback"
                                      />
                                    </div>

                                    <div className="form-group required">
                                      <label className="control-label">
                                        Reason
                                      </label>
                                      <Field
                                        id="detail"
                                        name="detail"
                                        type="text"
                                        autoComplete="off"
                                        placeholder="Add details of the appointment"
                                        className={
                                          "form-control" +
                                          (errors.detail && touched.detail
                                            ? " is-invalid"
                                            : "")
                                        }
                                      />
                                      <ErrorMessage
                                        name="detail"
                                        component="div"
                                        className="invalid-feedback"
                                      />
                                    </div>

                                    <div className="row m-0 p-0 form-material">
                                      <div className="col-5 pl-0">
                                        <div className="form-group required">
                                          <label className="control-label">
                                            Date
                                          </label>
                                          <Field
                                            name="startDate"
                                            className={
                                              "form-control cat" +
                                              (errors.startDate &&
                                              touched.startDate
                                                ? " is-invalid"
                                                : "")
                                            }
                                            render={({ field }) => (
                                              <DatePicker
                                                {...field}
                                                value={this.state.calenderDate}
                                                className={
                                                  "form-control cat" +
                                                  (errors.startDate &&
                                                  touched.startDate
                                                    ? " is-invalid"
                                                    : "")
                                                }
                                                minDate={new Date()}
                                                maxDate={moment()
                                                  .add(1, "months")
                                                  .toDate()}
                                                format="MM/dd/yyyy"
                                                onChange={(date) => {
                                                  date === null
                                                    ? setFieldValue(
                                                        "startDate",
                                                        ""
                                                      )
                                                    : setFieldValue(
                                                        "startDate",
                                                        date
                                                      );

                                                  this.setState({
                                                    username: document.getElementById(
                                                      "username"
                                                    ).value,
                                                    phoneNumber: document.getElementById(
                                                      "phoneNumber"
                                                    ).value,
                                                    detail: document.getElementById(
                                                      "detail"
                                                    ).value,
                                                    calenderDate: date,
                                                    date: moment(date).format(
                                                      "MM/DD/YYYY"
                                                    ),
                                                    selectedStartTime: [],
                                                    selectedEndTime: "",
                                                  });
                                                }}
                                              />
                                            )}
                                          />
                                          <ErrorMessage
                                            name="startDate"
                                            component="div"
                                            className="invalid-feedback"
                                          />
                                        </div>
                                      </div>
                                      <div className="col-3">
                                        <div className="form-group required">
                                          <label className="control-label">
                                            Start Time
                                          </label>
                                          <Field
                                            name="startTimeApointment"
                                            render={({ field }) => (
                                              <select
                                                {...field}
                                                className={
                                                  "form-control cat" +
                                                  (errors.startTimeApointment &&
                                                  touched.startTimeApointment
                                                    ? " is-invalid"
                                                    : "")
                                                }
                                                value={
                                                  this.state.selectedStartTime
                                                }
                                                disabled={!this.state.date}
                                                onChange={(event) => {
                                                  this.state.timeOptions.forEach(
                                                    (time, i) => {
                                                      if (
                                                        time.value ==
                                                        event.target.value
                                                      ) {
                                                        this.setState({
                                                          username: document.getElementById(
                                                            "username"
                                                          ).value,
                                                          phoneNumber: document.getElementById(
                                                            "phoneNumber"
                                                          ).value,
                                                          detail: document.getElementById(
                                                            "detail"
                                                          ).value,
                                                          selectedStartTime: this
                                                            .state.timeOptions[
                                                            i
                                                          ].value,
                                                          selectedEndTime: this
                                                            .state.timeOptions[
                                                            i + 1
                                                          ].value,
                                                        });
                                                        console.log(
                                                          this.state
                                                            .availabilities
                                                        );
                                                      }
                                                    }
                                                  );
                                                  let stopTime =
                                                    parseInt(
                                                      event.target.value
                                                    ) + 1;
                                                  let data = {
                                                    date: this.state.date,
                                                    timeSlot:
                                                      event.target.value +
                                                      " - " +
                                                      stopTime +
                                                      ":00",
                                                  };
                                                  this.setState({
                                                    counsellors: [],
                                                    counsellorId: "",
                                                    counsellorAvailable: false,
                                                    counsellorUnavailable: false,
                                                  });
                                                  appointmentService
                                                    .getAvailableCounsellors(
                                                      data
                                                    )
                                                    .then((data) => {
                                                      // console.log(data.Data)
                                                      if (
                                                        data.Data
                                                          .availableCounsellors[0]
                                                      ) {
                                                        this.setState({
                                                          counsellors:
                                                            data.Data
                                                              .availableCounsellors,
                                                          counsellorId:
                                                            data.Data
                                                              .availableCounsellors[0][
                                                              "_id"
                                                            ],
                                                          counsellorAvailable: true,
                                                        });
                                                      } else {
                                                        this.setState({
                                                          counsellors:
                                                            data.Data
                                                              .availableCounsellors,
                                                          counsellorId: "",
                                                          counsellorUnavailable: true,
                                                        });
                                                      }
                                                      // console.log(this.state.counsellors)
                                                    });
                                                }}
                                              >
                                                {this.state.timeOptions.map(
                                                  (opt, key) => (
                                                    <option
                                                      key={key}
                                                      value={opt.value}
                                                    >
                                                      {opt.name}
                                                    </option>
                                                  )
                                                )}
                                              </select>
                                            )}
                                            className={
                                              "form-control cat" +
                                              (errors.startTimeApointment &&
                                              touched.startTimeApointment
                                                ? " is-invalid"
                                                : "")
                                            }
                                          />
                                          <ErrorMessage
                                            name="startTimeApointment"
                                            component="div"
                                            className="invalid-feedback"
                                          />
                                        </div>
                                      </div>
                                      <div className="col-3">
                                        <div className="form-group required">
                                          <label className="control-label">
                                            End Time
                                          </label>
                                          <Field
                                            name="endTimeApointment"
                                            render={({ field }) => (
                                              <select
                                                {...field}
                                                className={"form-control"}
                                                disabled
                                                value={
                                                  this.state.selectedEndTime
                                                }
                                              >
                                                {this.state.selectedEndTime && (
                                                  <option>
                                                    {moment(
                                                      this.state
                                                        .selectedEndTime,
                                                      ["HH:mm"]
                                                    ).format("hh:mm A")}
                                                  </option>
                                                )}
                                              </select>
                                            )}
                                            className={
                                              "form-control cat" +
                                              (errors.endTimeApointment &&
                                              touched.endTimeApointment
                                                ? " is-invalid"
                                                : "")
                                            }
                                          />
                                          <ErrorMessage
                                            name="endTimeApointment"
                                            component="div"
                                            className="invalid-feedback"
                                          />
                                        </div>
                                      </div>
                                    </div>
                                    {this.state.calenderDate &&
                                      this.state.counsellorAvailable &&
                                      this.state.counsellors[0] && (
                                        <div className="form-group required">
                                          <label className="control-label">
                                            Select Counsellor
                                          </label>
                                          <Field
                                            name="staff_contact"
                                            render={({ field }) => (
                                              <select
                                                {...field}
                                                className={
                                                  "form-control" +
                                                  (errors.counsellor &&
                                                  touched.counsellor
                                                    ? " is-invalid"
                                                    : "")
                                                }
                                                onChange={(event) =>
                                                  this.getCounsellorName(event)
                                                }
                                              >
                                                {this.state.counsellors
                                                  .filter(
                                                    (v, i, a) =>
                                                      a.findIndex(
                                                        (t) => t._id === v._id
                                                      ) === i
                                                  )
                                                  .map((counsellor, index) => (
                                                    <option
                                                      value={counsellor._id}
                                                      key={index}
                                                    >
                                                      {counsellor.firstName +
                                                        " " +
                                                        counsellor.lastName}
                                                    </option>
                                                  ))}
                                              </select>
                                            )}
                                          />
                                          <ErrorMessage
                                            name="staff_contact"
                                            component="div"
                                            className="invalid-feedback"
                                          />
                                        </div>
                                      )}
                                    {this.state.calenderDate &&
                                      this.state.counsellorUnavailable &&
                                      this.state.selectedStartTime[0] &&
                                      !this.state.counsellors[0] && (
                                        <h3 className="no_counsellor">
                                          Counsellor not Available
                                        </h3>
                                      )}
                                  </div>
                                  <div className="modal-footer">
                                    <button
                                      id="close-modal"
                                      type="button"
                                      className="btn btn-secondary"
                                      data-dismiss="modal"
                                    >
                                      Close
                                    </button>
                                    {/* <button type="button" className="btn btn-primary" onClick={()=>this.saveApointment()}>Save </button> */}
                                    <div className="">
                                      <button
                                        type="submit"
                                        disabled={isSubmitting}
                                        onClick={() => this.submitForm()}
                                        className="btn btn-primary"
                                      >
                                        {" "}
                                        Place Apointment{" "}
                                      </button>
                                      {this.state.isFormSubmitting &&
                                        isSubmitting && (
                                          <img src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
                                        )}
                                    </div>
                                    {/* {status && <div className={'alert alert-danger'}>{status}</div>} */}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </Form>
                        )}
                      />

                      <hr />
                      <MDBDataTable
                        striped
                        bordered
                        hover
                        data={this.state.appointments}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <div className="pt-4">
                  <div className="card">
                    <div className="card-body">
                      <div className="d-flex justify-content-between">
                        <div className="align-self-center tbl-head">
                          <i className="mdi mdi-calendar-check"></i>{" "}
                          <span>Appointment History</span>
                        </div>
                      </div>
                      <hr />
                      {this.state.appointmentsHistory.rows ? (
                        <MDBDataTable
                          striped
                          bordered
                          hover
                          data={this.state.appointmentsHistory}
                        />
                      ) : (
                        <div className="text-center">
                          <h3>No history found</h3>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Modal isOpen={this.state.modal} toggle={this.toggle} centered="true">
            {this.state.modalLoding && <Preloader />}
            <ModalHeader toggle={this.toggle}>Approve Appointment</ModalHeader>
            <ModalBody>
              {this.state.counsellors.filter(
                (counsellor) => counsellor.previouslyConsulted
              )[0] && <h3>Lead Counsellor:</h3>}
              <div className="no_counsellor">
                {this.state.counsellors
                  .filter(
                    (v, i, a) => a.findIndex((t) => t._id === v._id) === i
                  )
                  .filter((counsellor) => counsellor.previouslyConsulted)
                  .map((filteredcounsellor, index) => (
                    <span style={{ marginRight: "11px" }}>
                      {index +
                        1 +
                        ") " +
                        filteredcounsellor.firstName +
                        " " +
                        filteredcounsellor.lastName}
                    </span>
                  ))}
              </div>
              {this.state.counsellorId == "" ? (
                <h3 className="text-center">No Counsellors Available</h3>
              ) : (
                <div className="form-group">
                  <Label for="exampleSelect">Select Counsellor</Label>
                  <select
                    className="form-control"
                    name="select"
                    onChange={(event) => this.getCounsellorName(event)}
                    id="exampleSelect"
                  >
                    {this.state.counsellors
                      .filter(
                        (v, i, a) => a.findIndex((t) => t._id === v._id) === i
                      )
                      .map((counsellor, index) =>
                        counsellor.previouslyConsulted ? (
                          <option
                            className="previous_counsellor"
                            value={counsellor._id}
                            key={index}
                          >
                            {counsellor.firstName + " " + counsellor.lastName}
                          </option>
                        ) : (
                          <option value={counsellor._id} key={index}>
                            {counsellor.firstName + " " + counsellor.lastName}
                          </option>
                        )
                      )}
                  </select>
                </div>
              )}
            </ModalBody>
            {this.state.counsellorId != "" && (
              <ModalFooter>
                <Button color="primary" onClick={this.approveAppointment}>
                  Approve
                </Button>{" "}
                <Button color="secondary" onClick={this.toggle}>
                  Cancel
                </Button>
              </ModalFooter>
            )}
          </Modal>

          <Modal
            isOpen={this.state.rejectModal}
            toggle={this.toggleReject}
            centered="true"
          >
            <ModalHeader toggle={this.toggleReject}>
              Reject Appointment
            </ModalHeader>
            <ModalBody>
              <div className="text-center">
                <h3>Are your sure?</h3>
                <p>You want to cancel the Request.</p>
              </div>
            </ModalBody>
            <ModalFooter>
              <Button color="danger" onClick={this.rejectAppointment}>
                Yes
              </Button>{" "}
              <Button color="secondary" onClick={this.toggleReject}>
                No
              </Button>
            </ModalFooter>
          </Modal>

          <Modal
            isOpen={this.state.detailsModal}
            centered="true"
            toggle={this.getDetails}
          >
            <ModalHeader toggle={this.getDetails}>
              Details of Appointment
            </ModalHeader>
            <ModalBody>
              {/* {this.state.appointmentDetails.status == 3 ? */}
              <div>
                <div className="full_width appointment-details-modal">
                  <div className="font-weight-bold">Preffered Day:</div>
                  <div>
                    {this.state.appointmentDetails.preferredDay
                      ? capitalizeAndJoin(
                          this.state.appointmentDetails.preferredDay
                        )
                      : "-"}
                  </div>
                </div>
                <div className="full_width appointment-details-modal">
                  <div className="font-weight-bold">Preffered Time:</div>
                  <div>
                    {this.state.appointmentDetails.preferredTime
                      ? capitalizeAndJoin(
                          this.state.appointmentDetails.preferredTime
                        )
                      : "-"}
                  </div>
                </div>

                {this.state.appointmentDetails.notes && (
                  <div className="full_width appointment-details-modal">
                    <div className="font-weight-bold">Note</div>
                    <div
                      id="notes"
                      dangerouslySetInnerHTML={{
                        __html: this.state.appointmentDetails.notes,
                      }}
                    ></div>
                  </div>
                )}

                {this.state.appointmentDetails.rejectReason && (
                  <div className="full_width appointment-details-modal">
                    <div className="font-weight-bold">Reason For Rejection</div>
                    <div>{this.state.appointmentDetails.rejectReason}</div>
                  </div>
                )}
              </div>
            </ModalBody>
          </Modal>

          <FooterAdmin />
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(UserAppointment);
