import React, { Component } from "react";
import { connect } from "react-redux";
import Preloader from "../../components/Preloader";
import HeaderAdmin from "../../components/HeaderAdmin";
import Sidebaar from "../../components/user/Sidebaar";
import FooterAdmin from "../../components/FooterAdmin";
import { Form, Formik, Field, ErrorMessage } from "formik";
import { setUserData } from "../../redux-store/action";
import { authenticationService } from "../../_services";
import { contentLibraryService } from "../../_services/staff";
import Select from "react-select";
import { Calendar } from "primereact/calendar";
import { eventService } from "../../_services/staff";
import * as Yup from "yup";
import moment from "moment";
import { Link } from "react-router-dom";
import swal from "sweetalert";
import axios from "axios";
import mime from "mime-types";
import config from "../../config";
import { Editor } from "primereact/editor";
import TimePicker from "rc-time-picker";
import "rc-time-picker/assets/index.css";
function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {
    setUserData: (data) => {
      dispatch(setUserData(data));
    },
  };
}

class AddEvent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentUser: authenticationService.currentUserValue,
      event: {},
      loading: true,
      datetime: null,
      staffMembers: [],
      name: "",
      eventname: "",
      description: "",
      location: "",
      accesslink: "",
      capacity: "",
      starttime: "",
      endtime: "",
      eventid: null,
      learn: "",
      imageLoading: false,
      bannerImage: "",
    };
  }

  componentDidMount() {
    this.props.setUserData(this.state.currentUser);
    this.props.match.params.id &&
      eventService.getEventById(this.props.match.params.id).then((data) => {
        //   this.setState({ event: data.Data, loading: false });
        this.setState({
          event: data.Data,
          eventid: data.Data._id,
          eventname: data.Data.name,
          description: data.Data.description,
          location: data.Data.location,
          accesslink: data.Data.accessLink,
          capacity: data.Data.participantsCapacity,
          date: new Date(data.Data.dateTime),
          starttime: moment(data.Data.startTime),
          endtime: moment(data.Data.endTime),
          bannerImage: data.Data.bannerImage,
          pdfLink: data.Data.pdfLink,
          loading: false,
        });
      });
    this.getStaff();
    this.setState({ loading: false });
  }

  handleChange = (selectedOption) => {
    this.setState({ name: selectedOption });
  };

  getStaff = () => {
    eventService.getAllStaffMembers().then((data) => {
      this.setState({
        staffMembers: data.Data,
      });
    });
  };

  handleBannerImageChange = (event) => {
    this.setState({
      imageLoading: true,
    });
    if (event.target.files[0]) {
      let file = event.target.files[0];
      // Split the filename to get the name and type
      let fileName = "events/" + event.target.files[0].name;
      let fileType = mime.lookup(event.target.files[0].name);
      console.log(fileName);
      console.log(fileType);
      console.log("Preparing the upload");

      axios
        .post(
          `${config.apiUrl}/api/v1/events/file-upload`,
          {
            fileName: fileName,
            fileType: fileType,
          },
          {
            headers: {
              Authorization: `Bearer ${this.state.currentUser.token}`,
              isenc: localStorage.getItem("isenc")
                ? parseInt(localStorage.getItem("isenc"))
                : 0,
            },
          }
        )
        .then((response) => {
          console.log(response.data);
          var returnData = response.data.Data;
          var signedRequest = returnData.signedRequest;
          var url = returnData.url;
          console.log("url: ", url);
          //this.setState({ url: url });
          console.log("Recieved a signed request " + signedRequest);

          // Put the fileType in the headers for the upload
          var options = {
            headers: {
              "Content-Type": fileType,
            },
          };
          axios
            .put(signedRequest, file, options)
            .then((result) => {
              console.log("Response from s3", result);
              this.setState({ bannerImage: url, imageLoading: false });
            })
            .catch((error) => {
              console.log(error);
            });
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      this.setState({
        url: "",
        imageLoading: false,
      });
    }
  };

  handlePdfFileChange = (event) => {
    if (event.target.files[0]) {
      let file = event.target.files[0];
      // Split the filename to get the name and type
      let fileName = "events/" + event.target.files[0].name;
      let fileType = mime.lookup(event.target.files[0].name);
      console.log(fileName);
      console.log(fileType);
      console.log("Preparing the upload");

      axios
        .post(
          `${config.apiUrl}/api/v1/events/file-upload`,
          {
            fileName: fileName,
            fileType: fileType,
          },
          {
            headers: {
              Authorization: `Bearer ${this.state.currentUser.token}`,
              isenc: localStorage.getItem("isenc")
                ? parseInt(localStorage.getItem("isenc"))
                : 0,
            },
          }
        )
        .then((response) => {
          console.log(response.data);
          var returnData = response.data.Data;
          var signedRequest = returnData.signedRequest;
          var url = returnData.url;
          console.log("url: ", url);
          //this.setState({ url: url });
          console.log("Recieved a signed request " + signedRequest);

          // Put the fileType in the headers for the upload
          var options = {
            headers: {
              "Content-Type": fileType,
            },
          };
          axios
            .put(signedRequest, file, options)
            .then((result) => {
              console.log("Response from s3", result);
              this.setState({ pdfLink: url });
            })
            .catch((error) => {
              console.log(error);
            });
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
    }
  };
  render() {
    return (
      <div id="main-wrapper" className="fix-header fix-sidebar card-no-border">
        {/* <Preloader /> */}
        <HeaderAdmin />
        <Sidebaar />
        <div className="page-wrapper">
          <div className="container-fluid">
            <div className="row page-titles">
              <div className="col-md-5 col-8 align-self-center">
                <h3 className="text-themecolor m-b-0 m-t-0">
                  {!this.state.event._id ? "Add Event" : "Edit Event"}
                </h3>
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <a href="/">Home</a>
                  </li>

                  <li className="breadcrumb-item active">Events</li>
                  <li className="breadcrumb-item active">Add Event</li>
                </ol>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6">
                <div className="pt-4">
                  <div className="card">
                    <Formik
                      enableReinitialize={true}
                      initialValues={{
                        eventname: this.state.eventname || "",
                        description: this.state.description || "",
                        capacity: this.state.capacity || "",
                        location: this.state.location || "",
                        accesslink: this.state.accesslink || "",
                        currentUserId: this.state.currentUser.id,
                        date: this.state.date || null,
                        starttime: this.state.starttime || null,
                        endtime: this.state.endtime || null,
                      }}
                      validationSchema={Yup.object().shape({
                        eventname: Yup.string().required(
                          "Event Name is required"
                        ),
                        description: Yup.string().required(
                          "Description is required"
                        ),
                        //location: Yup.string().required("Location is required"),
                        accesslink: Yup.string().required(
                          "Access Link is required"
                        ),
                        //capacity: Yup.string().required("Capacity is required"),
                        date: Yup.string().required("Date is required"),
                        starttime: Yup.string().required(
                          "Start Time is required"
                        ),
                        endtime: Yup.string().required("End Time is required"),
                      })}
                      onSubmit={(formData, { setStatus, setSubmitting }) => {
                        let startMomentObj = moment(
                          moment(formData.date).format("MM/DD/YYYY") +
                            " " +
                            moment(formData.starttime).format("HH:mm"),
                          "MM/DD/YYYY HH:mm"
                        );
                        let endMomentObj = moment(
                          moment(formData.date).format("MM/DD/YYYY") +
                            " " +
                            moment(formData.endtime).format("HH:mm"),
                          "MM/DD/YYYY HH:mm"
                        );
                        let startMilisecOfMoment = startMomentObj.valueOf();
                        let endMilisecOfMoment = endMomentObj.valueOf();

                        let data = {
                          name: formData.eventname,
                          description: formData.description,
                          location: formData.location,
                          accessLink: formData.accesslink,
                          participantsCapacity: formData.capacity,
                          dateTime: moment(
                            moment(formData.date).format("MM/DD/YYYY") +
                              " " +
                              moment(formData.starttime).format("HH:mm"),
                            "MM/DD/YYYY HH:mm"
                          ).valueOf(),
                          startTime: startMilisecOfMoment,
                          endTime: endMilisecOfMoment,
                          whatWillYouLearn: formData.learn,
                          recurrence: formData.recurrenceFrequency
                            ? true
                            : false,
                          recurrenceEndDate: formData.recurrenceFrequency
                            ? formData.recurrenceEndDate
                            : null,
                          recurrenceFrequency: formData.recurrenceFrequency,
                          currentUserId: formData.currentUserId,
                          eventId: this.state.eventid,
                          type: "event",
                          bannerImage: this.state.bannerImage,
                          pdfLink: this.state.pdfLink,
                        };
                        console.log("event form data: ", data);
                        // setStatus();

                        eventService.createUpdateEvent(data).then(
                          (data) => {
                            this.setState({
                              datetime: null,
                              staffMembers: [],
                              name: "",
                              eventname: "",
                              description: "",
                              location: "",
                              accesslink: "",
                              capacity: "",
                              starttime: "",
                              endtime: "",
                              eventid: null,
                              learn: "",
                            });
                            const { from } = this.props.location.state || {
                              from: { pathname: "/staff/events/list" },
                            };
                            this.props.history.push(from);
                            swal("Event Added Succesfully!", "", "success");
                            setSubmitting(false);
                          },
                          (error) => {
                            setSubmitting(false);
                            setStatus(error);
                          }
                        );
                      }}
                      render={({
                        errors,
                        status,
                        touched,
                        isSubmitting,
                        values,
                        setFieldValue,
                      }) => (
                        <Form>
                          <div className="card-body">
                            <div className="row form-material">
                              <div className="col-md-12">
                                <div className="form-group required">
                                  <label className="control-label">
                                    Event Name
                                  </label>
                                  <Field
                                    name="eventname"
                                    type="text"
                                    placeholder="Workshop/Event Name"
                                    className={
                                      "form-control" +
                                      (errors.eventname && touched.eventname
                                        ? " is-invalid"
                                        : "")
                                    }
                                  />
                                  <ErrorMessage
                                    name="eventname"
                                    component="div"
                                    className="invalid-feedback"
                                  />
                                </div>
                                <div className="form-group required">
                                  <label className="control-label">
                                    Description
                                  </label>
                                  <Editor
                                    style={{
                                      height: "120px",
                                    }}
                                    name="learn"
                                    value={values.description}
                                    onTextChange={(e) =>
                                      setFieldValue("description", e.htmlValue)
                                    }
                                  />
                                  <ErrorMessage
                                    name="description"
                                    component="div"
                                    className="invalid-feedback"
                                  />
                                </div>

                                <div className="form-group">
                                  <label className="control-label">
                                    Location Details
                                  </label>
                                  <Field
                                    name="location"
                                    type="text"
                                    rows={5}
                                    placeholder="Location Details"
                                    className={
                                      "form-control" +
                                      (errors.location && touched.location
                                        ? " is-invalid"
                                        : "")
                                    }
                                  />
                                  <ErrorMessage
                                    name="location"
                                    component="div"
                                    className="invalid-feedback"
                                  />
                                </div>

                                <div className="form-group required">
                                  <label className="control-label">
                                    Access Link
                                  </label>
                                  <Field
                                    name="accesslink"
                                    type="text"
                                    rows={5}
                                    placeholder="Access Link"
                                    className={
                                      "form-control" +
                                      (errors.accesslink && touched.accesslink
                                        ? " is-invalid"
                                        : "")
                                    }
                                  />
                                  <ErrorMessage
                                    name="accesslink"
                                    component="div"
                                    className="invalid-feedback"
                                  />
                                </div>

                                <div className="d-flex">
                                  <div className="form-group required">
                                    <label className="control-label">
                                      Select Date
                                    </label>
                                    <div
                                      style={{ marginTop: "8px" }}
                                      className={
                                        //   "form-control" +
                                        errors.datetime && touched.datetime
                                          ? " is-invalid"
                                          : ""
                                      }
                                    >
                                      <Calendar
                                        required={true}
                                        showIcon={true}
                                        hourFormat="12"
                                        value={values.date}
                                        minDate={new Date()}
                                        // onChange={(e) => this.setState({ datetime: e.value })}
                                        onChange={(e) =>
                                          setFieldValue("date", e.value)
                                        }
                                      ></Calendar>
                                    </div>
                                    <ErrorMessage
                                      name="date"
                                      component="div"
                                      className="invalid-feedback"
                                    />
                                  </div>
                                </div>

                                <div className="d-flex">
                                  <div className="form-group required">
                                    <label className="control-label">
                                      Start Time
                                    </label>
                                    <div
                                      style={{ marginTop: "8px" }}
                                      className={
                                        //   "form-control" +
                                        errors.starttime && touched.starttime
                                          ? " is-invalid"
                                          : ""
                                      }
                                    >
                                      <TimePicker
                                        showSecond={false}
                                        defaultValue={moment()}
                                        value={values.starttime}
                                        className=""
                                        onChange={(value) => {
                                          console.log(value);
                                          setFieldValue("starttime", value);
                                        }}
                                        format={"hh:mm A"}
                                        use12Hours
                                        disabledHours={() => [12]}
                                        hideDisabledOptions={true}
                                      />
                                    </div>
                                    <ErrorMessage
                                      name="starttime"
                                      component="div"
                                      className="invalid-feedback"
                                    />
                                  </div>

                                  <div className="form-group required">
                                    <label className="control-label">
                                      End Time
                                    </label>
                                    <div
                                      style={{ marginTop: "8px" }}
                                      className={
                                        //   "form-control" +
                                        errors.endtime && touched.endtime
                                          ? " is-invalid"
                                          : ""
                                      }
                                    >
                                      <TimePicker
                                        showSecond={false}
                                        defaultValue={moment()}
                                        value={values.endtime}
                                        className=""
                                        onChange={(value) => {
                                          console.log(value);
                                          setFieldValue("endtime", value);
                                        }}
                                        format={"hh:mm A"}
                                        use12Hours
                                      />
                                    </div>
                                    <ErrorMessage
                                      name="endtime"
                                      component="div"
                                      className="invalid-feedback"
                                    />
                                  </div>
                                </div>
                                <div className="form-group">
                                  <label className="control-label">
                                    Banner Image
                                  </label>
                                  <div className="banner-image-box my-2">
                                    {this.state.imageLoading ? (
                                      <h3>Loading Image</h3>
                                    ) : (
                                      <img
                                        src={
                                          this.state.bannerImage ||
                                          "https://via.placeholder.com/560x200.png?text=Banner_Image"
                                        }
                                        alt="Event Banner"
                                        className="img-fluid"
                                      />
                                    )}
                                  </div>

                                  <input
                                    name="bannerImage"
                                    // className={(errors.url && touched.url ? ' is-invalid' : '')}
                                    type="file"
                                    onChange={this.handleBannerImageChange}
                                  />
                                </div>

                                <div className="form-group">
                                  <label className="control-label">
                                    More Info - PDF
                                  </label>

                                  <input
                                    name="pdflink"
                                    type="text"
                                    value={
                                      this.state.pdfLink || "No PDF uploaded"
                                    }
                                    className="form-control"
                                    readOnly
                                  />
                                  <input
                                    name="pdfFile"
                                    className="mt-2"
                                    type="file"
                                    onChange={this.handlePdfFileChange}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="card-footer accordionfooter d-flex flex-row-reverse">
                            <button
                              type="submit"
                              disabled={isSubmitting}
                              className="btn btn-primary"
                            >
                              {/* {!this.state.department._id */}
                              {/* ?  */}
                              Save
                              {/* : "Update Category"}{" "} */}
                            </button>

                            <div style={{ marginRight: "5px" }}>
                              <Link
                                to="/staff/events/list"
                                className="btn btn-secondary"
                                style={{
                                  backgroundColor: "grey",
                                  color: "white",
                                }}
                              >
                                {/* <i className="mdi mdi-chevron-left"></i> */}
                                <span className="hide-menu">Cancel</span>
                              </Link>
                            </div>
                            {isSubmitting && (
                              <img src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
                            )}
                          </div>
                          {status && (
                            <div className={"alert alert-danger"}>{status}</div>
                          )}
                        </Form>
                      )}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <FooterAdmin />
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AddEvent);
