import React, { Component } from "react";
import { connect } from "react-redux";
import Preloader from "../../components/Preloader";
import HeaderAdmin from "../../components/HeaderAdmin";
import SidebarSuperAdmin from "../../components/admin/Sidebaar";
import FooterAdmin from "../../components/FooterAdmin";
import { Form, Formik, Field, ErrorMessage } from "formik";
import { setUserData } from "../../redux-store/action";
import { authenticationService } from "../../_services";
import { departmentService, siteLocationService } from "../../_services/admin";
import Select from "react-select";
import { Link } from "react-router-dom";

import * as Yup from "yup";
import SiteLocation from "./SiteLocation";

function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {
    setUserData: (data) => {
      dispatch(setUserData(data));
    },
  };
}

function validateEmail(value) {
  let error;
  if (!value) {
    error = "Required";
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)) {
    error = "Invalid email address";
  }
  return error;
}

class CreateReferal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      currentUser: authenticationService.currentUserValue,
      department: {},
      siteLocation: [],
      loading: true,
      staffOptions: [],
    };
  }
  componentDidMount() {
    //this.getAllDepartments();
    //this.getAllUsers()
    this.getAllSiteLocations();
    this.props.setUserData(this.state.currentUser);
    this.props.match.params.id &&
      departmentService
        .getDepartmentById(this.props.match.params.id)
        .then((data) => {
          this.setState({ department: data.Data, loading: false });
        });
    this.setState({ loading: false });
  }

  getAllDepartments() {
    departmentService
      .getAll()
      .then((data) => this.setState({ departmentList: data.Data }));
  }

  getAllSiteLocations() {
    siteLocationService
      .getAll()
      .then((data) => this.setState({ siteLocation: data.Data }));
  }

  render() {
    const departments = this.state.department;
    const { siteLocation } = this.state;

    const siteLocationList = siteLocation.map((site, index) => (
      <option key={index} value={site._id}>
        {site.name}
      </option>
      // siteLocationList.push({value:index,label: site.name})
    ));

    return (
      <div id="main-wrapper" className="fix-header fix-sidebar card-no-border">
        {this.state.loading && <Preloader />}
        <HeaderAdmin />
        <SidebarSuperAdmin />
        <div className="page-wrapper">
          <div className="container-fluid">
            <div className="row page-titles">
              <div className="col-md-5 col-8 align-self-center">
                <h3 className="text-themecolor m-b-0 m-t-0">
                  {!this.state.department._id
                    ? "Create Department"
                    : "Edit Department"}
                </h3>
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <a href="/">Home</a>
                  </li>
                  <li className="breadcrumb-item active">Create Department</li>
                </ol>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6">
                <div className="pt-4">
                  <div className="card">
                    <Formik
                      enableReinitialize={true}
                      initialValues={{
                        departmentId: this.state.department._id || null,
                        name: this.state.department.name || "",
                        description: this.state.department.description || "",
                        currentUserId: this.state.currentUser.id,
                        isActive: this.state.department.isActive || "",
                        siteLocation: this.state.department.siteLocation || "",
                        // staffsForReferal:this.state.department?this.state.department.staffsForReferal : []
                      }}
                      validationSchema={Yup.object().shape({
                        name: Yup.string().required(
                          "Department Name is required"
                        ),
                        description: Yup.string().required(
                          "Description is required"
                        ),
                        siteLocation: Yup.string().required(
                          "Site Location is required"
                        ),
                      })}
                      onSubmit={(formData, { setStatus, setSubmitting }) => {
                        setStatus();
                        departmentService.create(formData).then(
                          (user) => {
                            const { from } = this.props.location.state || {
                              from: { pathname: "/admin/departments" },
                            };
                            this.props.history.push(from);
                          },
                          (error) => {
                            setSubmitting(false);
                            setStatus(error);
                          }
                        );
                      }}
                      render={({
                        errors,
                        status,
                        touched,
                        isSubmitting,
                        values,
                        setFieldValue,
                      }) => (
                        <Form>
                          <div className="card-body">
                            <div className="row form-material">
                              <div className="col-md-12">
                                <div className="form-group required">
                                  <label className="control-label">
                                    Department Name
                                  </label>
                                  <Field
                                    name="name"
                                    type="text"
                                    placeholder="Department Name"
                                    className={
                                      "form-control" +
                                      (errors.name && touched.name
                                        ? " is-invalid"
                                        : "")
                                    }
                                  />
                                  <ErrorMessage
                                    name="name"
                                    component="div"
                                    className="invalid-feedback"
                                  />
                                </div>
                                <div className="form-group required">
                                  <label className="control-label">
                                    Description
                                  </label>
                                  <Field
                                    name="description"
                                    type="text"
                                    placeholder="Description"
                                    className={
                                      "form-control" +
                                      (errors.description && touched.description
                                        ? " is-invalid"
                                        : "")
                                    }
                                  />
                                  <ErrorMessage
                                    name="description"
                                    component="div"
                                    className="invalid-feedback"
                                  />
                                </div>

                                <div className="form-group required">
                                  <label className="control-label">
                                    Site Location
                                  </label>
                                  <Field
                                    name="siteLocation"
                                    as="select"
                                    multiple="multiple"
                                    className={
                                      "form-control" +
                                      (errors.siteLocation &&
                                      touched.siteLocation
                                        ? " is-invalid"
                                        : "")
                                    }
                                    placeholder="Select Site Location"
                                  >
                                    <option value="">
                                      Select Site Location
                                    </option>

                                    {siteLocationList}
                                  </Field>
                                  {/* <Select
                                    isMulti
                                    name="Site"
                                    placeholder="Choose Site Location"
                                    options={siteLocationList}
                                    // className="basic-multi-select"
                                    // classNamePrefix="select"
                                  /> */}
                                  <ErrorMessage
                                    name="siteLocation"
                                    component="div"
                                    className="invalid-feedback"
                                  />
                                </div>

                                {/* <div className="form-group">
                                                    <label htmlFor="" className="control-label">Staff For Refral</label>
                                                    <Field name="staffForReferal" type="text" autoComplete="off" 
                                                        placeholder="Staff" 
                                                        className={'forminput' + (errors.recipient && touched.staffsForReferal ? ' is-invalid' : '')}
                                                            render={({field})=> (
                                                                <Select
                                                                    isMulti
                                                                    isSearchable
                                                                    onChange={(data)=>{
                                                                        console.log( data && data.map(elem=>elem.value));
                                                                        setFieldValue('staffsForReferal',data ?data.map(elem=>elem.value):[]);
                                                                    }}
                                                                    className={' ' + (errors.staffsForReferal && touched.staffsForReferal ? ' is-invalid' : '')}
                                                                    options={
                                                                        
                                                                        this.state.staffOptions?.map(opt=>({
                                                                            label:opt.name,
                                                                            value:opt.value
                                                                        }))
                                                                    }
                                                                    value={this.state.staffOptions?.filter(elem=>(values?.staffsForReferal?.includes(elem.value)))?.map(opt=>({
                                                                        label:opt.name,
                                                                        value:opt.value
                                                                    }))}
                                                                    placeholder="Choose Staff" />
                                                            )}
                                                        />
                                                </div> */}
                              </div>
                            </div>
                          </div>
                          <div className="card-footer accordionfooter d-flex">
                            <div style={{ marginRight: "5px" }}>
                              <Link
                                to="/admin/departments"
                                className="btn btn-secondary"
                                style={{
                                  backgroundColor: "grey",
                                  color: "white",
                                }}
                              >
                                {/* <i className="mdi mdi-chevron-left"></i> */}
                                <span className="">Cancel</span>
                              </Link>
                            </div>
                            <button
                              type="submit"
                              disabled={isSubmitting}
                              className="btn btn-primary"
                            >
                              {" "}
                              {!this.state.department._id
                                ? "Save Department"
                                : "Update Department"}{" "}
                            </button>

                            {isSubmitting && (
                              <img src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
                            )}
                          </div>
                          {status && (
                            <div className={"alert alert-danger"}>{status}</div>
                          )}
                        </Form>
                      )}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <FooterAdmin />
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateReferal);
