import { BehaviorSubject } from "rxjs";

import config from "../config";
import { handleResponse, history } from "../_helpers";
import EncDec from "../e2e/e2e_functions/index";

const currentUserSubject = new BehaviorSubject(
  JSON.parse(sessionStorage.getItem("currentUser"))
);
const currentDepartmentSubject = new BehaviorSubject(
  sessionStorage.getItem("currentDepartment")
);
const currentDepartmentNameSubject = new BehaviorSubject(
  sessionStorage.getItem("currentDepartmentName")
);

export const authenticationService = {
  login,
  logout,
  currentUser: currentUserSubject.asObservable(),
  get currentUserValue() {
    return currentUserSubject.value;
  },
  currentDepartment: currentDepartmentSubject.asObservable(),
  get currentDepartmentValue() {
    return currentDepartmentSubject.value;
  },
  get currentDepartmentNameValue() {
    return currentDepartmentNameSubject.value;
  },
  changeDepartment,
  setDepartmentName,
  syncLogout,
  forgotPassword,
};

function changeDepartment(departmentId, departmentName) {
  sessionStorage.setItem("currentDepartment", departmentId);
  currentDepartmentSubject.next(departmentId);
  setDepartmentName(departmentName);
  history.push("/");
}
function setDepartmentName(departmentName) {
  currentDepartmentNameSubject.next(departmentName);
  sessionStorage.setItem("currentDepartmentName", departmentName);
}
function encData(data) {
  console.log("encData::", data);
  let isenc = localStorage.getItem("isenc")
    ? parseInt(localStorage.getItem("isenc"))
    : 0;
  if (isenc == 1) {
    return JSON.stringify({
      enc_string: EncDec.encryptResponse(JSON.stringify(data)),
    });
  } else {
    return JSON.stringify(data);
  }
}

function login(email, password, captcha) {
  const requestOptions = {
    method: "POST",
    //headers: { 'Content-Type': 'application/json' },
    headers: {
      "Content-Type": "application/json",
      isenc: localStorage.getItem("isenc") || 0,
    },

    //body: JSON.stringify({ email, password })
    body: encData({ email, password, captcha }),
  };

  return fetch(`${config.apiUrl}/api/v1/users/authenticate`, requestOptions)
    .then(handleResponse)
    .then((user) => {
      // store user details and jwt token in local storage to keep user logged in between page refreshes
      if(user && user.Data) {
        sessionStorage.setItem("currentUser", JSON.stringify(user.Data));
        currentUserSubject.next(user.Data);
        //store department selection default1
        sessionStorage.setItem("currentDepartment", user.Data?.departments[0]);
        currentDepartmentSubject.next(user.Data?.departments[0]);
      }
      return user.Data;
    });
}

function forgotPassword(data) {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": `application/json`,
      isenc: localStorage.getItem("isenc")
        ? parseInt(localStorage.getItem("isenc"))
        : 0,
    },
    body: encData(data),
  };
  return fetch(
    `${config.apiUrl}/api/v1/users/forgotpassword`,
    requestOptions
  ).then(handleResponse);
}

function logout() {
  // remove user from local storage to log user out
  sessionStorage.removeItem("currentUser");
  sessionStorage.removeItem("currentDepartment");
  sessionStorage.removeItem("currentDepartmentName");
  currentUserSubject.next(null);
  currentDepartmentSubject.next(null);
  currentDepartmentNameSubject.next(null);
}

function syncLogout() {
  currentUserSubject.next(null);
  currentDepartmentSubject.next(null);
  currentDepartmentNameSubject.next(null);
}
