import React, { Component } from "react";
import { appointmentService } from "../../_services/staff/appointment.service";
import moment from "moment";
import { programType } from "../../_helpers";
import { MDBDataTable } from "mdbreact";
import { authenticationService } from "../../_services";
export default class CounsellorsDashboard extends Component {
  state = {
    currentUser: authenticationService.currentUserValue,
    currentDepartmentName: authenticationService.currentDepartmentNameValue,
    type: "today",
    bookings: {},
    todaysCount: 0,
    upcomingCount: 0,
    completedCount: 0,
  };
  componentDidMount() {
    this.getCounsellorPersonalStats();
    this.getBookingByCounsellorId();
  }
  getCounsellorPersonalStats() {
    appointmentService
      .getCounsellorPersonalStats(
        this.state.currentUser.id,
        new Date().getTime()
      )
      .then((data) => {
        if (data && data.Status) {
          this.setState({
            todaysCount: data.Data.todaysAppointment,
            upcomingCount: data.Data.upcomingAppointment,
            completedCount: data.Data.completedAppointment,
          });
        }
      });
  }

  toggleBookingType = (type) => {
    this.setState({
      type: type,
    });
    this.getBookingByCounsellorId(type);
  };

  getBookingByCounsellorId(type) {
    appointmentService
      .getAllBookingsbyCounsellorId(
        this.state.currentUser.id,
        new Date().getTime(),
        type || this.state.type
      )
      .then((data) => {
        if (data && data.Status) {
          let rowsArray = [];
          console.log("yayayayay", data.Data);
          data &&
            data.Data &&
            data.Data.forEach((user, index) => {
              rowsArray.push({
                key: index + 1,
                username: user.name,
                date: moment(user.date).format("dddd, MMM Do YYYY"),
                time: user.time,
                reason: user.reason
                  .map((item) => {
                    return programType[item];
                  })
                  .join(", "),
              });
            });

          let tableData = {
            columns: [
              // {
              //     label: "No.",
              //     field: "key",
              //     sort: "asc",
              //     width: 270,
              // },
              {
                label: "User Name",
                field: "username",
                sort: "asc",
                width: 150,
              },

              {
                label: "Date",
                field: "date",
                sort: "asc",
                width: 270,
              },

              {
                label: "Time",
                field: "time",
                sort: "asc",
                width: 270,
              },

              {
                label: "Reason",
                field: "reason",
                sort: "asc",
                width: 150,
              },
            ],
            rows: rowsArray,
          };
          this.setState({
            bookings: tableData,
          });
        }
      });
  }
  componentWillUnmount() {}
  render() {
    return (
      <>
        <div className="row pt-4">
          <div className="col-sm-4">
            <div
              className="card cursor-pointer"
              onClick={(e) => {
                this.toggleBookingType("today");
              }}
            >
              <div className="card-body d-flex">
                <div className="round round-lg align-self-center round-info">
                  <i className="mdi mdi-book-open"></i>
                </div>
                <div className="m-l-10 align-self-center dashboard_cardTitle">
                  <h4 className="card-title">Today's Appointment</h4>
                  <p className="card-text dashboard_cardtext">
                    {this.state.todaysCount}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-sm-4">
            <div
              className="card cursor-pointer"
              onClick={(e) => {
                this.toggleBookingType("upcoming");
              }}
            >
              <div className="card-body d-flex">
                <div className="round round-lg align-self-center round-warning">
                  <i className="mdi mdi-cellphone-link"></i>
                </div>
                <div className="m-l-10 align-self-center dashboard_cardTitle">
                  <h4 className="card-title">Upcoming Appointments</h4>
                  <p className="card-text dashboard_cardtext">
                    {this.state.upcomingCount}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-sm-4">
            <div
              className="card cursor-pointer"
              onClick={(e) => {
                this.toggleBookingType("completed");
              }}
            >
              <div className="card-body d-flex">
                <div className="round round-lg align-self-center round-danger">
                  <i className="mdi mdi-bullseye"></i>
                </div>
                <div className="m-l-10 align-self-center dashboard_cardTitle">
                  <h4 className="card-title">Completed Appointments</h4>
                  <p className="card-text dashboard_cardtext">
                    {this.state.completedCount}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <div className="card">
              <div className="card-header">
                <h3 style={{ textAlign: "center" }}>Appointment Details</h3>
              </div>

              <div className="card-body">
                {this.state.bookings.rows && this.state.bookings.rows.length ? (
                  <MDBDataTable
                    responsive
                    striped
                    bordered
                    hover
                    data={this.state.bookings}
                  />
                ) : (
                  <div className="text-center">
                    <h3>No Appointment Available</h3>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
