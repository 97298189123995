import React, { Component } from 'react'
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import Preloader from "../../components/Preloader";
import HeaderAdmin from "../../components/HeaderAdmin";
import Sidebaar from "../../components/user/Sidebaar";
import FooterAdmin from "../../components/FooterAdmin";
import { setUserData } from "../../redux-store/action";
import { userService } from "../../_services/admin";
import { authenticationService } from "../../_services";
import { Form, Formik, Field, ErrorMessage, FieldArray } from "formik";
import { NotificationManager } from "react-notifications";
import { Calendar } from 'primereact/calendar';
import { appointmentService } from "../../_services/staff/appointment.service"
import moment from "moment";


function mapStateToProps(state) {
    return {};
}

function mapDispatchToProps(dispatch) {
    return {
        setUserData: (name) => {
            dispatch(setUserData(name));
        },
    };
}

export class Availability extends Component {

    constructor(props) {
        super(props)
        const AM = "AM";
        const PM = "PM";
        const userData = {};
        const socket = {};
        this.state = {
            currentUser: authenticationService.currentUserValue,
            loading: true,
            date: new Date(),
            formDataHook: {},
            availabilities: [],
            timeOptions: [
                //AM times
                // {name:'12:00 '+AM,value:'00:00'},
                // {name:'12:30 '+AM,value:'00:30'},
                // {name:'01:00 '+AM,value:'01:00'},
                // {name:'01:30 '+AM,value:'01:30'},
                // {name:'02:00 '+AM,value:'02:00'},
                // {name:'02:30 '+AM,value:'02:30'},
                // {name:'03:00 '+AM,value:'03:00'},
                // {name:'03:30 '+AM,value:'03:30'},
                // {name:'04:00 '+AM,value:'04:00'},
                // {name:'04:30 '+AM,value:'04:30'},
                // {name:'05:00 '+AM,value:'05:00'},
                // {name:'05:30 '+AM,value:'05:30'},
                // {name:'06:00 '+AM,value:'06:00'},
                // {name:'06:30 '+AM,value:'06:30'},
                // {name:'07:00 '+AM,value:'07:00'},
                // {name:'07:30 '+AM,value:'07:30'},
                // {name:'08:00 '+AM,value:'08:00'},
                // {name:'08:30 '+AM,value:'08:30'},
                {name:'09:00 '+AM,value:'09:00'},
                // {name:'09:30 '+AM,value:'09:30'},
                {name:'10:00 '+AM,value:'10:00'},
                // {name:'10:30 '+AM,value:'10:30'},
                {name:'11:00 '+AM,value:'11:00'},
                // {name:'11:30 '+AM,value:'11:30'},
                //PM times
                {name:'12:00 '+PM,value:'12:00'},
                // {name:'12:30 '+PM,value:'12:30'},
                {name:'01:00 '+PM,value:'13:00'},
                // {name:'01:30 '+PM,value:'13:30'},
                {name:'02:00 '+PM,value:'14:00'},
                // {name:'02:30 '+PM,value:'14:30'},
                {name:'03:00 '+PM,value:'15:00'},
                // {name:'03:30 '+PM,value:'15:30'},
                {name:'04:00 '+PM,value:'16:00'},
                // {name:'04:30 '+PM,value:'16:30'},
                // {name:'05:00 '+PM,value:'17:00'},
                // {name:'05:30 '+PM,value:'17:30'},
                // {name:'06:00 '+PM,value:'18:00'},
                // {name:'06:30 '+PM,value:'18:30'},
                // {name:'07:00 '+PM,value:'19:00'},
                // {name:'07:30 '+PM,value:'19:30'},
                // {name:'08:00 '+PM,value:'20:00'},
                // {name:'08:30 '+PM,value:'20:30'},
                // {name:'09:00 '+PM,value:'21:00'},
                // {name:'09:30 '+PM,value:'21:30'},
                // {name:'10:00 '+PM,value:'22:00'},
                // {name:'10:30 '+PM,value:'22:30'},
                // {name:'11:00 '+PM,value:'23:00'},
                // {name:'11:30 '+PM,value:'23:30'},
            ]
        }
    }

    componentDidMount() {
        const { currentUser } = this.state;
        userService.getUserById(currentUser.id).then((data) => {
            data.Data && this.props.setUserData(data.Data);
        });
        console.log(currentUser)
        this.setState({ loading: false });
        this.getAvailability()

    }

    startTimeFormatterForEvents = (time) => {
        if (time && time.getHours()) {
            let hour = time.getHours() < 10 ? "0" + time.getHours() : time.getHours();
            let minute =
                time.getMinutes() < 10 ? "0" + time.getMinutes() : time.getMinutes();
            return hour + ":" + minute;
        } else {
            return "00:00";
        }
    }

    getAvailability = () => {
        appointmentService.counsellorAvailabilityByDate( this.props.match.params.id, new Date().getTime() )
            .then(data => {
                this.setState({
                    availabilities: data.Data
                })
            })
    }

    onChange = (value) => {
        this.setState({
            availabilities: [...this.state.availabilities,
            {
                date: new Date(value[value.length - 1]).toLocaleDateString("en-US", { month: '2-digit', day: '2-digit', year: 'numeric' }),
                day: new Date(value[value.length - 1]).toLocaleDateString("en-US", { weekday: 'long' }),
                counsellorId: this.props.match.params.id,
                availability: [{ startTime: "09:00", endTime: "16:00" }]
            }]
        })
    }

    render() {
        return (
            <div id="main-wrapper" className="fix-header fix-sidebar card-no-border">
                {this.state.loading && <Preloader />}
                <HeaderAdmin />
                <Sidebaar />
                <div className="page-wrapper">
                    <div className="container-fluid">
                        <div className="row page-titles">
                            <div className="col-md-5 col-8 align-self-center">
                                <h3 className="text-themecolor m-b-0 m-t-0">Availability Detail</h3>
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item">
                                        <a href="/">Home</a>
                                    </li>

                                    <li className="breadcrumb-item active">Counsellor Availability</li>
                                    <li className="breadcrumb-item active">Availability Detail</li>
                                </ol>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-md-12">
                                <div className="pt-4">
                                    <div className="card">
                                        <div className="row">
                                            <div className="col-md-12">
                                                <div className="card-body">

                                                    <Formik
                                                        enableReinitialize={true}
                                                        initialValues={{
                                                            startTimeApointment: this.state.formDataHook.start
                                                                ? this.startTimeFormatterForEvents(this.state.formDataHook.start)
                                                                : "00:00",
                                                            endTimeApointment: this.state.formDataHook.end
                                                                ? this.startTimeFormatterForEvents(this.state.formDataHook.end)
                                                                : "00:30",
                                                            // slotSize:userData?.slotSize || '30',
                                                            slotSize: '30',
                                                            availabilities: this.state.availabilities
                                                            //  userData?.availability && userData?.availability[0] && userData?.availability[0].hasOwnProperty('timings') 
                                                            //   ? userData?.availability
                                                            //   :
                                                            // [
                                                            //     { day: "monday", timings: [{ openingTime: "12:00", closingTime: "18:00" }] },
                                                            //     // { day: "tuesday", timings: [{ openingTime: "12:00", closingTime: "18:00" }] },
                                                            //     // { day: "wednesday", timings: [{ openingTime: "12:00", closingTime: "18:00" }] },
                                                            //     // { day: "thursday", timings: [{ openingTime: "12:00", closingTime: "18:00" }] },
                                                            //     // { day: "friday", timings: [{ openingTime: "12:00", closingTime: "18:00" }] },
                                                            //     // { day: "saturday", timings: [{ openingTime: "12:00", closingTime: "18:00" }] },
                                                            //     // { day: "sunday", timings: [{ openingTime: "12:00", closingTime: "18:00" }] },
                                                            // ],
                                                        }}
                                                        onSubmit={(formData, { setSubmitting }) => {
                                                            // socket.emit("updateUserAttributes", {
                                                            //   attributes: {
                                                            //     ["availability"]: formData.listOfDay,
                                                            //   },
                                                            // });
                                                            let temp = {availabilities: this.state.availabilities, currentUserId: this.state.currentUser.id}
                                                            console.log(temp)
                                                            appointmentService.addAvailabilities(temp).then((data) => {
                                                                NotificationManager.success("Availability saved!");
                                                                setSubmitting(false);
                                                            });
                                                        }}
                                                        render={({
                                                            formData,
                                                            errors,
                                                            status,
                                                            touched,
                                                            isSubmitting,
                                                            values,
                                                            setFieldValue,
                                                            handleChange,
                                                        }) => (
                                                                <Form>
                                                                    <div className="p-4">
                                                                        <div className="row availability-row">
                                                                            <div className="col-md-12 d-flex">

                                                                                <div className="form-group">
                                                                                    <Link
                                                                                        to="/staff/counsellor-availability"
                                                                                        className="btn btn-primary"
                                                                                    >
                                                                                        <i className="mdi mdi-chevron-left"></i>
                                                                                        <span className="hide-menu">Back</span>
                                                                                    </Link>
                                                                                </div>

                                                                                <div className="form-group required d-flex" style={{ marginLeft: "31%" }}>
                                                                                    <label className="control-label">Select Date</label>
                                                                                    {/* <DatePicker className="form-control"
                                                                                            onChange={(value) => this.onChange(value)}
                                                                                            value={this.state.date}
                                                                                    /> */}
                                                                                    <Calendar className="calendercls"
                                                                                        id="calenderElem"
                                                                                        dateFormat="mm/dd/yy"
                                                                                        selectionMode="multiple"
                                                                                        minDate={new Date()} 
                                                                                        maxDate={moment().add(1, 'months').toDate()}
                                                                                        value={this.state.dates}
                                                                                        showIcon={true}
                                                                                        onChange={(e) => { this.setState({ dates: e.value }); this.onChange(e.value) }}
                                                                                    >
                                                                                    </Calendar>
                                                                                </div>

                                                                            </div>
                                                                        </div>

                                                                        <div className="d-flex line-background justify-content-between">
                                                                            <span className="">
                                                                            { this.props.match.params.name }
                                                                            </span>
                                                                        </div>

                                                                        <div
                                                                            className="pt-4 row availability-row"
                                                                            style={{marginTop: "19px", maxHeight: "370px", overflow: "auto" }}
                                                                        >
                                                                            {/* DAY TITLE */}
                                                                            <div className="col-md-4">
                                                                                <div className="form-group">
                                                                                    <label htmlFor="" className="control-label">
                                                                                        Date
                  </label>
                                                                                </div>
                                                                            </div>

                                                                            {/* OPENING TIME */}
                                                                            <div className="col-md-4 px-0">
                                                                                <div className="form-group required">
                                                                                    <label className="control-label">Start Time</label>
                                                                                </div>
                                                                            </div>

                                                                            {/* CLOSING TIME */}
                                                                            <div className="col-md-4 pl-0">
                                                                                <div className="form-group required">
                                                                                    <label className="control-label">End Time</label>
                                                                                </div>
                                                                            </div>

                                                                            <div className="col">
                                                                                <FieldArray
                                                                                    name="availabilities"
                                                                                    render={(arrayHelpers) =>
                                                                                        values.availabilities[0] ? 
                                                                                        values.availabilities.sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime()).map((row, index) => (
                                                                                            <div className="row mb-2 " style={{ borderRadius: '5px', border: '1px dashed grey' }} key={index}>
                                                                                                <div className="col-md-4">
                                                                                                    <div className="form-group">
                                                                                                        <label htmlFor="" className="control-label" style={{ textTransform: 'capitalize' }}>
                                                                                                            {row.date}
                                                                                                        </label>
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div className="col-md-3 p-0" style={{ marginLeft: "7%" }}>
                                                                                                    <FieldArray
                                                                                                        name={`availabilities[${index}][availability]`}
                                                                                                        render={(arrayHelpers1) =>
                                                                                                            <div className=" p-1" >
                                                                                                                {values.availabilities[index].availability.map((row1, index1) => (
                                                                                                                    <div key={index1} className="row p-0 mx-0 ">
                                                                                                                        <div className="col-md-5 px-1">
                                                                                                                            <div className="form-group mb-0 required">
                                                                                                                                <select
                                                                                                                                    value={row1.startTime}
                                                                                                                                    onChange={(event) => {

                                                                                                                                        this.state.timeOptions.forEach((time, i) => {
                                                                                                                                            if (time.value == event.target.value) {

                                                                                                                                                const tempArr = this.state.availabilities
                                                                                                                                                tempArr[index]['availability'][index1]['startTime'] = this.state.timeOptions[i].value
                                                                                                                                                this.setState({
                                                                                                                                                    availabilities: tempArr
                                                                                                                                                })
                                                                                                                                                console.log(this.state.availabilities)
                                                                                                                                            }
                                                                                                                                        });
                                                                                                                                    }}
                                                                                                                                    className={"form-control form-control-sm"}
                                                                                                                                >
                                                                                                                                    {this.state.timeOptions.map((opt, key) => (
                                                                                                                                        <option key={key} value={opt.value}>
                                                                                                                                            {opt.name}
                                                                                                                                        </option>
                                                                                                                                    ))}
                                                                                                                                </select>

                                                                                                                                <ErrorMessage
                                                                                                                                    name="startTime"
                                                                                                                                    component="div"
                                                                                                                                    className="invalid-feedback"
                                                                                                                                />
                                                                                                                            </div>
                                                                                                                        </div>

                                                                                                                        <div className="col-md-5 px-1">
                                                                                                                            <div className="form-group mb-0 required">
                                                                                                                                <select
                                                                                                                                    value={this.state.availabilities[index]['availability'][index1]['endTime']}
                                                                                                                                    onChange={(event) => {
                                                                                                                                        const tempArr = this.state.availabilities
                                                                                                                                                tempArr[index]['availability'][index1]['endTime'] = event.target.value
                                                                                                                                                this.setState({
                                                                                                                                                    availabilities: tempArr
                                                                                                                                                })
                                                                                                                                    }}
                                                                                                                                    className={"form-control form-control-sm"}
                                                                                                                                >
                                                                                                                                    
                                                                                                                                    {this.state.timeOptions.map((opt, key) => {
                                                                                                                                        // if (
                                                                                                                                        //   opt.value != "" &&
                                                                                                                                        //   opt.value > values.listOfDay[index].openingTime
                                                                                                                                        // ) {
                                                                                                                                        return (
                                                                                                                                            <option key={key} value={opt.value}>
                                                                                                                                                {opt.name}
                                                                                                                                            </option>
                                                                                                                                        );
                                                                                                                                        // } else {
                                                                                                                                        // }
                                                                                                                                    })}
                                                                                                                                </select>

                                                                                                                                <ErrorMessage
                                                                                                                                    name="endTime"
                                                                                                                                    component="div"
                                                                                                                                    className="invalid-feedback"
                                                                                                                                />
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                        <div className="col-md-1 px-1" onClick={() => {
                                                                                                                            const tempArr = this.state.availabilities
                                                                                                                            tempArr[index]['availability'].splice(index1, 1)
                                                                                                                            if (!tempArr[index]['availability'][0]) {
                                                                                                                                tempArr.splice(index, 1)
                                                                                                                            }

                                                                                                                            console.log(tempArr[index])
                                                                                                                            this.setState({
                                                                                                                                availabilities: tempArr
                                                                                                                            })
                                                                                                                        }} >
                                                                                                                            <a className={'btn  actn_btn d-flex justify-content-center align-items-center'} >-</a>
                                                                                                                        </div>

                                                                                                                    </div>))}
                                                                                                                <a className={'btn w-100 btn-primary btn-sm text-white'} onClick={() => {
                                                                                                                    const tempArr = this.state.availabilities
                                                                                                                    tempArr[index]['availability'].push({ startTime: "09:00", endTime: "16:00" })
                                                                                                                    this.setState({
                                                                                                                        availabilities: tempArr
                                                                                                                    })
                                                                                                                    // this.setState({
                                                                                                                    //     listOfDay : [...this.state.listOfDay[index][timings],
                                                                                                                    //        { openingTime: "12:00", closingTime: "18:00" }]
                                                                                                                    // })
                                                                                                                    // console.log(this.state.listOfDay)
                                                                                                                    // // arrayHelpers1.push({ openingTime: '12:00', closingTime: '12:00' });
                                                                                                                    // console.log(index)
                                                                                                                }} ><i className="fa fa-plus"></i>Add More</a>
                                                                                                            </div>
                                                                                                        }
                                                                                                    />
                                                                                                </div>


                                                                                            </div>
                                                                                        )) : <h3 style={{textAlign: "center"}}>No availabilities added </h3>
                                                                                    }
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                        {(this.state.availabilities[0]) &&
                                                                            <div className="row availability-row">
                                                                                <div className="col-md-12 mt-4 px-0">
                                                                                    <button className="btn btn-outline-primary"
                                                                                    >
                                                                                        Sav{isSubmitting ? "ing..." : "e"} Configuration
                                                    </button>
                                                                                </div>
                                                                            </div>
                                                                        }

                                                                    </div>
                                                                </Form>
                                                            )}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <FooterAdmin />
                </div>
            </div>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Availability)
