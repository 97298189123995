import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import Preloader from "../../components/Preloader";
import HeaderAdmin from "../../components/HeaderAdmin";
import Sidebaar from "../../components/user/Sidebaar";
import FooterAdmin from "../../components/FooterAdmin";
import { setUserData } from "../../redux-store/action";
import { userService } from "../../_services/admin";
import { authenticationService } from "../../_services";
import { contentLibraryService } from "../../_services/staff";
import { MDBDataTable } from "mdbreact";
import moment from "moment";
import DeleteConfirmation from "../../shared/DeleteConfirmation";
import swal from "sweetalert";

function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {
    setUserData: (name) => {
      dispatch(setUserData(name));
    },
  };
}

class ContentLibrary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentUser: authenticationService.currentUserValue,
      userFromApi: null,
      contentCategories: [],
      loading: true,
    };
  }

  componentDidMount() {
    const { currentUser } = this.state;
    userService.getUserById(currentUser.id).then((data) => {
      data.Data && this.props.setUserData(data.Data);
    });
    console.log(currentUser);

    this.getAllContentCategories();
  }

  deleteCategory(id) {
    contentLibraryService
      .deleteContentCategory(id, this.state.currentUser.id)
      .then((data) => {
        this.getAllContentCategories();
        swal("Resource Library Deleted Succesfully!", "", "success");
      })
      .catch((error) => {
        console.log("error: ", error);
        swal("Oops Something Went Wrong!", "", "error");
      });
  }

  getAllContentCategories() {
    contentLibraryService.getAll().then((data) => {
      let rowsArray = [];
      data &&
        data.Data &&
        data.Data.forEach((item, i) => {
          rowsArray.push({
            key: i + 1,
            category: item.name,
            description: item.description,
            view: (
              <React.Fragment>
                <Link
                  style={{ backgroundColor: "#a6ce39" }}
                  to={"/staff/category-detail/" + item._id}
                  className="btn btn-sm btn-primary"
                >
                  <span className="hide-menu">View Files</span>
                </Link>
              </React.Fragment>
            ),
            action: (
              <React.Fragment>
                <Link
                  to={"/staff/content-library/edit/" + item._id}
                  data-toggle="tooltip"
                  data-original-title="Edit"
                >
                  <i className="fas fa-pencil-alt m-r-20 text-muted"></i>
                </Link>
                <a
                  // onClick={() => this.deleteCategory(item._id)}
                  onClick={() => document.getElementById(item._id).click()}
                  data-toggle="tooltip"
                  data-original-title="Delete"
                >
                  <i className="fas fa-trash text-muted "></i>
                  <DeleteConfirmation
                    id={item._id}
                    confirm={() => this.deleteCategory(item._id)}
                  ></DeleteConfirmation>
                </a>
              </React.Fragment>
            ),
          });
        });
      let tableData = {
        columns: [
          // {
          //   label: "No.",
          //   field: "key",
          //   sort: "asc",
          //   width: 270,
          // },
          {
            label: "Category",
            field: "category",
            sort: "asc",
            width: 150,
          },
          {
            label: "Description",
            field: "description",
            sort: "asc",
            width: 150,
          },
          {
            label: "View",
            field: "view",
            sort: "disabled",
            width: 270,
          },
          {
            label: "Action",
            field: "action",
            sort: "disabled",
            width: 270,
          },
        ],
        rows: rowsArray,
      };
      if (this.state.currentUser.accessType == 1) {
        tableData.columns.pop();
        this.setState({ contentCategories: tableData, loading: false });
      } else {
        this.setState({ contentCategories: tableData, loading: false });
      }
    });
  }

  render() {
    // if (this.state.currentUser.accessType == 1) {
    //   this.state.contentCategories
    // }
    const { contentCategories } = this.state;
    return (
      <div id="main-wrapper" className="fix-header fix-sidebar card-no-border">
        {this.state.loading && <Preloader />}
        <HeaderAdmin />
        <Sidebaar />
        <div className="page-wrapper">
          <div className="container-fluid">
            <div className="row page-titles">
              <div className="col-md-5 col-8 align-self-center">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <a href="/">Home</a>
                  </li>
                  <li className="breadcrumb-item active">Resource Library</li>
                </ol>
              </div>
            </div>

            <div className="row">
              <div className="col-md-12">
                <div className="pt-4">
                  <div className="card">
                    <div className="card-body">
                      <div className="d-flex justify-content-between">
                        <div className="align-self-center tbl-head">
                          <i className="mdi mdi-view-dashboard mr-2"></i>
                          <span>RESOURCE LIBRARY</span>
                        </div>
                        {this.state.currentUser.accessType == 2 && (
                          <div>
                            <Link
                              to="/staff/content-library/create"
                              className="btn btn-primary"
                            >
                              <i className="mdi mdi-plus"></i>
                              <span className="hide-menu">
                                Add New Category
                              </span>
                            </Link>
                          </div>
                        )}
                      </div>
                      <hr />
                      <MDBDataTable
                        striped
                        bordered
                        hover
                        data={contentCategories}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <FooterAdmin />
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ContentLibrary);
