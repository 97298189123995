import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import {Provider} from 'react-redux';
import configureStore from './redux-store/store';
import * as serviceWorker from './serviceWorker';
import 'react-notifications/lib/notifications.css';
import {NotificationContainer, NotificationManager} from 'react-notifications';


// setup fake backend
// import { configureFakeBackend } from './_helpers';
// configureFakeBackend();

const store = configureStore();



ReactDOM.render(
    <Provider store={store}>
        <App />
        <NotificationContainer/>
    </Provider>,
    document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
