import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";

import { history, AccessType } from "../../_helpers";
import { authenticationService } from "../../_services";

function mapStateToProps(state) {
  return { currentDepartmentName: state.selectedDepartment };
}

class Sidebaar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentUser: authenticationService.currentUserValue,
    };
  }
  logout() {
    authenticationService.logout();
    history.push("/login");
  }
  render() {
    const { currentUser } = this.state;
    const { currentDepartmentName } = this.props;
    const accessModules = currentUser.accessModules || [];
    return (
      <aside className="left-sidebar">
        <div className="scroll-sidebar">
          <nav className="sidebar-nav">
            <ul id="sidebarnav">
              {/* {currentDepartmentName === 'Frontend Staff' &&
            <li>
            <Link
                  to="/staff/contents-library"
                  className="waves-effect waves-dark"
                >
                  <i className="mdi mdi-view-dashboard"></i>{" "}
                  <span className="hide-menu">Dashboard</span>
            </Link>
            </li>
  } */}
              {accessModules.includes(1) ? (
                <li>
                  <Link
                    to="/staff/contents-library"
                    className="waves-effect waves-dark"
                  >
                    <i className="mdi mdi-view-dashboard"></i>{" "}
                    <span className="hide-menu">Resource Library</span>
                  </Link>
                </li>
              ) : null}

              {accessModules.includes(2) ? (
                <li>
                  <Link
                    to="/staff/workshops/list"
                    className="waves-effect waves-dark"
                  >
                    <i className="mdi mdi-calendar-text"></i>{" "}
                    <span className="hide-menu">Workshops</span>
                  </Link>
                </li>
              ) : null}
              {accessModules.includes(3) ? (
                <li>
                  <Link
                    to="/staff/events/list"
                    className="waves-effect waves-dark"
                  >
                    <i className="mdi mdi-calendar-text"></i>{" "}
                    <span className="hide-menu">Events</span>
                  </Link>
                </li>
              ) : null}

              {currentDepartmentName === "Frontend Staff" && (
                <li>
                  <Link
                    to="/staff/userAppointment"
                    className="waves-effect waves-dark"
                  >
                    <i className="mdi mdi-calendar-check"></i>{" "}
                    <span className="hide-menu">User Appointment</span>
                  </Link>
                </li>
              )}

              {(currentDepartmentName === "IT Department" ||
                currentDepartmentName === "Frontend Staff") && (
                <li>
                  <Link
                    to="/staff/counsellor-availability"
                    className="waves-effect waves-dark"
                  >
                    <i className="mdi mdi-calendar-clock"></i>{" "}
                    <span className="hide-menu">Counsellor Availability</span>
                  </Link>
                </li>
              )}

              {currentDepartmentName === "Counsellors" && (
                <li>
                  <Link
                    to="/staff/appointments"
                    className="waves-effect waves-dark"
                  >
                    <i className="mdi mdi-calendar-check"></i>{" "}
                    <span className="hide-menu">Appointments</span>
                  </Link>
                </li>
              )}

              {/* {currentDepartmentName === 'Counsellors' &&
                <li>
                  <Link
                    to="/staff/counsellorCalender"
                    className="waves-effect waves-dark"
                  >
                    <i className="mdi mdi-calendar-check"></i>{" "}
                    <span className="hide-menu">View Appointments</span>
                  </Link>
                </li>
              } */}
              {currentDepartmentName === "IT Department" && (
                <li>
                  <Link
                    to="/staff/holidays"
                    className="waves-effect waves-dark"
                  >
                    <i className="mdi mdi-calendar-range"></i>{" "}
                    <span className="hide-menu">Holidays</span>
                  </Link>
                </li>
              )}

              {currentDepartmentName === "IT Department" && (
                <li>
                  <Link
                    to="/staff/users"
                    className="waves-effect waves-dark disabled"
                  >
                    <i className="mdi mdi-account-multiple"></i>{" "}
                    <span className="hide-menu">Users</span>
                  </Link>
                </li>
              )}

              <li>
                <a
                  href="https://211ontario.ca/"
                  target="_blank"
                  className="waves-effect waves-dark"
                >
                  <i className="mdi mdi-phone"></i>{" "}
                  <span className="hide-menu">211 Ontario Search</span>
                </a>
              </li>

              <li>
                <Link
                  to="/staff/user-analytics"
                  className="waves-effect waves-dark"
                >
                  <i className="mdi mdi-chart-bar"></i>{" "}
                  <span className="hide-menu">User Analytics</span>
                </Link>
              </li>

              <li>
                <a href="https://ayework.ca/" target="_blank">
                  <img
                    className="blink_me"
                    src="https://ayework.ca/wp-content/uploads/2020/03/logo-1.png"
                    alt="AyeWork"
                  />
                </a>
              </li>
            </ul>
          </nav>
        </div>

        <div className="sidebar-footer">
          {/* <a href="#" className="link" data-toggle="tooltip" title="Settings"><i className="ti-settings"></i></a>

                <a href="#" className="link" data-toggle="tooltip" title="Email"><i className="mdi mdi-gmail"></i></a>

                <a onClick={this.logout} className="link" data-toggle="tooltip" title="Logout"><i className="mdi mdi-power"></i></a> */}
        </div>
      </aside>
    );
  }
}

export default connect(mapStateToProps)(Sidebaar);
