import config from '../../config';
import { authHeader, handleResponse } from '../../_helpers';
import { encData } from '../../_helpers/_helperFunctions';
export const departmentService = {
    getAll,
    getDepartmentById,
    getSubDepartmentsByDepartmentId,
    getSubDepartmentsByDepartmentIdList
};

function getAll() {
    const requestOptions = { method: 'GET', headers: authHeader()};
    console.log("requestOptions: ", requestOptions)
    return fetch(`${config.apiUrl}/api/v1/departments`, requestOptions).then(handleResponse);
}

function getDepartmentById(id) {
    const requestOptions = { method: 'GET', headers: authHeader() };
    return fetch(`${config.apiUrl}/api/v1/departments/${id}`, requestOptions).then(handleResponse);
}

function getSubDepartmentsByDepartmentId(departmentId){
    const requestOptions = { method: 'GET', headers: authHeader() };
    return fetch(`${config.apiUrl}/api/v1/departments/${departmentId}/sub-departments`, requestOptions).then(handleResponse);
}

function getSubDepartmentsByDepartmentIdList(departmentIdList){
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: encData(departmentIdList)
    };
    return fetch(`${config.apiUrl}/api/v1/departments/getSubDepartments`, requestOptions).then(handleResponse);
}
