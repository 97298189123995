import React, { Component } from "react";
import { connect } from "react-redux";
import Preloader from "../../components/Preloader";
import HeaderAdmin from "../../components/HeaderAdmin";
import Sidebaar from "../../components/user/Sidebaar";
import FooterAdmin from "../../components/FooterAdmin";
import ITDepartmentDashboard from "../../components/user/ITDepartmentDashboard";
import FrontEndDeptDashboard from "../../components/user/FrontEndDeptDashboard";
import CounsellorsDashboard from "../../components/user/CounsellorsDashboard";
import { setUserData } from "../../redux-store/action";
import { authenticationService } from "../../_services";
import { userService } from "../../_services/admin";
function mapStateToProps(state) {
  return { currentDepartmentName: state.selectedDepartment };
}

function mapDispatchToProps(dispatch) {
  return {
    setUserData: (name) => {
      dispatch(setUserData(name));
    },
  };
}

class ClientDashboard extends Component {
  constructor(props) {
    super(props);

    this.state = {
      currentUser: authenticationService.currentUserValue,
      currentDepartmentName: authenticationService.currentDepartmentNameValue,
    };
  }

  componentDidMount() {
    // const { currentUser } = this.state;
    // userService.getUserById(currentUser.id).then((data) => {
    //   data.Data && this.props.setUserData(data.Data);
    // });
  }

  render() {
    const { currentUser } = this.state;
    const { currentDepartmentName } = this.props;
    return (
      <div id="main-wrapper" className="fix-header fix-sidebar card-no-border">
        <HeaderAdmin />
        <Sidebaar />
        <div className="page-wrapper">
          <div className="container-fluid">
            <div className="row page-titles">
              <div className="col-md-5 col-8 align-self-center">
                <h3 className="text-themecolor m-b-0 m-t-0">Staff Dashboard</h3>
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <a href="/">Home</a>
                  </li>
                  <li className="breadcrumb-item active">Staff Dashboard</li>
                </ol>
              </div>
            </div>
            {currentDepartmentName === "Counsellors" && (
              <CounsellorsDashboard />
            )}

            {currentDepartmentName === "Frontend Staff" && (
              <FrontEndDeptDashboard />
            )}
            {currentDepartmentName === "IT Department" && (
              <ITDepartmentDashboard />
            )}
            {currentDepartmentName !== "Frontend Staff" &&
              currentDepartmentName !== "Counsellors" &&
              currentDepartmentName !== "IT Department" && (
                <div className="row">
                  <div className="col-md-12">
                    <div className="pt-4">
                      <div className="card">
                        <div className="card-body">
                          <h3>Welcome to UHC..</h3>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
          </div>
          <FooterAdmin />
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ClientDashboard);
