import React, { Component } from "react";
import { appointmentService } from "../../_services/staff/appointment.service";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
am4core.useTheme(am4themes_animated);
export default class FrontEndDeptDashboard extends Component {
  state = {
    assignedBookingCount: 0,
    pendingBookingCount: 0,
    showPieChart: true,
  };
  componentDidMount() {
    appointmentService
      .getCounsellorAppointmentsStats(new Date().getTime())
      .then((data) => {
        console.log("data: ", data);
        if (data && data.Status) {
          if (data.Data.assignedCount) {
            let counsellorData = data.Data.counsellors.map((counsellor) => {
              return {
                name: counsellor.firstName,
                total: counsellor.bookings.length,
              };
            });

            let chart = am4core.create(
              "chartGenderVariation",
              am4charts.PieChart3D
            );
            chart.data = counsellorData;
            let pi_title = chart.titles.create();
            // pi_title.text = `Total: ${this.TotalGender}`;
            pi_title.fontSize = 14;
            pi_title.align = "left";

            let genderTitle = chart.titles.create();
            genderTitle.text = `Counsellor Appointments`;
            genderTitle.fontSize = 18;
            genderTitle.align = "center";

            let pieSeries = chart.series.push(new am4charts.PieSeries3D());
            pieSeries.dataFields.value = "total";
            pieSeries.dataFields.category = "name";
            pieSeries.slices.template.tooltipText = "{category}: {value.value}";

            chart.legend = new am4charts.Legend();
            chart.legend.valueLabels.template.text = "{value.value}";
            chart.exporting.menu = new am4core.ExportMenu();
            pieSeries.labels.template.text = "{category}: \n {value}";
            pieSeries.labels.template.fill = am4core.color("black");
            this.chart1 = chart;
          } else {
            this.setState({ showPieChart: false });
          }

          this.setState({
            pendingBookingCount: data.Data.pendingCount,
            assignedBookingCount: data.Data.assignedCount,
          });
        }
      });
  }

  componentWillUnmount() {
    if (this.chart1) {
      this.chart1.dispose();
    }
  }

  render() {
    return (
      <div className="row">
        <div className="col-md-12">
          <div className="pt-4">
            <div className="card">
              <div className="card-body">
                <div className="row" style={{ justifyContent: "center" }}>
                  <div className="col-sm-4">
                    <div className="card">
                      <div className="card-body d-flex">
                        <div className="round round-lg align-self-center round-info">
                          <i className="mdi mdi-book-open"></i>
                        </div>
                        <div className="m-l-10 align-self-center dashboard_cardTitle">
                          <h4 className="card-title">Appointment Requests</h4>
                          <p className="card-text dashboard_cardtext">
                            {this.state.pendingBookingCount}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  {this.state.showPieChart ? (
                    <div
                      id="chartGenderVariation"
                      style={{ width: "100%", height: "400px" }}
                    ></div>
                  ) : (
                    <div className="text-center">
                      <h3>
                        Currently there are no appointments assigned to the
                        counselors
                      </h3>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
