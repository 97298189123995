import React, { Component } from "react";
import { connect } from "react-redux";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import Preloader from "../../components/Preloader";
import HeaderAdmin from "../../components/HeaderAdmin";
import Sidebaar from "../../components/user/Sidebaar";
import FooterAdmin from "../../components/FooterAdmin";
import { setUserData } from "../../redux-store/action";
import { userService } from "../../_services/admin";
import { authenticationService } from "../../_services";
import { appointmentService } from "../../_services/staff/appointment.service";
import { Calendar } from "primereact/calendar";
import moment from "moment";
import MaskedInput from "react-text-mask";
import Select from "react-select";
import { Editor } from "primereact/editor";
import {
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";

import { capitalize, holidayDateList } from "../../_helpers/_helperFunctions";
import classNames from "classnames";
import { programType } from "../../_helpers";
import swal from "sweetalert";
function mapStateToProps(state) {
  return {
    holidayList: state.holidayList,
    socket: state.socket,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setUserData: (name) => {
      dispatch(setUserData(name));
    },
  };
}

function filterCounsellors(counsellorList, filterType) {
  if (filterType) {
    if (filterType === "BR & W") {
      return counsellorList.filter(
        (user) => user.counsellorType === "BR" || user.counsellorType === "W"
      );
    } else {
      return counsellorList.filter(
        (user) => user.counsellorType === filterType
      );
    }
  } else {
    return counsellorList;
  }
}

function visibilityFilter(counsellorlist, showAvailableOnly, options) {
  if (showAvailableOnly) {
    return counsellorlist.filter((user) => {
      if (user.availabilityData.length) {
        const data = user.availabilityData[0].availability.filter(
          (availData) => {
            return availData.isNA === undefined;
          }
        );
        if (data.length) {
          return true;
        } else {
          return false;
        }
      }
      return false;
    });
  }

  return counsellorlist;
}

const locationOptions = [
  { label: "BR", value: "BR" },
  { label: "W", value: "W" },
  { label: "BR & W", value: "BR & W" },
  { label: "LBS", value: "LBS" },
  { label: "IRCC", value: "IRCC" },
  { label: "KTH", value: "KTH" },
  { label: "Pre-App", value: "Pre-App" },
  { label: "CL", value: "CL" },
  { label: "SM", value: "SM" },
  { label: "ALSO", value: "ALSO" },
  { label: "Other", value: "Other" },
  { label: "All", value: "" },
];
const counsellorTypesOptions = [
  "",
  "BR",
  "W",
  "LBS",
  "IRCC",
  "KTH",
  "Pre-App",
  "CL",
  "SM",
  "ALSO",
  "Other",
];

const codeOptions = [
  {
    value: "employment-services",
    label: "ES",
  },
  {
    value: "youth-programs",
    label: "YJC",
  },
  {
    value: "literacy-basic-skills",
    label: "LBS",
  },
  {
    value: "resource-information-centre",
    label: "IRCC",
  },
  {
    value: "keep-it-hot",
    label: "KTH",
  },
  {
    value: "JMPI",
    label: "JMPI",
  },
  {
    value: "Pre-App",
    label: "Pre-App",
  },
];

export class CounsellorAvailability extends Component {
  constructor(props) {
    super(props);

    this.state = {
      currentUser: authenticationService.currentUserValue,
      currentDepartmentName: authenticationService.currentDepartmentNameValue,
      userFromApi: null,
      loading: true,
      counsellorList: [],
      allCounsellor: [],
      date: new Date(),
      userModal: false,
      note: null,
      selectedUser: "",
      availabilities: [],
      allUesrs: [],
      selectedCode: null,
      bookingStatus: null,
      appointmentType: null,
      filterType: "",
      toAttend: null,
      counsellorId: "",
      counsellorTime: "",
      counsellorNote: null,
      showAvailableOnly: false,
      selectedAvalibilityId: "",
      updateAvailabilityArray: [],
      clickedItem: null,
      bookingData: {},
      bookingInfoPopup: false,
      cancelBookingPopup: false,
      activeTab: "1",
      repeatQuaterly: false,
      repeatQuaterlyModal: false,
      brFilter: false,
      wFilter: false,
      isGreySlot: false,
      cancelModal: false,
      callStatus: "",
      conflictError: "",
      showErrorMessage: false,
      userName: null,
      bookingId: "",
      freeSlots: [],
      time: "",
      isHoliday: false,
      counsellorsBookedTimeSlots: {},
      removedSlots: [],
      availabilityLocation: "",
    };
  }

  timing = [
    "9:00",
    "9:30",
    "10:00",
    "10:30",
    "11:00",
    "11:30",
    "1:00",
    "1:30",
    "2:00",
    "2:30",
    "3:00",
    "3:30",
    "4:00",
    "4:30",
    "5:00",
  ];

  componentDidMount() {
    setTimeout(() => {
      this.props.socket.emit("join-room", { data: "Testing" });
      this.props.socket.addEventListener("appoinment_holding", (payload) => {
        if (payload?.date === moment(this.state.date).format("MM/DD/YYYY")) {
          const tmpCouncelloer = [...this.state.allCounsellor];
          const index = tmpCouncelloer.findIndex(
            (val) => val?._id === payload?.counsellorId
          );
          // eslint-disable-next-line no-unused-expressions
          if (tmpCouncelloer[index]?.availabilityData?.length === 0) {
            // eslint-disable-next-line no-unused-expressions
            tmpCouncelloer[index]?.availabilityData.push({
              availability: [payload],
            });
          } else {
            // eslint-disable-next-line no-unused-expressions
            tmpCouncelloer[index]?.availabilityData[0]?.availability?.push(
              payload
            );
          }
        }
      });
      this.props.socket.addEventListener("partial_appointment", (payload) => {
        if (payload?.date === moment(this.state.date).format("MM/DD/YYYY"));
        const data = [...this.state.allCounsellor];
        const index = data.findIndex(
          (val) => val?._id === payload?.counsellorId
        );
        const tempData = data[index]?.availabilityData[0]?.availability;
        const tempDataIndex = tempData?.findIndex(
          (val) => val?.bookingId === payload?.userId
        );
        if (
          tempData[tempDataIndex]?.bookingId === payload?.userId &&
          tempData[tempDataIndex]?.time === payload?.time
        ) {
          tempData[tempDataIndex].slotType = "Holding";
        }
      });
      this.props.socket.addEventListener(
        "remove_partial_appointment",
        (payload) => {
          if (payload?.date === moment(this.state.date).format("MM/DD/YYYY"));
          const data = [...this.state.allCounsellor];
          const index = data.findIndex(
            (val) => val?._id === payload?.counsellorId
          );
          const tempData = data[index]?.availabilityData[0]?.availability;
          const tempDataIndex = tempData?.findIndex(
            (val) => val?.bookingId === payload?.bookingId
          );
          if (tempData[tempDataIndex]?.bookingId === payload?.bookingId) {
            delete tempData[tempDataIndex]?.slotType;
          }
        }
      );
      this.props.socket.addEventListener(
        "remove_hold_appointment",
        (payload) => {
          const tmpCouncelloer = [...this.state.allCounsellor];
          if (tmpCouncelloer && tmpCouncelloer.length > 0) {
            const index = tmpCouncelloer.findIndex(
              (val) => val._id === payload?.counsellorId
            );

            if (tmpCouncelloer[index]?.availabilityData) {
              const availabilityArr =
                tmpCouncelloer[index]?.availabilityData[0]?.availability;

              if (availabilityArr && availabilityArr.length > 0) {
                const availIndex = availabilityArr?.findIndex(
                  (item) => item?.counsellorId === payload?.counsellorId
                );
                availabilityArr.splice(availIndex, 1);
              }
            }
          }
        }
      );
    }, 2000);
    this.getCounsellorsWithAvailabilies(new Date());
    window.addEventListener("beforeunload", this.handleBeforeUnload);
    //this.getAllUsers();
    //this.getAvailabilities();
  }

  componentWillUnmount() {
    window.removeEventListener("beforeunload", this.handleBeforeUnload);
  }

  handleBeforeUnload = async (event) => {
    event.preventDefault();
    if (this.props?.socket) {
      if (this.state.bookingId === "") {
        this.props.socket.emit("leaving_hold_appointment", {
          counsellorId: this.state?.counsellorId,
          bookingId: this.state.bookingId,
        });
        await appointmentService.deleteSelctedSlot({
          counsellorId: this.state?.counsellorId,
        });
      } else {
        const payload = {
          counsellorId: this.state.counsellorId,
          bookingId: this.state?.bookingId,
        };
        this.props.socket.emit("leave_partiallyBooked_appointment", payload);
        await appointmentService.deleteSlotTypeByBookingId({
          bookingId: this.state?.bookingId,
        });
      }
    }
  };
  componentDidUpdate(prevProps, prevState) {
    let prevAppointment = prevProps.appointmentChangeId;
    let newAppointment = this.props.appointmentChangeId;
    if (prevAppointment !== newAppointment) {
      // refetch all the bookings.
      this.getAllBookings();
    }
    if (
      prevState?.date !== this.state.date ||
      prevProps?.holidayList !== this.props?.holidayList
    ) {
      const day = moment(this.state.date).format("dddd");
      if (day === "Saturday" || day === "Sunday") {
        this.setState({
          isHoliday: true,
        });
      } else {
        const index = this.props.holidayList.findIndex(
          (val) => val.date === moment(this.state.date).format("MM/DD/YYYY")
        );

        this.setState({
          isHoliday: index !== -1 ? true : false,
        });
      }
    }
  }

  toggleDetails = () => {
    this.setState({
      counsellorNote: null,
      appointmentType: null,
      bookingStatus: null,
      phoneNumber: null,
      email: null,
      toAttend: null,
      userName: "",
      selectedCode: "",
      selectedUser: "",
      availabilityLocation: "",
      userModal: !this.state.userModal,
    });
  };

  visibilityFilter = (counsellorList, filter, options) => {
    let { availabilities } = this.state;
    let showAvailableOnly =
      typeof filter !== "undefined" ? filter : this.state.showAvailableOnly;
    if (showAvailableOnly && availabilities.length > 0) {
      return counsellorList.filter((user) => {
        let result = false;
        availabilities.forEach((availData) => {
          if (availData.counsellorId === user._id) {
            if (availData.availability.length > 0) {
              result = true;
            }
          }
        });
        return result;
      });
    } else {
      return counsellorList;
    }
  };

  filterUser = (filterType) => {
    this.setState((state) => {
      return {
        filterType: filterType,
        allCounsellor: visibilityFilter(
          filterCounsellors(state.counsellorList, filterType),
          state.showAvailableOnly,
          1
        ),
      };
    });
  };

  getCounsellor = (id) => {
    const data = this.state?.allCounsellor?.filter((val) => id === val?._id);
    return {
      label:
        data[0]?.firstName && data[0]?.lastName
          ? data[0].firstName.slice(0, 1) + "" + data[0].lastName.slice(0, 1)
          : "Counsellor",
      value: data[0]?._id,
    };
  };

  toggleShowAvailableOnly = () => {
    this.setState((state) => {
      return {
        showAvailableOnly: !state.showAvailableOnly,
        allCounsellor: visibilityFilter(
          filterCounsellors(state.counsellorList, state.filterType),
          !state.showAvailableOnly,
          2
        ),
      };
    });
  };

  getBookingById = (id) => {
    appointmentService
      .getBookingById(id)
      .then((res) => {
        let bStatus = {
          label: res.Data.bookingStatus,
          value: res.Data.bookingStatus,
        };
        this.setState({
          bookingData: res.Data,
          email: res.Data.email,
          phoneNumber: res.Data.phoneNumber,
          userName: res.Data.name,
          counsellorNote: res.Data.notes,
          selectedCode: {
            value: res.Data.codeStatus,
            label: res.Data.codeStatus,
          },
          counsellorId: res.Data.counsellorId,
          counsellorTime: res.Data.time,
          status: res.Data.status,
          toAttend: { value: res.Data.toAttend, label: res.Data.toAttend },
          bookingStatus: res.Data.bookingStatus ? bStatus : null,
          appointmentType: {
            value: res.Data.appointmentType,
            label: res.Data.appointmentType,
          },
        });
        const data = {
          status: res.Data.status,
          counsellorId: res.Data.counsellorId,
          userId: res.Data._id,
          date: res.Data.date,
          time: res.Data.time,
        };
        this.props.socket.emit("partiallyBooked_appoinment", data);
        this.getTimeSlotWithCounsellorAvailablities({
          date: this.state.date,
          id: this.state.counsellorId,
          availabilityLocation: this.state?.availabilityLocation,
        });
      })
      .catch((err) => console.log("Error: ", err));
  };

  editInput = (id, time, availabilityLocation, isNA) => {
    let originalList = this.state.allCounsellor;
    let newList = Array.from(originalList);
    for (let i = 0; i < newList.length; i++) {
      if (
        newList[i]._id === id &&
        newList[i].counsellorType === availabilityLocation
      ) {
        if (newList[i].availabilityData.length) {
          let index = newList[i].availabilityData[0].availability.findIndex(
            (item) => item.time === time
          );
          if (index === -1) {
            let obj = { time: time, availabilityLocation };
            if (isNA) {
              obj["isNA"] = true;
            }
            newList[i].availabilityData[0].availability.push(obj);
            const isItemIncludedInCounsellorBookTimeSlots =
              this.state.counsellorsBookedTimeSlots?.[id]?.some(
                (item) =>
                  item.time === time &&
                  item.availabilityLocation === availabilityLocation
              );
            const isItemIncludedInRemovedSlots = this.state.removedSlots?.[
              id
            ]?.some(
              (item) =>
                item.time === time &&
                item.availabilityLocation === availabilityLocation
            );
            if (
              isItemIncludedInCounsellorBookTimeSlots &&
              isItemIncludedInRemovedSlots
            ) {
              const removedSlotsArray = { ...this.state.removedSlots };
              const removedTimeIndex = removedSlotsArray[id]?.findIndex(
                (item) =>
                  item.time === time &&
                  item.availabilityLocation === availabilityLocation
              );
              if (removedTimeIndex !== -1) {
                removedSlotsArray[id].splice(removedTimeIndex, 1);
                this.setState({ removedSlots: removedSlotsArray });
              }
            }
          } else {
            const isBooked = this.state.counsellorsBookedTimeSlots?.[id]?.some(
              (item) =>
                item.time === time &&
                item.availabilityLocation === availabilityLocation
            );

            if (isBooked) {
              this.setState((prevState) => ({
                removedSlots: {
                  ...prevState.removedSlots,
                  [id]: [
                    ...(prevState.removedSlots[id] || []),
                    { time, availabilityLocation },
                  ],
                },
              }));
            }
            newList[i].availabilityData[0].availability.splice(index, 1);
          }
        } else {
          let data = {
            date: moment(this.state.date).format("MM/DD/YYYY"),
            day: moment(this.state.date).format("dddd"),
            availability: [
              {
                time: time,
                availabilityLocation: availabilityLocation,
              },
            ],
            counsellorId: id,
          };
          if (isNA) {
            data["availability"][0]["isNA"] = true;
          }
          newList[i].availabilityData.push(data);
        }
        break;
      }
    }
    this.setState({
      allCounsellor: visibilityFilter(
        filterCounsellors(newList, this.state.filterType),
        this.state.showAvailableOnly
      ),
    });
  };

  addAvailability = (options) => {
    let availabilities = [];
    let counsellorList = this.state.allCounsellor;
    for (let i = 0; i < counsellorList.length; i++) {
      if (counsellorList[i].availabilityData.length) {
        availabilities.push(counsellorList[i].availabilityData[0]);
      }
    }
    let postData = {
      availabilities: availabilities,
      currentUserId: this.state.currentUser.id,
      repeatQuaterly: options.repeatQuaterly ? true : false,
      removedSlots: this.state.removedSlots,
    };

    appointmentService.addAvailabilities(postData).then((data) => {
      this.toggleRepeatQuaterlyModal();
      swal("Availability saved!", "", "success");
      this.setState({ removedSlots: [] });
      setTimeout(() => {
        this.getCounsellorsWithAvailabilies(this.state.date);
      }, 1000);
      //NotificationManager.success("Availability saved!", null, 5000);
    });
  };

  getAvailabilities = (dt) => {
    this.setState({
      availabilities: [],
    });
    appointmentService
      .getAllAvailabilityByDate(moment(dt).valueOf())
      .then((data) => {
        if (data.Data?.[0]) {
          this.setState({
            availabilities: data.Data,
          });
        }
      })
      .catch((err) => {
        this.setState({
          availabilities: [],
        });
      });
  };
  addBookSlot = async (data) => {
    this.props.socket.emit("hold_appoinment", data);
    await appointmentService
      .addBookSlot(data)
      .then((res) => {
        // console.log(res);
      })
      .catch((error) => {
        this.setState({
          conflictError: error,
          showErrorMessage: true,
          userModal: true,
        });
      });
  };
  addSlotTypeByBookingId = async (data) => {
    await appointmentService
      .addSlotTypeByBookingId(data)
      .then((res) => {
        this.setState({ bookingId: data.bookingId });
      })
      .catch((error) => {
        this.setState({
          conflictError: error,
          showErrorMessage: true,
          userModal: true,
        });
      });
  };
  deleteSlotTypeByBookingId = async (data) => {
    await appointmentService.deleteSlotTypeByBookingId(data);
    this.setState({
      isGreySlot: false,
      counsellorNote: null,
      bookingStatus: null,
      selectedCode: "",
      toAttend: "",
      appointmentType: "",
      name: "",
      email: null,
      userName: null,
      phoneNumber: null,
    });
  };
  deleteSelectedSlot = async (counsellorId) => {
    await appointmentService.deleteSelctedSlot(counsellorId);
  };
  updateBookingCallStatus = async (
    counsellorAvailabilityId,
    bookingId,
    availabilityId
  ) => {
    let data = {
      counsellorAvailabilityId,
      callStatus: this.state.callStatus,
      bookingId,
      availabilityId,
    };
    await appointmentService.updateBookingCallStatus(data);
    this.getCounsellorsWithAvailabilies(this.state.date);
  };

  appointCounsellor = () => {
    let data = {
      bookingId: this.state.selectedUser?.value._id,
      date: moment(this.state.date).format("MM/DD/YYYY"),
      day: moment(this.state.date).format("dddd"),
      time: this.state.counsellorTime,
      counsellorId: this.state.counsellorId,
      appointmentType: this.state.appointmentType?.value
        ? this.state.appointmentType?.value
        : "",
      currentUserId: this.state.currentUser?.id,
      availabilityId: this.state.selectedAvalibilityId,
      codeStatus: this?.state?.selectedCode?.label,
      isGreySlot: this.state.isGreySlot,
      bookingStatus: this.state.bookingStatus?.value
        ? this.state.bookingStatus?.value
        : null,
      toAttend: this.state.toAttend?.value ? this.state.toAttend?.value : "",
      notes: this?.state?.counsellorNote ? this?.state?.counsellorNote : "",
      availabilityLocation: this?.state?.availabilityLocation,
    };
    appointmentService
      .approveAppointment(data)
      .then((res) => {
        this.toggleDetails();
        this.setState({
          isGreySlot: false,
          counsellorNote: null,
          bookingStatus: null,
          selectedCode: "",
          toAttend: "",
          appointmentType: "",
        });
        this.getCounsellorsWithAvailabilies(this.state.date);
      })
      .catch((error) => {
        swal(error, "", "error");
      });
  };

  getTimeSlotWithCounsellorAvailablities = (data) => {
    appointmentService
      .getCounsellorsWithAvailabilities(data?.date.getTime())
      .then((response) => {
        const availableTimes = [];
        // eslint-disable-next-line no-unused-expressions
        response?.Data?.forEach((item) => {
          item.availabilityData.forEach((availabilityData) => {
            if (availabilityData?.counsellorId === data?.id) {
              availabilityData.availability.forEach((availability) => {
                if (
                  !("bookingId" in availability) &&
                  data?.availabilityLocation ===
                    availability?.availabilityLocation
                )
                  availableTimes.push(availability.time);
              });
            }
          });
        });
        this.setState({ freeSlots: availableTimes });
      })
      .catch((err) => {
        console.log("err getCounsellorsWithAvailabilies: ", err);
      });
  };
  getCounsellorsWithAvailabilies = (dt) => {
    appointmentService
      .getCounsellorsWithAvailabilities(dt.getTime())
      .then((data) => {
        if (data && data.Status) {
          this.setState(
            {
              allCounsellor: visibilityFilter(
                filterCounsellors(data.Data, this.state.filterType),
                this.state.showAvailableOnly
              ),
              counsellorList: data.Data,
              loading: false,
            },
            () => {
              const tempTimeSlotsData = {};
              // eslint-disable-next-line no-unused-expressions
              this.state.allCounsellor?.forEach((counsellorItem) => {
                if (counsellorItem?.availabilityData?.length > 0) {
                  const slotTimeArray =
                    counsellorItem.availabilityData[0]?.availability?.map(
                      (availabilityItem) => ({
                        time: availabilityItem?.time,
                        availabilityLocation:
                          availabilityItem?.availabilityLocation,
                      })
                    );
                  if (counsellorItem?._id) {
                    tempTimeSlotsData[counsellorItem._id] = [
                      ...(tempTimeSlotsData[counsellorItem._id] ?? []),
                      ...slotTimeArray,
                    ];
                  }
                }
              });
              this.setState({ counsellorsBookedTimeSlots: tempTimeSlotsData });
            }
          );
          this.getPendingAppointment(
            visibilityFilter(
              filterCounsellors(data.Data, this.state.filterType),
              this.state.showAvailableOnly
            ),
            this.state?.date
          );
        }
      })
      .catch((err) => {
        console.log("err getCounsellorsWithAvailabilies: ", err);
      });

    this.getAllBookings();
  };

  getPendingAppointment = async (arr, date) => {
    await appointmentService
      .pendingAppointment(date.getTime())
      .then((res) => {
        if (res.Data.length > 0) {
          res.Data.map((item) => {
            const tmpCouncelloer = [...arr];
            const index = tmpCouncelloer.findIndex(
              (val) => val._id === item.counsellorId
            );
            // eslint-disable-next-line no-unused-expressions
            if (tmpCouncelloer[index]?.availabilityData.length === 0) {
              // eslint-disable-next-line no-unused-expressions
              tmpCouncelloer[index]?.availabilityData.push({
                availability: [item],
              });
            } else {
              // eslint-disable-next-line no-unused-expressions
              tmpCouncelloer[index]?.availabilityData[0]?.availability.push(
                item
              );
            }
          });
        }
      })
      .catch((error) => {
        this.setState({
          conflictError: error,
          showErrorMessage: true,
          userModal: true,
        });
      });
  };
  getAllBookings = () => {
    appointmentService
      .getAllBookings()
      .then((data) => {
        let filteredData = data.Data.filter((item) => {
          return (
            item.reason.includes("Employment-Services") ||
            item.reason.includes("Youth-Programs") ||
            item.reason.includes("literacy-basic-skills") ||
            item.reason.includes("resource-information-centre") ||
            item.reason.includes("keep-it-hot") ||
            item.reason.includes("JMPI") ||
            item.reason.includes("other") ||
            item.reason.includes("newcomer-services") ||
            item.reason.includes("energy-assistance") ||
            item.reason.includes("food-assistance") ||
            item.reason.includes("ged") ||
            item.reason.includes("pre-apprenticeship") ||
            item.reason.includes("employment-workshops") ||
            item.reason.includes("workshops") ||
            item.reason.includes("cooking-classes") ||
            item.reason.includes("second-career")
          );
        });
        this.setState({
          allUesrs: filteredData,
        });
      })
      .catch((error) => {
        console.log("error: ", error);
      });
  };

  getAllUsers = () => {
    userService
      .getUsersByDepartmentName("Counsellors")
      .then((data) => {
        this.setState({
          allCounsellor: visibilityFilter(
            filterCounsellors(data.Data, this.state.filterType),
            this.state.showAvailableOnly
          ),
          counsellorList: data.Data,
          loading: false,
        });
      })
      .catch((error) => {
        console.log("error: ", error);
      });
  };

  toggleCancelBooking = () => {
    this.setState({
      isGreySlot: false,
      counsellorNote: null,
      bookingStatus: null,
      selectedCode: "",
      toAttend: "",
      appointmentType: "",
      name: "",
      email: null,
      userName: null,
      phoneNumber: null,
    });
    this.setState((state) => {
      return { cancelBookingPopup: !state.cancelBookingPopup };
    });
  };

  cancelBooking = () => {
    let selectedBooking = this.state.clickedItem;
    let { selectedAvalibilityId, isGreySlot } = this.state;
    let currentUserId = this.state.currentUser.id;

    let requestData = {
      bookingId: selectedBooking.bookingId,
      availabilityId: selectedAvalibilityId,
      currentUserId: currentUserId,
      time: selectedBooking.time,
      isGreySlot: isGreySlot,
    };

    appointmentService
      .cancelBooking(requestData)
      .then((data) => {
        if (data.Status && data.Data) {
          swal(data.Data.message, "", "success");
          //NotificationManager.success(data.Data.message,null,5000);
          this.toggleCancelBooking();
          this.toggleBookingInfoPopup();
          //this.getAvailabilities(this.state.date);
          this.getCounsellorsWithAvailabilies(this.state.date);
        }
      })
      .catch((error) => {
        swal(error, "", "error");
        //NotificationManager.error(error,null,5000);
      });
  };

  toggleBookingInfoPopup = (id) => {
    this.setState((state) => {
      let stateObj = {
        bookingInfoPopup: !state.bookingInfoPopup,
      };
      if (state.bookingInfoPopup) {
        stateObj["bookingdata"] = {};
        stateObj["isGreySlot"] = false;
        this.setState({
          isGreySlot: false,
          counsellorNote: null,
          bookingStatus: null,
          selectedCode: "",
          toAttend: "",
          appointmentType: "",
          name: "",
          email: null,
          userName: null,
          phoneNumber: null,
        });
        const payload = {
          counsellorId: this.state.counsellorId,
          bookingId: this.state.bookingData._id,
        };
        this.props.socket.emit("leave_partiallyBooked_appointment", payload);
        appointmentService.deleteSlotTypeByBookingId({
          bookingId: this.state?.bookingId,
        });
      } else {
        this.getBookingById(id);
      }
      return stateObj;
    });
  };

  updateBookingNotes = () => {
    let date = moment(this.state.date).format("MM/DD/YYYY");
    let data = {
      counsellorId: this.state.counsellorId,
      bookingId: this.state.bookingData._id,
      notes: this.state.counsellorNote,
      email: this.state.email,
      name: this.state.userName,
      phoneNumber: this.state.phoneNumber,
      codeStatus: this.state.selectedCode?.label
        ? this.state.selectedCode?.label
        : "",
      day: moment(this.state.date).format("dddd"),
      toAttend: this.state.toAttend?.label ? this.state.toAttend?.label : "",
      bookingStatus: this.state.bookingStatus?.label
        ? this.state.bookingStatus?.label
        : null,
      date: date,
      time: this.state.counsellorTime ? this.state.counsellorTime : "",
      appointmentType: this.state.appointmentType?.label
        ? this.state.appointmentType?.label
        : "",
      availabilityLocation: this.state?.availabilityLocation || "",
    };
    appointmentService
      .updateBookingNotes(data)
      .then((res) => {
        const payload = {
          counsellorId: this.state.counsellorId,
          bookingId: this.state.bookingData._id,
        };
        this.props.socket.emit("leave_partiallyBooked_appointment", payload);
        this.deleteSlotTypeByBookingId({
          bookingId: this.state.bookingData._id,
        });
        setTimeout(() => {
          this.getCounsellorsWithAvailabilies(this.state.date);
        }, 500);
        this.toggleBookingInfoPopup();
        this.setState({
          counsellorNote: null,
          toAttend: null,
          bookingStatus: null,
          email: null,
          phoneNumber: null,
          userName: null,
          selectedCode: null,
          appointmentType: null,
        });
      })
      .catch((error) => {
        swal(error, "", "error");
      });
  };

  toggleTab = (tab) => {
    this.setState((state) => {
      if (state.activeTab !== tab) return { activeTab: tab };
    });
  };

  handleOnChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  appointWalkin = () => {
    let data = {
      date: moment(this.state.date).format("MM/DD/YYYY"),
      day: moment(this.state.date).format("dddd"),
      time: this.state.counsellorTime,
      counsellorId: this.state.counsellorId,
      appointmentType: this.state.appointmentType?.value,
      currentUserId: this.state.currentUser?.id,
      availabilityId: this.state.selectedAvalibilityId,
      name: this.state.userName,
      phoneNumber: this.state.phoneNumber,
      email: this.state.email,
      isGreySlot: this.state.isGreySlot,
      codeStatus: this.state.selectedCode?.label,
      reason: this.state.selectedCode?.value,
      bookingStatus: this.state.bookingStatus?.value
        ? this.state.bookingStatus?.value
        : null,
      toAttend: this.state.toAttend?.value ? this.state.toAttend?.value : "",
      notes: this?.state?.counsellorNote ? this?.state?.counsellorNote : "",
      availabilityLocation: this?.state?.availabilityLocation,
    };

    appointmentService
      .assignWalkinAppointment(data)
      .then((res) => {
        this.toggleDetails();
        this.setState({
          isGreySlot: false,
          email: null,
          userName: null,
          counsellorNote: "",
          phoneNumber: null,
          toAttend: "",
          bookingStatus: null,
          appointmentType: "",
          selectedCode: "",
          availabilityLocation: "",
        });
        this.getCounsellorsWithAvailabilies(this.state.date);
      })
      .catch((error) => {
        swal(error, "", "error");
        //NotificationManager.error(error, null, 5000)
      });
  };

  handleAssignGray = (counsellor) => {};

  toggleRepeatQuaterlyModal = () => {
    this.setState((state) => {
      return { repeatQuaterlyModal: !state.repeatQuaterlyModal };
    });
  };

  updateCounsellorType = (e, counsellorId) => {
    let requestData = {
      counsellorId: counsellorId,
      currentUserId: this.state.currentUser.id,
      counsellorType: e.target.value || " ",
    };

    userService.updateCounsellorType(requestData).then((data) => {
      if (data.Status) {
        swal("Counsellor Type updated", "", "success");
        //NotificationManager.success("Counsellor Type updated", null, 5000);
        this.getCounsellorsWithAvailabilies(this.state.date);
      }
    });
  };

  renderInput = (id, time, availabilityLocation, isChecked, isNA) => {
    if (this.state.currentDepartmentName === "IT Department") {
      return (
        <div className="d-flex">
          {!isNA && (
            <div className="form-check availability-input">
              <input
                className="form-check-input"
                type="checkbox"
                id={id + availabilityLocation + "+" + time}
                onChange={() => this.editInput(id, time, availabilityLocation)}
                checked={isChecked}
              />
              <label
                className="form-check-label"
                htmlFor={id + availabilityLocation + "+" + time}
              ></label>
            </div>
          )}

          {!isChecked && (
            <div className="form-check availability-input ml-auto">
              <input
                className="form-check-input"
                type="checkbox"
                id={id + availabilityLocation + "+NA+" + time}
                onChange={() =>
                  this.editInput(id, time, availabilityLocation, true)
                }
                checked={isNA}
              />
              <label
                className="form-check-label"
                htmlFor={id + availabilityLocation + "+NA+" + time}
              >
                NA
              </label>
            </div>
          )}
        </div>
      );
    }
    return null;
  };

  getSlotColor = (elem) => {
    if (elem.isNA) {
      return "#dc3545";
    }
    if (elem?.slotType === "Holding") return "grey";

    // if (elem?.availabilityLocation === "W") {
    //   return "#a6ce39";
    // }
    return "#5955a5";
    // return ["virtual", "Virtual"].includes(elem.appointmentType)
    //   ? "#a6ce39"
    //   : "#5955a5";
  };
  exportPDF = () => {
    const input = document.getElementById("mainDivision");
    html2canvas(input, {
      logging: true,
      letterRendering: 1,
      useCORS: true,
    }).then((canvas) => {
      const imageWidth = 270;
      const imageHeight = (canvas.height * imageWidth) / canvas.width;
      const imgData = canvas.toDataURL("img/png");
      const pdf = new jsPDF("l", "mm", "a4");
      pdf.addImage(imgData, "PNG", 0, 0, imageWidth, imageHeight);
      pdf.save("Counsellor Appointment.pdf");
    });
  };

  render() {
    return (
      <div id="main-wrapper" className="fix-header fix-sidebar card-no-border">
        {this.state.loading && <Preloader />}
        <HeaderAdmin />
        <Sidebaar />
        <div className="page-wrapper">
          <div className="container-fluid">
            <div className="row page-titles">
              <div className="col-md-5 col-8 align-self-center">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <a href="/">Home</a>
                  </li>
                  <li className="breadcrumb-item active">
                    Counsellor Availability
                  </li>
                </ol>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <div className="pt-4">
                  <div className="card">
                    <div className="card-body">
                      <div className="d-flex justify-content-between">
                        <div className="align-self-center tbl-head">
                          <i className="mdi mdi-calendar-clock"></i>{" "}
                          <span>Counsellor Availability</span>
                        </div>
                        <div className="switch">
                          <label>
                            Show Available Only
                            <input
                              type="checkbox"
                              checked={this.state.showAvailableOnly}
                              onChange={this.toggleShowAvailableOnly}
                            />
                            <span className="lever"></span>
                          </label>
                        </div>
                      </div>
                      <hr />
                      {/* <MDBDataTable bordered hover data={this.state.counsellor} /> */}
                      <div className="table-responsive" id="mainDivision">
                        <div className="d-flex space_between flex-wrap">
                          <div className="calenderArrowButtons">
                            <div className="calendercls mb-4">
                              <Calendar
                                id="calenderElem"
                                className="with-holiday "
                                dateFormat="dd, M yy, DD"
                                // selectionMode="multiple"
                                value={this.state.date}
                                showIcon={true}
                                onChange={(e) => {
                                  this.setState({
                                    date: e.value,
                                  });
                                  //this.getAvailabilities(e.value);
                                  this.getCounsellorsWithAvailabilies(e.value);
                                }}
                                disabledDates={holidayDateList(
                                  this.props.holidayList
                                )}
                                readOnlyInput
                              ></Calendar>
                            </div>
                            <div>
                              <Button
                                className=""
                                outline
                                color="secondary"
                                onClick={() => {
                                  const day = moment(this.state.date).format(
                                    "dddd"
                                  );
                                  let subractedDate;
                                  if (day === "Monday") {
                                    subractedDate = moment(this.state.date)
                                      .subtract(3, "d")
                                      .toDate();
                                  } else {
                                    subractedDate = moment(this.state.date)
                                      .subtract(1, "d")
                                      .toDate();
                                  }
                                  this.setState({ date: subractedDate });
                                  this.getCounsellorsWithAvailabilies(
                                    subractedDate
                                  );
                                }}
                              >
                                &lt;
                              </Button>
                              <Button
                                className=""
                                outline
                                color="secondary"
                                onClick={() => {
                                  const day = moment(this.state.date).format(
                                    "dddd"
                                  );
                                  let addedDate;
                                  if (day === "Friday") {
                                    addedDate = moment(this.state.date)
                                      .add(3, "d")
                                      .toDate();
                                  } else {
                                    addedDate = moment(this.state.date)
                                      .add(1, "d")
                                      .toDate();
                                  }

                                  this.setState({ date: addedDate });
                                  this.getCounsellorsWithAvailabilies(
                                    addedDate
                                  );
                                }}
                              >
                                &gt;
                              </Button>
                            </div>
                          </div>
                          <div className="mb-4 mr-2 allBtnTabl">
                            <div className="d-flex flex-wrap">
                              {this.state.currentDepartmentName ===
                                "IT Department" && (
                                <Button
                                  className="mr-2 mb-2"
                                  onClick={this.toggleRepeatQuaterlyModal}
                                  color="info"
                                >
                                  Save
                                </Button>
                              )}
                              <Button
                                className="mr-2 mb-2"
                                color="info"
                                onClick={() => this.exportPDF()}
                              >
                                Print
                              </Button>
                              {locationOptions.map((locationItem, index) => (
                                <Button
                                  key={index}
                                  className="mr-2 mb-2"
                                  color="primary"
                                  onClick={() =>
                                    this.filterUser(locationItem.value)
                                  }
                                >
                                  {locationItem.label}
                                </Button>
                              ))}
                            </div>
                          </div>
                        </div>
                        {!this.state.isHoliday ? (
                          <div
                            style={{
                              overflow: "auto",
                              width: "100%",
                              height: "80vh",
                            }}
                          >
                            <table className="table">
                              <thead
                                className="thead-dark  text-center"
                                style={{ position: "sticky", top: "0" }}
                              >
                                <tr>
                                  <th scope="col"></th>
                                  <th scope="col"></th>
                                  <th scope="col">
                                    <div>9:00</div>
                                    <div>am</div>
                                  </th>
                                  <th scope="col">
                                    09:30 <br /> am
                                  </th>
                                  <th scope="col">
                                    10:00 <br /> am
                                  </th>
                                  <th scope="col">
                                    10:30 <br /> am
                                  </th>
                                  <th scope="col">
                                    11:00 <br /> am
                                  </th>
                                  <th
                                    scope="col"
                                    style={{ display: "none" }}
                                  ></th>
                                  <th scope="col">
                                    11:30 <br /> pm
                                  </th>
                                  <th scope="col">
                                    1:00 <br /> pm
                                  </th>
                                  <th scope="col">
                                    01:30 <br /> pm
                                  </th>
                                  <th scope="col">
                                    02:00 <br /> pm
                                  </th>
                                  <th scope="col">
                                    02:30 <br /> pm
                                  </th>
                                  <th scope="col">
                                    03:00 <br /> pm
                                  </th>
                                  <th scope="col">
                                    03:30 <br /> pm
                                  </th>
                                  <th scope="col">
                                    04:00 <br /> pm
                                  </th>
                                  <th scope="col">
                                    04:30 <br /> pm
                                  </th>
                                  <th scope="col">
                                    05:00 <br /> pm
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                {this.state.allCounsellor?.[0] &&
                                  this.state.allCounsellor.map((c, i) => (
                                    <tr key={i}>
                                      <th
                                        scope="row"
                                        data-toggle="tooltip"
                                        data-placement="left"
                                        title={c.firstName + " " + c.lastName}
                                      >
                                        {c.firstName.slice(0, 1) +
                                          "" +
                                          c.lastName.slice(0, 1)}
                                      </th>
                                      <td>
                                        {/* {this.state.currentDepartmentName ===
                                        "IT Department" ? (
                                          <select
                                            value={c.counsellorType}
                                            onChange={(e) => {
                                              this.updateCounsellorType(
                                                e,
                                                c._id
                                              );
                                            }}
                                          >
                                            {counsellorTypesOptions.map(
                                              (counsellorTypeItem, index) => (
                                                <option
                                                  key={index}
                                                  value={counsellorTypeItem}
                                                >
                                                  {counsellorTypeItem}
                                                </option>
                                              )
                                            )}
                                          </select>
                                        ) : (
                                          <span>{c.counsellorType}</span>
                                        )} */}
                                        <span>{c.counsellorType}</span>
                                      </td>
                                      {this.timing.map((time, timeIndex) => {
                                        let temp = (
                                          <td
                                            className="availabilityBox"
                                            key={
                                              c._id +
                                              c.counsellorType +
                                              "-" +
                                              time
                                            }
                                            style={{
                                              display:
                                                time === "12:00" && "none",
                                            }}
                                          >
                                            {this.renderInput(
                                              c._id,
                                              time,
                                              c.counsellorType,
                                              false,
                                              false
                                            )}
                                            <div
                                              className="form-control feedCounsellerDetails cursor-pointer"
                                              id="time-"
                                              style={{
                                                border: "none",
                                                backgroundColor: "#e9ecef",
                                                color: "white",
                                                height: "5em",
                                              }}
                                              onClick={() => {
                                                this.toggleDetails();
                                                const addedDate = new Date(
                                                  moment(
                                                    this.state.date
                                                  ).format("MM-DD-YYYY")
                                                );
                                                this.addBookSlot({
                                                  name: this.state.currentUser
                                                    .firstName,
                                                  date: addedDate,
                                                  time: time,
                                                  counsellorId: c._id,
                                                  status: 1,
                                                  currentUserId:
                                                    this.state?.currentUser?.id,
                                                });
                                                this.setState({
                                                  counsellorTime: time,
                                                  counsellorId: c._id,
                                                  selectedAvalibilityId:
                                                    c.availabilityData[0]?._id,
                                                  isGreySlot: true,
                                                  availabilityLocation:
                                                    c.counsellorType,
                                                });
                                              }}
                                            ></div>
                                          </td>
                                        );
                                        if (this.state.isHoliday) {
                                          temp = (
                                            <td
                                              className="availabilityBox"
                                              key={
                                                c._id +
                                                c.counsellorType +
                                                "-" +
                                                time
                                              }
                                              style={{
                                                display:
                                                  time === "12:00" && "none",
                                              }}
                                            >
                                              <div
                                                className="form-control feedCounsellerDetails"
                                                id="time-"
                                                style={{
                                                  border: "none",
                                                  backgroundColor: "#dc3545",
                                                  color: "white",
                                                  height: "5em",
                                                }}
                                              ></div>
                                            </td>
                                          );
                                        } else if (c.availabilityData.length) {
                                          let availability =
                                            c.availabilityData[0];
                                          if (
                                            availability.availability.length
                                          ) {
                                            availability.availability.forEach(
                                              (elmt) => {
                                                if (elmt.time === time) {
                                                  temp = (
                                                    <td
                                                      className={classNames(
                                                        "availabilityBox",
                                                        {
                                                          "cursor-pointer":
                                                            !elmt.isNA,
                                                        }
                                                      )}
                                                      key={
                                                        c._id +
                                                        c.counsellorType +
                                                        "-" +
                                                        time
                                                      }
                                                    >
                                                      {elmt?.bookingId ? (
                                                        <div>
                                                          <input
                                                            type="text"
                                                            id="callStatus"
                                                            name="callStatus"
                                                            maxlength="10"
                                                            value={
                                                              elmt.callStatus ??
                                                              ""
                                                            }
                                                            className="inputTextField"
                                                            onChange={(e) => {
                                                              this.setState(
                                                                {
                                                                  callStatus:
                                                                    e.target
                                                                      .value,
                                                                },
                                                                () => {
                                                                  this.updateBookingCallStatus(
                                                                    c
                                                                      ?.availabilityData[0]
                                                                      ?._id,
                                                                    elmt?.bookingId,
                                                                    elmt?._id
                                                                  );
                                                                }
                                                              );
                                                            }}
                                                          />
                                                        </div>
                                                      ) : (
                                                        ""
                                                      )}

                                                      {this.renderInput(
                                                        c._id,
                                                        time,
                                                        c.counsellorType,
                                                        elmt.isNA
                                                          ? false
                                                          : true,
                                                        elmt.isNA
                                                      )}
                                                      <div
                                                        onClick={() => {
                                                          if (
                                                            elmt?.slotType !==
                                                            "Holding"
                                                          ) {
                                                            if (
                                                              elmt.bookingId
                                                            ) {
                                                              this.toggleBookingInfoPopup(
                                                                elmt.bookingId
                                                              );
                                                              this.addSlotTypeByBookingId(
                                                                {
                                                                  bookingId:
                                                                    elmt?.bookingId,
                                                                }
                                                              );
                                                              //this.toggleCancelBooking();
                                                              this.setState({
                                                                counsellorTime:
                                                                  time,
                                                                counsellorId:
                                                                  c._id,
                                                                selectedAvalibilityId:
                                                                  availability?._id,
                                                                clickedItem:
                                                                  elmt,
                                                                isGreySlot:
                                                                  elmt.isGreySlot,
                                                                availabilityLocation:
                                                                  c.counsellorType,
                                                              });
                                                            } else {
                                                              if (!elmt.isNA) {
                                                                this.toggleDetails();
                                                                this.addBookSlot(
                                                                  {
                                                                    name: this
                                                                      .state
                                                                      .currentUser
                                                                      .firstName,
                                                                    date: this
                                                                      .state
                                                                      .date,
                                                                    time: time,
                                                                    counsellorId:
                                                                      c._id,
                                                                    status: 1,
                                                                    currentUserId:
                                                                      this.state
                                                                        ?.currentUser
                                                                        ?.id,
                                                                  }
                                                                );
                                                                this.setState({
                                                                  counsellorTime:
                                                                    time,
                                                                  counsellorId:
                                                                    c._id,
                                                                  selectedAvalibilityId:
                                                                    availability._id,
                                                                  availabilityLocation:
                                                                    c.counsellorType,
                                                                });
                                                              }
                                                            }
                                                          }
                                                        }}
                                                        className="form-control feedCounsellerDetails"
                                                        id={`time-${i}`}
                                                        style={{
                                                          border: "none",
                                                          backgroundColor:
                                                            this.getSlotColor(
                                                              elmt
                                                            ),
                                                          color: "white",
                                                          height: "5em",
                                                        }}
                                                      >
                                                        {elmt?.slotType ===
                                                        "Holding" ? (
                                                          <div>
                                                            {elmt?.name ===
                                                              null ||
                                                            !elmt?.name
                                                              ? "Someone"
                                                              : elmt?.name}{" "}
                                                            is booking this slot
                                                          </div>
                                                        ) : (
                                                          <>
                                                            <div className="slotDetails">
                                                              {elmt.codeStatus}-
                                                              {elmt.name}
                                                            </div>
                                                            <div>
                                                              {elmt.phoneNumber}
                                                            </div>
                                                            <div className="slotDetails">
                                                              <a
                                                                href={`mailto:${elmt?.email}`}
                                                                className="counsellorAvailabilityEmail"
                                                              >
                                                                {elmt?.email}
                                                              </a>
                                                            </div>
                                                          </>
                                                        )}
                                                      </div>
                                                    </td>
                                                  );
                                                }
                                              }
                                            );
                                          }
                                        }

                                        return temp;
                                      })}
                                    </tr>
                                  ))}
                              </tbody>
                            </table>
                          </div>
                        ) : (
                          <div
                            className="card"
                            style={{ width: "40%", margin: "5rem auto" }}
                          >
                            <div className="card-body d-flex border justify-content-center">
                              <div className="m-l-10 align-self-center dashboard_cardTitle">
                                <h1 className="card-title p-3 mb-1">
                                  Holiday on{" "}
                                  {moment(this.state?.date).format(
                                    "MM/DD/YYYY"
                                  ) || ""}
                                </h1>
                              </div>
                            </div>
                          </div>
                        )}
                      </div>

                      <div>
                        <Modal
                          isOpen={this.state.cancelModal}
                          centered
                          toggle={() => {
                            this.toggleDetails();
                          }}
                        >
                          <ModalBody>
                            Do you want to close without saving?
                            <Button
                              className="disableColor m-3"
                              onClick={() => {
                                this.toggleDetails();
                                this.setState({
                                  cancelModal: !this.state.cancelModal,
                                });
                                this.deleteSelectedSlot({
                                  counsellorId: this.state.counsellorId,
                                });
                                this.props.socket.emit(
                                  "leaving_hold_appointment",
                                  { counsellorId: this.state.counsellorId }
                                );
                              }}
                              color="danger"
                            >
                              Yes
                            </Button>
                            <Button
                              className="disableColor"
                              onClick={() => {
                                this.setState({
                                  cancelModal: !this.state.cancelModal,
                                });
                              }}
                              color="primary"
                            >
                              No
                            </Button>
                          </ModalBody>
                        </Modal>
                        {!this.state.showErrorMessage ? (
                          <Modal
                            isOpen={this.state.userModal}
                            centered
                            toggle={() => {
                              this.toggleDetails();
                              this.setState({
                                cancelModal: !this.state.cancelModal,
                              });
                            }}
                          >
                            <ModalHeader
                              toggle={() => {
                                this.setState({
                                  cancelModal: !this.state.cancelModal,
                                });
                              }}
                            >
                              Schedule Appointment
                            </ModalHeader>
                            <ModalBody>
                              <Nav tabs>
                                <NavItem>
                                  <NavLink
                                    className={
                                      this.state.activeTab === "1"
                                        ? "active"
                                        : ""
                                    }
                                    onClick={() => {
                                      this.toggleTab("1");
                                    }}
                                  >
                                    Registered User
                                  </NavLink>
                                </NavItem>
                                <NavItem>
                                  <NavLink
                                    className={
                                      this.state.activeTab === "2"
                                        ? "active"
                                        : ""
                                    }
                                    onClick={() => {
                                      this.toggleTab("2");
                                    }}
                                  >
                                    Call In/Walk In
                                  </NavLink>
                                </NavItem>
                              </Nav>
                              <TabContent activeTab={this.state.activeTab}>
                                <TabPane tabId="1">
                                  <div className="d-flex row mt-2 mb-3">
                                    <div className="mr-2 col-3">
                                      <strong>
                                        Code:{" "}
                                        <span style={{ color: "red" }}>*</span>
                                      </strong>
                                      <Select
                                        required
                                        value={this.state.selectedCode}
                                        className="mt-1"
                                        onChange={(e) => {
                                          this.setState({
                                            selectedCode: e,
                                          });
                                        }}
                                        options={codeOptions}
                                        placeholder="Code"
                                      />
                                    </div>
                                    <div className="mr-2 col-8">
                                      <strong>Select User :</strong>
                                      <Select
                                        required
                                        className="mt-1"
                                        placeholder="Select User"
                                        onChange={(e) => {
                                          let res = {
                                            label: e.value.reason.includes(
                                              "Employment-Services"
                                            )
                                              ? "ES"
                                              : "YJC",
                                            value: e.value.reason,
                                          };
                                          this.setState({
                                            selectedUser: e,
                                            selectedCode: res,
                                          });
                                        }}
                                        // value={this.state.selectedUser}
                                        options={this.state.allUesrs?.map(
                                          (member, index) => ({
                                            label: member.name,
                                            value: member,
                                          })
                                        )}
                                      />
                                    </div>
                                  </div>
                                  {this.state.selectedUser && (
                                    <React.Fragment>
                                      <div className="mb-3">
                                        <strong>Reason :</strong>{" "}
                                        {this.state.selectedUser.value.reason
                                          .map((item) => {
                                            return programType[item];
                                          })
                                          .join(", ")}
                                      </div>

                                      <div className="mb-3">
                                        <strong>PhoneNumber :</strong>{" "}
                                        {
                                          this.state.selectedUser.value
                                            .phoneNumber
                                        }
                                        {!this.state.selectedUser.value.preferredMethodOfContact.includes(
                                          "text"
                                        ) &&
                                          this.state.selectedUser.value.preferredMethodOfContact?.includes(
                                            "phone"
                                          ) && (
                                            <span
                                              style={{
                                                color: "blue",
                                              }}
                                            >
                                              {" "}
                                              (Preferred: phone)
                                            </span>
                                          )}
                                        {!this.state.selectedUser.value.preferredMethodOfContact.includes(
                                          "phone"
                                        ) &&
                                          this.state.selectedUser.value.preferredMethodOfContact?.includes(
                                            "text"
                                          ) && (
                                            <span
                                              style={{
                                                color: "blue",
                                              }}
                                            >
                                              {" "}
                                              (Preferred: text){" "}
                                            </span>
                                          )}
                                        {this.state.selectedUser.value.preferredMethodOfContact?.includes(
                                          "phone"
                                        ) &&
                                          this.state.selectedUser.value.preferredMethodOfContact?.includes(
                                            "text"
                                          ) && (
                                            <span
                                              style={{
                                                color: "blue",
                                              }}
                                            >
                                              {" "}
                                              (Preferred: phone & text)
                                            </span>
                                          )}
                                      </div>
                                      <div className="mb-3">
                                        <strong>Email :</strong>{" "}
                                        {this.state.selectedUser.value.email}
                                        {this.state.selectedUser.value.preferredMethodOfContact?.includes(
                                          "email"
                                        ) && (
                                          <span
                                            style={{
                                              color: "blue",
                                            }}
                                          >
                                            {" "}
                                            (Preferred)
                                          </span>
                                        )}
                                      </div>
                                      <div className="mb-3">
                                        <strong>Preferred Day :</strong>
                                        {this.state.selectedUser.value.preferredDay.map(
                                          (day, ind) => (
                                            <span key={ind}>
                                              {" " + capitalize(day) + " "}
                                            </span>
                                          )
                                        )}
                                      </div>
                                      <div className="mb-3">
                                        <strong>Preferred Time :</strong>
                                        {this.state.selectedUser.value.preferredTime.map(
                                          (t, ind) => (
                                            <span key={ind}>
                                              {" " + capitalize(t) + " "}
                                            </span>
                                          )
                                        )}
                                      </div>
                                      <div className="row mb-3">
                                        <div className="col-4">
                                          <strong>Status :{""}</strong>
                                          <Select
                                            className="mt-1"
                                            placeholder="Status"
                                            onChange={(e) => {
                                              this.setState({
                                                bookingStatus: e,
                                              });
                                            }}
                                            options={[
                                              {
                                                value: "WNI",
                                                label: "WNI",
                                              },
                                              {
                                                value: "WI",
                                                label: "WI",
                                              },
                                              {
                                                value: "I",
                                                label: "I",
                                              },
                                              {
                                                value: "NI",
                                                label: "NI",
                                              },
                                              {
                                                value: "IJO",
                                                label: "IJO",
                                              },
                                              {
                                                value: "BJO",
                                                label: "BJO",
                                              },
                                              {
                                                value: "IDJO",
                                                label: "IDJO",
                                              },
                                            ]}
                                          />
                                        </div>
                                        <div className="col-4">
                                          <strong>Attended:</strong>
                                          <Select
                                            className="mt-1"
                                            placeholder="Attended"
                                            onChange={(e) => {
                                              this.setState({
                                                toAttend: e,
                                              });
                                            }}
                                            options={[
                                              {
                                                value: "Yes",
                                                label: "Yes",
                                              },
                                              {
                                                value: "No",
                                                label: "No",
                                              },
                                            ]}
                                          />
                                        </div>
                                        <div className="col-4">
                                          <strong>Appoinment Type:{""}</strong>
                                          <Select
                                            className="mt-1"
                                            placeholder="Appointment"
                                            onChange={(e) => {
                                              this.setState({
                                                appointmentType: e,
                                              });
                                            }}
                                            options={[
                                              {
                                                value: "virtual",
                                                label: "Virtual",
                                              },
                                              {
                                                value: "in person",
                                                label: "In person",
                                              },
                                            ]}
                                          />
                                        </div>
                                        <div className="mt-4">
                                          <Editor
                                            style={{
                                              height: "120px",
                                            }}
                                            value={this.state.counsellorNote}
                                            onTextChange={(e) =>
                                              this.setState({
                                                counsellorNote: e.htmlValue,
                                              })
                                            }
                                          />
                                        </div>
                                      </div>
                                      {/* <div className="mb-3">
                                                            <strong>Counsellers Note :</strong>
                                                            <div className="mt-1">
                                                                <Editor style={{height:'120px'}} value={this.state.note} onTextChange={(e) => this.setState({note: e.htmlValue})} />
                                                            </div>
                                                        </div> */}
                                    </React.Fragment>
                                  )}
                                </TabPane>
                                <TabPane tabId="2">
                                  <div className="d-flex row mt-2 mb-3">
                                    <div className="mr-2 col-3">
                                      <strong>Code: {""}</strong>
                                      <Select
                                        required
                                        value={this.state.selectedCode}
                                        className="mt-1"
                                        onChange={(e) => {
                                          this.setState({
                                            selectedCode: e,
                                          });
                                        }}
                                        options={codeOptions}
                                        placeholder="Code"
                                      />
                                    </div>
                                    <div className="mr-2 col-8">
                                      <strong>User Name:{""}</strong>
                                      <input
                                        required
                                        name="userName"
                                        className="mt-1 form-control"
                                        value={this.state.userName}
                                        onChange={this.handleOnChange}
                                      />
                                    </div>
                                  </div>

                                  <div className="mb-3">
                                    <strong>Phone Number :{""}</strong>
                                    {/* <input name="phoneNumber" className="mt-1 form-control" value={this.state.phoneNumber} onChange={this.handleOnChange}/> */}
                                    <MaskedInput
                                      mask={[
                                        "(",
                                        /[1-9]/,
                                        /\d/,
                                        /\d/,
                                        ")",
                                        " ",
                                        /\d/,
                                        /\d/,
                                        /\d/,
                                        "-",
                                        /\d/,
                                        /\d/,
                                        /\d/,
                                        /\d/,
                                      ]}
                                      name="phoneNumber"
                                      id="phoneNumber"
                                      onChange={this.handleOnChange}
                                      className={"mt-1 form-control"}
                                      placeholder="Phone Number"
                                      guide={false}
                                      type="text"
                                      value={this.state.phoneNumber}
                                      render={(ref, props) => (
                                        <input
                                          required
                                          type="text"
                                          label="Phone Number"
                                          name="phoneNumber"
                                          ref={ref}
                                          value={this.state.phoneNumber}
                                          {...props}
                                        />
                                      )}
                                    />
                                  </div>
                                  <div className="mb-3">
                                    <strong>Email :</strong>
                                    <input
                                      required
                                      name="email"
                                      className="mt-1 form-control"
                                      value={this.state.email}
                                      onChange={this.handleOnChange}
                                    />
                                  </div>

                                  <div className="row mb-3">
                                    <div className="col-4">
                                      <strong>Status :{""}</strong>
                                      <Select
                                        className="mt-1"
                                        placeholder="Status"
                                        onChange={(e) => {
                                          this.setState({
                                            bookingStatus: e,
                                          });
                                        }}
                                        options={[
                                          {
                                            value: "WNI",
                                            label: "WNI",
                                          },
                                          {
                                            value: "WI",
                                            label: "WI",
                                          },
                                          {
                                            value: "I",
                                            label: "I",
                                          },
                                          {
                                            value: "NI",
                                            label: "NI",
                                          },
                                          {
                                            value: "IJO",
                                            label: "IJO",
                                          },
                                          {
                                            value: "BJO",
                                            label: "BJO",
                                          },
                                          {
                                            value: "IDJO",
                                            label: "IDJO",
                                          },
                                        ]}
                                      />
                                    </div>
                                    <div className="col-4 pl-0">
                                      <strong>Attended:</strong>
                                      <Select
                                        className="mt-1"
                                        placeholder="Attended"
                                        onChange={(e) => {
                                          this.setState({
                                            toAttend: e,
                                          });
                                        }}
                                        options={[
                                          {
                                            value: "Yes",
                                            label: "Yes",
                                          },
                                          {
                                            value: "No",
                                            label: "No",
                                          },
                                        ]}
                                      />
                                    </div>
                                    <div className="col-4 pl-0">
                                      <strong>Appointment Type:{""}</strong>
                                      <Select
                                        className="mt-1"
                                        placeholder="Appointment"
                                        onChange={(e) => {
                                          this.setState({
                                            appointmentType: e,
                                          });
                                        }}
                                        options={[
                                          {
                                            value: "virtual",
                                            label: "Virtual",
                                          },
                                          {
                                            value: "in person",
                                            label: "In person",
                                          },
                                        ]}
                                      />
                                    </div>
                                    <div className="mt-4">
                                      <Editor
                                        style={{
                                          height: "120px",
                                        }}
                                        value={this.state.counsellorNote}
                                        onTextChange={(e) =>
                                          this.setState({
                                            counsellorNote: e.htmlValue,
                                          })
                                        }
                                      />
                                    </div>
                                  </div>
                                </TabPane>
                              </TabContent>
                            </ModalBody>
                            <ModalFooter>
                              {this.state.activeTab === "1" ? (
                                <Button
                                  className="disableColor"
                                  onClick={() => {
                                    this.appointCounsellor();
                                    // this.deleteSelectedSlot({
                                    //   counsellorId: this.state.counsellorId,
                                    // });
                                  }}
                                  color="primary"
                                  disabled={!this.state.selectedUser}
                                >
                                  Save
                                </Button>
                              ) : (
                                <Button
                                  className="disableColor"
                                  onClick={() => {
                                    this.appointWalkin();
                                    // this.deleteSelectedSlot({
                                    //   counsellorId: this.state.counsellorId,
                                    // });
                                    this.props.socket.emit(
                                      "leaving_hold_appointment",
                                      { counsellorId: this.state.counsellorId }
                                    );
                                  }}
                                  color="primary"
                                  // disabled={!this.state.userName || !this.state.selectedCode || !this.state.phoneNumber || !this.state.bookingStatus || !this.state.appointmentType}
                                >
                                  Save
                                </Button>
                              )}
                            </ModalFooter>
                          </Modal>
                        ) : (
                          <Modal isOpen={this.state.userModal} centered>
                            <ModalHeader>Conflict Occured</ModalHeader>
                            <ModalBody>{this.state.conflictError}</ModalBody>
                            <ModalFooter>
                              <Button
                                className="disableColor"
                                onClick={() => {
                                  this.setState({
                                    userModal: !this.state.userModal,
                                    showErrorMessage:
                                      !this.state.showErrorMessage,
                                  });
                                  this.getCounsellorsWithAvailabilies(
                                    this.state.date
                                  );
                                }}
                                color="primary"
                              >
                                Close
                              </Button>
                            </ModalFooter>
                          </Modal>
                        )}
                      </div>
                      <div>
                        <Modal
                          isOpen={this.state.cancelBookingPopup}
                          centered
                          toggle={this.toggleCancelBooking}
                        >
                          <ModalHeader toggle={this.toggleCancelBooking}>
                            User Details
                          </ModalHeader>
                          <ModalBody>
                            <h4>
                              Are you sure you want to cancel the assigned
                              booking?
                            </h4>
                          </ModalBody>
                          <ModalFooter>
                            <Button
                              className="disableColor"
                              onClick={this.toggleCancelBooking}
                              color="secondary"
                            >
                              No
                            </Button>
                            <Button
                              className="disableColor"
                              onClick={this.cancelBooking}
                              color="primary"
                            >
                              Yes
                            </Button>
                          </ModalFooter>
                        </Modal>
                      </div>
                      <div>
                        <Modal
                          isOpen={this.state.bookingInfoPopup}
                          centered
                          toggle={this.toggleBookingInfoPopup}
                        >
                          <ModalHeader toggle={this.toggleBookingInfoPopup}>
                            User Details
                          </ModalHeader>
                          <ModalBody>
                            <div className="mb-3">
                              <strong>UserName :</strong>{" "}
                              <input
                                name="userName"
                                className="mt-1 form-control"
                                value={this.state.userName}
                                onChange={this.handleOnChange}
                              />
                            </div>
                            <div className="mb-3">
                              <strong>Code :</strong>{" "}
                              <Select
                                value={this.state.selectedCode}
                                className="mt-1"
                                onChange={(e) => {
                                  this.setState({
                                    selectedCode: e,
                                  });
                                }}
                                options={codeOptions}
                                placeholder="Code"
                              />
                            </div>
                            <div className="mb-3">
                              <strong>Phone Number :</strong>{" "}
                              <MaskedInput
                                mask={[
                                  "(",
                                  /[1-9]/,
                                  /\d/,
                                  /\d/,
                                  ")",
                                  " ",
                                  /\d/,
                                  /\d/,
                                  /\d/,
                                  "-",
                                  /\d/,
                                  /\d/,
                                  /\d/,
                                  /\d/,
                                ]}
                                name="phoneNumber"
                                id="phoneNumber"
                                onChange={this.handleOnChange}
                                className={"mt-1 form-control"}
                                placeholder="Phone Number"
                                guide={false}
                                type="text"
                                value={this.state.phoneNumber}
                                render={(ref, props) => (
                                  <input
                                    type="text"
                                    label="Phone Number"
                                    name="phoneNumber"
                                    ref={ref}
                                    value={this.state.phoneNumber}
                                    {...props}
                                  />
                                )}
                              />
                            </div>
                            <div className="mb-3">
                              <strong>Email :</strong>{" "}
                              <input
                                name="email"
                                className="mt-1 form-control"
                                value={this.state.email}
                                onChange={this.handleOnChange}
                              />
                            </div>
                            <strong>Counsellor :</strong>{" "}
                            <div className="d-flex row mb-3">
                              <div className="mr-2 col-4">
                                <Select
                                  className="mt-1"
                                  placeholder="Counsellor"
                                  value={this.getCounsellor(
                                    this.state.counsellorId
                                  )}
                                  onChange={(e) => {
                                    const time =
                                      e.value ===
                                        this.state?.bookingData?.counsellorId &&
                                      moment(this.state.date).format(
                                        "MM/DD/YYYY"
                                      ) === this.state?.bookingData?.date
                                        ? this.state.bookingData?.time
                                        : "";
                                    this.setState({
                                      counsellorTime: time,
                                      counsellorId: e.value,
                                    });
                                    this.getTimeSlotWithCounsellorAvailablities(
                                      {
                                        date: this.state.date,
                                        id: e.value,
                                        availabilityLocation:
                                          this.state?.availabilityLocation,
                                      }
                                    );
                                  }}
                                  options={this.state?.allCounsellor?.map(
                                    (item) => ({
                                      label:
                                        item?.firstName && item?.lastName
                                          ? item.firstName.slice(0, 1) +
                                            "" +
                                            item.lastName.slice(0, 1)
                                          : "Counsellor",
                                      value: item?._id,
                                    })
                                  )}
                                />
                              </div>
                            </div>
                            <strong>Date :</strong>{" "}
                            <div className="d-flex row mb-3">
                              <div className="mr-2 col-4">
                                <Calendar
                                  id="calenderElem"
                                  className="with-holiday "
                                  dateFormat="dd, M yy, DD"
                                  // selectionMode="multiple"
                                  value={this.state.date}
                                  showIcon={true}
                                  onChange={(e) => {
                                    const time =
                                      moment(e.value).format("MM/DD/YYYY") ===
                                        this.state?.bookingData?.date &&
                                      this.state?.counsellorId ===
                                        this.state?.bookingData?.counsellorId
                                        ? this.state.bookingData?.time
                                        : "";
                                    this.setState({
                                      date: e.value,
                                      counsellorTime: time,
                                    });
                                    this.getTimeSlotWithCounsellorAvailablities(
                                      {
                                        date: e.value,
                                        id: this.state.counsellorId,
                                        availabilityLocation:
                                          this.state?.availabilityLocation,
                                      }
                                    );
                                  }}
                                  disabledDates={holidayDateList(
                                    this.props.holidayList
                                  )}
                                  readOnlyInput
                                ></Calendar>
                              </div>
                            </div>
                            <strong>Time Slot :</strong>{" "}
                            <div className="d-flex row mb-3">
                              <div className="mr-2 col-4">
                                <Select
                                  className="mt-1"
                                  placeholder="Time Slot"
                                  value={{ label: this.state.counsellorTime }}
                                  onChange={(e) => {
                                    this.setState({
                                      counsellorTime: e.label,
                                    });
                                  }}
                                  options={this.state.freeSlots.map((label) => {
                                    return { label: label, value: label };
                                  })}
                                />
                              </div>
                            </div>
                            <strong>Status :</strong>{" "}
                            <div className="d-flex row mb-3">
                              <div className="mr-2 col-4">
                                <Select
                                  className="mt-1"
                                  placeholder="Status"
                                  value={this.state.bookingStatus}
                                  onChange={(e) => {
                                    this.setState({
                                      bookingStatus: e,
                                    });
                                  }}
                                  options={[
                                    {
                                      value: "WNI",
                                      label: "WNI",
                                    },
                                    {
                                      value: "WI",
                                      label: "WI",
                                    },
                                    {
                                      value: "I",
                                      label: "I",
                                    },
                                    {
                                      value: "NI",
                                      label: "NI",
                                    },
                                    {
                                      value: "IJO",
                                      label: "IJO",
                                    },
                                    {
                                      value: "BJO",
                                      label: "BJO",
                                    },
                                    {
                                      value: "IDJO",
                                      label: "IDJO",
                                    },
                                  ]}
                                />
                              </div>
                            </div>
                            <strong>Attended :</strong>{" "}
                            <div className="d-flex row mb-3">
                              <div className="mr-2 col-4">
                                <Select
                                  className="mt-1"
                                  placeholder="Attended"
                                  value={this.state.toAttend}
                                  onChange={(e) => {
                                    this.setState({
                                      toAttend: e,
                                    });
                                  }}
                                  options={[
                                    {
                                      value: "Yes",
                                      label: "Yes",
                                    },
                                    {
                                      value: "No",
                                      label: "No",
                                    },
                                  ]}
                                />
                              </div>
                            </div>
                            <div className="d-flex row mb-3">
                              <div className="mr-2 col-4">
                                <strong>Appoinment Type :</strong>
                                <Select
                                  className="mt-1"
                                  placeholder="Appointment"
                                  value={this.state.appointmentType}
                                  onChange={(e) => {
                                    this.setState({
                                      appointmentType: e,
                                    });
                                  }}
                                  options={[
                                    {
                                      value: "virtual",
                                      label: "Virtual",
                                    },
                                    {
                                      value: "in person",
                                      label: "In person",
                                    },
                                  ]}
                                />
                              </div>
                            </div>
                            <div className="mb-3">
                              <strong>Note :</strong>
                              <div className="mt-1">
                                <Editor
                                  style={{
                                    height: "120px",
                                  }}
                                  value={this.state.counsellorNote}
                                  onTextChange={(e) =>
                                    this.setState({
                                      counsellorNote: e.htmlValue,
                                    })
                                  }
                                />
                              </div>
                            </div>
                          </ModalBody>
                          <ModalFooter>
                            <Button
                              className="disableColor"
                              onClick={() => {
                                this.toggleCancelBooking();
                                const payload = {
                                  counsellorId: this.state.counsellorId,
                                  bookingId: this.state.bookingData._id,
                                };
                                this.props.socket.emit(
                                  "leave_partiallyBooked_appointment",
                                  payload
                                );
                                this.deleteSlotTypeByBookingId({
                                  bookingId: this.state.bookingData._id,
                                });
                              }}
                              color="secondary"
                            >
                              Cancel Booking
                            </Button>
                            <Button
                              className="disableColor"
                              onClick={() => {
                                this.updateBookingNotes();
                              }}
                              color="primary"
                            >
                              Save
                            </Button>
                            <Button
                              className="disableColor"
                              onClick={() => {
                                this.toggleBookingInfoPopup();
                                const payload = {
                                  counsellorId: this.state.counsellorId,
                                  bookingId: this.state.bookingData._id,
                                };
                                this.deleteSlotTypeByBookingId({
                                  bookingId: this.state.bookingData._id,
                                });
                                this.props.socket.emit(
                                  "leave_partiallyBooked_appointment",
                                  payload
                                );
                              }}
                              color="primary"
                            >
                              close
                            </Button>
                          </ModalFooter>
                        </Modal>
                      </div>
                      <div>
                        <Modal
                          isOpen={this.state.repeatQuaterlyModal}
                          centered
                          toggle={this.toggleRepeatQuaterlyModal}
                        >
                          <ModalHeader toggle={this.toggleRepeatQuaterlyModal}>
                            User Details
                          </ModalHeader>
                          <ModalBody>
                            <h4>
                              Do you want to repeat the availability for the
                              next quarter
                            </h4>
                          </ModalBody>
                          <ModalFooter>
                            <Button
                              className="disableColor"
                              onClick={this.addAvailability}
                              color="secondary"
                            >
                              No
                            </Button>
                            <Button
                              className="disableColor"
                              onClick={() =>
                                this.addAvailability({
                                  repeatQuaterly: true,
                                })
                              }
                              color="primary"
                            >
                              Yes
                            </Button>
                          </ModalFooter>
                        </Modal>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <FooterAdmin />
        </div>
      </div>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CounsellorAvailability);
