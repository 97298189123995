import React, { Component } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { contentLibraryService } from "../../_services/staff";
import { analyticsService } from "../../_services/admin";

class ResourceFileList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      fileList: [],
      expandedRows: null,
    };
  }

  componentDidMount() {
    contentLibraryService
      .getFilesByCategoryId(this.props.categoryId)
      .then((data) => {
        data && data.Data && this.setState({ fileList: data.Data });
      });
  }

  rowExpansionTemplate(data) {
    return (
      <div className="resource-files-subtable">
        <h5>
          <strong>User Stats for {data.title}</strong>
        </h5>
        <FileStats fileId={data._id} />
        <h5></h5>
      </div>
    );
  }

  render() {
    return (
      <DataTable
        value={this.state.fileList}
        expandedRows={this.state.expandedRows}
        onRowToggle={(e) => this.setState({ expandedRows: e.data })}
        rowExpansionTemplate={this.rowExpansionTemplate}
        dataKey="_id"
        paginator
        paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
        rows={10}
        rowsPerPageOptions={[10, 20, 50, 100]}
      >
        <Column
          headerClassName="row-accessories"
          expander
          style={{ width: "3em" }}
        />
        <Column
          headerClassName="row-accessories"
          field="title"
          header="Title"
          sortable
        ></Column>
        <Column
          headerClassName="row-accessories"
          field="ratings"
          header="Avg. Ratings"
          sortable
        ></Column>
        <Column
          headerClassName="row-accessories"
          field="reviews"
          header="Reviews"
          sortable
        ></Column>
      </DataTable>
    );
  }
}

class FileStats extends Component {
  constructor(props) {
    super(props);

    this.state = {
      viewsList: [],
    };
  }

  componentDidMount() {
    analyticsService.getResourceFileViews(this.props.fileId).then((data) => {
      data && data.Data && this.setState({ viewsList: data.Data });
    });
  }

  render() {
    return (
      <DataTable
        value={this.state.viewsList}
        paginator
        paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
        rows={10}
        rowsPerPageOptions={[10, 20, 50, 100]}
      >
        <Column
          headerClassName="user_states"
          field="_id.userName"
          header="User Name"
          sortable
        ></Column>
        <Column
          headerClassName="user_states"
          field="dur"
          header="Duration"
          sortable
        ></Column>
        <Column
          headerClassName="user_states"
          field="rating"
          header="Rating"
          sortable
        ></Column>
        <Column
          headerClassName="user_states"
          field="comment"
          header="Comment"
          sortable
        ></Column>
      </DataTable>
    );
  }
}

export class ResourceLibraryStats extends Component {
  constructor(props) {
    super(props);

    this.state = {
      expandedRows: null,
    };
  }

  resourceLibraryRowToggle = (e) => {
    console.log("/////////////  e: ", e);
    this.setState({ expandedRows: e.data });
  };

  rowExpansionTemplate(data) {
    return (
      <div className="resource-files-subtable">
        <h5>
          <strong>Files for {data.name}</strong>
        </h5>
        <ResourceFileList categoryId={data._id} />
        <h5></h5>
      </div>
    );
  }

  render() {
    return (
      <DataTable
        value={this.props.data}
        paginator
        expandedRows={this.state.expandedRows}
        onRowToggle={this.resourceLibraryRowToggle}
        rowExpansionTemplate={this.rowExpansionTemplate}
        dataKey="_id"
        paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
        rows={10}
        rowsPerPageOptions={[10, 20, 50, 100]}
      >
        <Column
          headerClassName="main_header"
          expander
          style={{ width: "3em" }}
        />
        <Column
          headerClassName="main_header"
          field="name"
          header="Name"
          sortable
        />
        <Column
          headerClassName="main_header"
          header="Views"
          field="pageViews"
          sortable
        />
      </DataTable>
    );
  }
}

export default ResourceLibraryStats;
