import React, { Component } from "react";
import { connect } from "react-redux";
import Preloader from "../../components/Preloader";
import HeaderAdmin from "../../components/HeaderAdmin";
import SidebarSuperAdmin from "../../components/admin/Sidebaar";
import FooterAdmin from "../../components/FooterAdmin";
import { Link } from "react-router-dom";
import { authenticationService } from "../../_services";
import { setUserData } from "../../redux-store/action";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import * as am4maps from "@amcharts/amcharts4/maps";
import am4geodata_worldLow from "@amcharts/amcharts4-geodata/worldLow";
import { Calendar } from "primereact/calendar";
import { Dropdown } from "primereact/dropdown";
import { analyticsService } from "../../_services/admin/analytics.service";
import { programType } from "../../_helpers";
import { getLastFiveYearsOptionList } from "../../_helpers/_helperFunctions";
import moment from "moment";
am4core.useTheme(am4themes_animated);

function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {
    setUserData: (data) => {
      dispatch(setUserData(data));
    },
  };
}

class Analytics extends Component {
  constructor(props) {
    super(props);

    this.state = {
      currentUser: authenticationService.currentUserValue,
      date: null,
      year: null,
      // loading: true
    };
  }
  TotalGender = 0;
  // chartData = [{ "gender": "Men", "0-18": 14, "18-35": 10, "35-99": 11, "total": 41 },
  // { "gender": "Women", "0-18": 3, "18-35": 9, "35-99": 1, "total": 14 },
  // { "gender": "Other", "0-18": 1, "18-35": 1, "35-99": 0, "total": 0 },
  // ]

  genderData = [
    { gender: "Men", total: 41 },
    { gender: "Women", total: 14 },

    { gender: "Other", total: 2 },
  ];

  componentDidMount() {
    this.props.setUserData(this.state.currentUser);
    // age Trend view.
    let colorSet = new am4core.ColorSet();
    analyticsService.ageTrendView().then((data) => {
      if (!Array.isArray(data.Data)) {
        data.Data = [data.Data];
      }
      let genderList = {
        Men: "male",
        Women: "female",
        Other: "other",
      };
      let keyMap = {
        "0-14": 0,
        "15-18": 1,
        "19-29": 2,
        "30-43": 3,
        "44-64": 4,
        "65+": 5,
      };

      let statsData = data.Data;
      console.log("statsData: ", statsData);
      let chartData = [];

      Object.keys(genderList).forEach((gender) => {
        chartData.push({
          gender: gender,
          "0-14": statsData[0] ? statsData[0][genderList[gender]] : 0,
          "15-18": statsData[1] ? statsData[1][genderList[gender]] : 0,
          "19-29": statsData[2] ? statsData[2][genderList[gender]] : 0,
          "30-43": statsData[3] ? statsData[3][genderList[gender]] : 0,
          "44-64": statsData[4] ? statsData[4][genderList[gender]] : 0,
          "65+": statsData[5] ? statsData[5][genderList[gender]] : 0,
          total: statsData.reduce(function (a, b) {
            return a + b[genderList[gender]];
          }, 0),
        });
      });

      let chart1 = am4core.create("chartdiv", am4charts.XYChart);

      chart1.data = chartData;

      // Create axes
      let categoryAxis1 = chart1.xAxes.push(new am4charts.CategoryAxis());
      categoryAxis1.dataFields.category = "gender";
      categoryAxis1.renderer.grid.template.location = 0;
      categoryAxis1.renderer.minGridDistance = 5;
      // categoryAxis1.renderer.labels.template.adapter.add("dy", function(dy, target) {
      //     if (target.dataItem && target.dataItem.index & 2 == 2) {
      //         return dy ;
      //     }
      //     return dy;
      // });

      let title1 = chart1.titles.create();
      title1.text = `Age Trend View`;
      title1.fontSize = 18;

      let valueAxis1 = chart1.yAxes.push(new am4charts.ValueAxis());
      // valueAxis1.renderer.inside = true;
      // valueAxis1.renderer.labels.template.disabled = true;
      valueAxis1.title.text = "Number of Records";

      valueAxis1.min = 0;

      // Create series
      const createSeries = (field, name, colorset) => {
        // Set up series
        let series1 = chart1.series.push(new am4charts.ColumnSeries());
        series1.name = name;
        series1.dataFields.valueY = field;
        series1.dataFields.categoryX = "gender";
        series1.sequencedInterpolation = true;
        series1.fill = am4core.color(colorset);
        // Make it stacked
        series1.stacked = true;

        // Configure columns
        series1.columns.template.width = am4core.percent(60);
        series1.columns.template.tooltipText =
          "[bold]{name}[/]\n[font-size:14px]{categoryX}: {valueY}";

        // Add label
        let labelBullet1 = series1.bullets.push(new am4charts.LabelBullet());
        labelBullet1.label.text = "{valueY}";
        labelBullet1.locationY = 0.5;
        labelBullet1.label.hideOversized = true;

        return series1;
      };

      createSeries("0-14", "0-14", colorSet.next());
      createSeries("15-18", "15-18", colorSet.next());
      createSeries("19-29", "19-29", colorSet.next());
      createSeries("30-43", "30-43", colorSet.next());
      createSeries("44-64", "44-64", colorSet.next());
      createSeries("65+", "65+", colorSet.next());

      // Legend
      chart1.legend = new am4charts.Legend();
      chart1.exporting.menu = new am4core.ExportMenu();
      //
      this.chart1 = chart1;
    });

    //

    analyticsService.genderVariationStats().then((data) => {
      let statsData = data.Data;
      let genderList = {
        Men: "male",
        Women: "female",
        Other: "other",
      };
      let chartData = [
        { gender: "Men", total: 0 },
        { gender: "Women", total: 0 },
        { gender: "Other", total: 0 },
      ];
      chartData.forEach((element) => {
        statsData.forEach((item) => {
          if (genderList[element.gender] === item._id) {
            element.total = item.count;
          }
        });
      });

      let totalGender = 0;
      for (let i = 0; i < statsData.length; i++) {
        totalGender = totalGender + statsData[i].count;
      }

      //gender Chart start
      let chart = am4core.create("chartGenderVariation", am4charts.PieChart3D);
      chart.data = chartData;
      let pi_title = chart.titles.create();
      pi_title.text = `Total: ${totalGender}`;
      pi_title.fontSize = 14;
      pi_title.align = "left";

      let genderTitle = chart.titles.create();
      genderTitle.text = `Gender variantion`;
      genderTitle.fontSize = 18;
      genderTitle.align = "center";

      let pieSeries = chart.series.push(new am4charts.PieSeries3D());
      pieSeries.dataFields.value = "total";
      pieSeries.dataFields.category = "gender";

      chart.legend = new am4charts.Legend();
      chart.exporting.menu = new am4core.ExportMenu();
      pieSeries.labels.template.text =
        "{category}: \n {value.percent.formatNumber('#.0')}% ";
      pieSeries.labels.template.fill = am4core.color("black");
      // Gender Chart END
      this.chart = chart;
    });

    analyticsService.getAppointmentRequestStats().then((data) => {
      // categoryWiseRegistration Start
      let chart2 = am4core.create("chartregistration", am4charts.PieChart);
      let dounut_title = chart2.titles.create();
      dounut_title.text = `Client Appointment Requests`;
      dounut_title.fontSize = 18;
      // Add data
      let dataSet = data.Data.map((group) => {
        return {
          program: programType[group._id],
          counts: group.count,
        };
      });
      chart2.data = dataSet;

      // Set inner radius
      chart2.innerRadius = am4core.percent(50);

      // Add and configure Series
      let pieSeries2 = chart2.series.push(new am4charts.PieSeries());
      pieSeries2.dataFields.value = "counts";
      pieSeries2.dataFields.category = "program";
      pieSeries2.slices.template.stroke = am4core.color("#fff");
      pieSeries2.slices.template.strokeWidth = 2;
      pieSeries2.slices.template.strokeOpacity = 1;

      // This creates initial animation
      pieSeries2.hiddenState.properties.opacity = 1;
      pieSeries2.hiddenState.properties.endAngle = -90;
      pieSeries2.hiddenState.properties.startAngle = -90;
      chart2.exporting.menu = new am4core.ExportMenu();
      // categoryWiseRegistration End
      this.chart2 = chart2;
    });

    let currentDateTime = moment();
    this.getNoShowsStats(
      currentDateTime.format("YYYY"),
      currentDateTime.format("MM")
    );

    this.getMonthlyAppointmentRecords(currentDateTime.format("YYYY"));

    analyticsService.getEventsPartcipantsStats().then((data) => {
      let statsData = data.Data;

      // Events Analysis Start

      let chart5 = am4core.create("eventParticipants", am4charts.XYChart);
      let limit = statsData.length > 6 ? 6 : statsData.length;
      let Data = [];

      for (let i = 0; i < limit; i++) {
        Data.push({
          event: statsData[i]._id,
          Registered: statsData[i].registered,
          Attended: statsData[i].attended,
          total: statsData[i].registered,
        });
      }

      console.log("Data: ", Data);

      chart5.data = Data;

      // Create axes
      let categoryAxis5 = chart5.xAxes.push(new am4charts.CategoryAxis());
      categoryAxis5.dataFields.category = "event";
      categoryAxis5.renderer.grid.template.location = 0;
      categoryAxis5.renderer.minGridDistance = 5;
      categoryAxis5.renderer.labels.template.rotation = 45;
      categoryAxis5.renderer.labels.template.fontSize = 13;
      categoryAxis5.renderer.labels.template.wrap = true;
      categoryAxis5.renderer.labels.template.maxWidth = 150;
      // categoryAxis5.renderer.labels.template.verticalCenter = "middle";
      categoryAxis5.renderer.labels.template.horizontalCenter = "left";
      // categoryAxis5.renderer.labels.template.adapter.add("dy", function(dy, target) {
      //     if (target.dataItem && target.dataItem.index & 2 == 2) {
      //         return dy ;
      //     }
      //     return dy;
      // });

      let title5 = chart5.titles.create();
      title5.text = `Event Participant's View`;
      title5.fontSize = 18;

      let valueAxis5 = chart5.yAxes.push(new am4charts.ValueAxis());
      // valueAxis5.renderer.inside = true;
      // valueAxis5.renderer.labels.template.disabled = true;
      valueAxis5.title.text = "Percent of Participants";

      valueAxis5.min = 0;

      // Create series
      const createSeries5 = (field, name, colorset) => {
        // Set up series
        let series1 = chart5.series.push(new am4charts.ColumnSeries());
        series1.name = name;
        series1.dataFields.valueY = field;
        series1.dataFields.categoryX = "event";
        series1.sequencedInterpolation = true;
        series1.fill = am4core.color(colorset);
        // Make it stacked
        series1.stacked = true;

        // Configure columns
        series1.columns.template.width = am4core.percent(60);
        series1.columns.template.tooltipText =
          "[bold]{name}[/]\n[font-size:14px]{categoryX}: {valueY}";

        // Add label
        let labelBullet1 = series1.bullets.push(new am4charts.LabelBullet());
        labelBullet1.label.text = "{valueY}";
        labelBullet1.locationY = 0.5;
        labelBullet1.label.hideOversized = true;

        return series1;
      };

      createSeries5("Registered", "Registered", "#67B7DB");
      createSeries5("Attended", "Attended", "#6691D7");

      // Legend
      chart5.legend = new am4charts.Legend();
      chart5.exporting.menu = new am4core.ExportMenu();
      //
      this.chart5 = chart5;

      // Events Analysis End
    });

    analyticsService.getClientLocationStats().then((data) => {
      let statData = data.Data;
      let colorSet = new am4core.ColorSet();
      let mapData = statData.map((elem) => {
        return {
          id: elem._id.code,
          name: elem._id.name,
          value: elem.count,
          fill: colorSet.next(),
        };
      });

      //  Map Start
      let chart6 = am4core.create("map", am4maps.MapChart);

      // let title6 = chart6.titles.create();
      // title6.text = `Client's Location`;
      // title6.align = "center";
      // title6.fontSize = 18;

      // Set map definition
      chart6.geodata = am4geodata_worldLow;

      // Set projection
      chart6.projection = new am4maps.projections.Miller();

      // Create map polygon series
      let polygonSeries = chart6.series.push(new am4maps.MapPolygonSeries());

      // Exclude Antartica
      polygonSeries.exclude = ["AQ"];

      // Make map load polygon (like country names) data from GeoJSON
      polygonSeries.useGeodata = true;
      polygonSeries.data = mapData;
      // Configure series
      let polygonTemplate = polygonSeries.mapPolygons.template;
      polygonTemplate.tooltipText = "{name}: {value}";
      polygonTemplate.polygon.fillOpacity = 0.6;
      polygonTemplate.propertyFields.fill = "fill";

      // Create hover state and set alternative fill color
      let hs = polygonTemplate.states.create("hover");
      hs.properties.fill = chart6.colors.getIndex(0);

      this.chart6 = chart6;
    });
  }

  getNoShowsStats = (year, month) => {
    analyticsService.noShowsStats(year, month).then((data) => {
      if (this.chart3) {
        this.chart3.dispose();
      }
      let stats = data.Data;
      let mappings = [
        {
          TimeSlot: "9:00-10:00",
          Time: "9:00",
          clients: 0,
        },
        {
          TimeSlot: "10:00-11:00",
          Time: "10:00",
          clients: 0,
        },
        {
          TimeSlot: "11:00-12:00",
          Time: "11:00",
          clients: 0,
        },
        {
          TimeSlot: "12:00-1:00",
          Time: "12:00",
          clients: 0,
        },
        {
          TimeSlot: "1:00-2:00",
          Time: "1:00",
          clients: 0,
        },
        {
          TimeSlot: "2:00-3:00",
          Time: "2:00",
          clients: 0,
        },
        {
          TimeSlot: "3:00-4:00",
          Time: "3:00",
          clients: 0,
        },
      ];

      let formattedData = mappings.map((element) => {
        if (stats.length) {
          for (let i = 0; i < stats.length; i++) {
            if (stats[i]._id === element.Time) {
              return {
                TimeSlot: element.TimeSlot,
                Time: element.Time,
                clients: stats[i].count,
              };
            }
          }
          return element;
        } else {
          return element;
        }
      });

      //preferable TimeSlot BarChart Start
      let chart3 = am4core.create("barchartTimeslot", am4charts.XYChart);

      let TimeSlotTitle = chart3.titles.create();
      TimeSlotTitle.text = `No Shows`;
      TimeSlotTitle.fontSize = 18;
      TimeSlotTitle.align = "center";

      // Add data
      chart3.data = formattedData;

      // Create axes

      let categoryAxis = chart3.xAxes.push(new am4charts.CategoryAxis());
      categoryAxis.dataFields.category = "TimeSlot";
      categoryAxis.renderer.grid.template.location = 0;
      categoryAxis.renderer.labels.template.rotation = 45;
      categoryAxis.renderer.labels.template.horizontalCenter = "left";
      // categoryAxis.renderer.labels.template.wrap = true;
      // categoryAxis.renderer.labels.template.maxidth = 60;
      categoryAxis.renderer.minGridDistance = 30;

      // categoryAxis.renderer.labels.template.adapter.add("dy", function (dy, target) {
      //     if (target.dataItem && target.dataItem.index & 2 == 2) {
      //         return dy + 25;
      //     }
      //     return dy;
      // });

      let valueAxis = chart3.yAxes.push(new am4charts.ValueAxis());
      valueAxis.title.text = "Number of Clients";

      // Create series
      let series = chart3.series.push(new am4charts.ColumnSeries());
      series.dataFields.valueY = "clients";
      series.dataFields.categoryX = "TimeSlot";
      series.name = "Visits";
      series.columns.template.tooltipText = "{categoryX}: [bold]{valueY}[/]";
      series.columns.template.fillOpacity = 0.8;

      let columnTemplate = series.columns.template;
      columnTemplate.strokeWidth = 2;
      columnTemplate.strokeOpacity = 1;
      chart3.exporting.menu = new am4core.ExportMenu();
      // preferable TimeSlot BarChat End

      this.chart3 = chart3;
    });
  };

  getMonthlyAppointmentRecords = (year) => {
    analyticsService.monthlyAppointmentRecords(year).then((data) => {
      if (this.chart4) {
        this.chart4.dispose();
      }
      let stats = data.Data;

      // LineChart Monthly Appointment Analysis Start
      let prepData = [];

      for (let i = 1; i <= 12; i++) {
        prepData.push({
          month: moment(`${i} ${year}`, "MM YYYY").format("YYYY-MM-DD"),
          count: 0,
        });
      }
      let chart4 = am4core.create(
        "LineChartMonthlyAppointment",
        am4charts.XYChart
      );
      let appintmentTitle = chart4.titles.create();
      appintmentTitle.text = `Monthly Appointment Records`;
      // appintmentTitle.paddingBottom = 0;
      appintmentTitle.align = "center";
      // appintmentTitle.align = 'center';
      // appintmentTitle.isMeasured = false;
      // appintmentTitle.x = 160;
      // appintmentTitle.y = 10;
      appintmentTitle.fontSize = 18;
      let formatedData = stats.map((item) => {
        let obj = {
          month: moment(`${item._id} ${year}`, "MM").format("YYYY-MM-DD"),
          count: item.count,
        };
        let month = parseInt(item._id);
        prepData.splice(month - 1, 1, obj);
        return obj;
      });

      chart4.data = prepData;
      let dateAxis = chart4.xAxes.push(new am4charts.DateAxis());

      dateAxis.renderer.grid.template.location = 0;
      dateAxis.renderer.minGridDistance = 50;
      let ValueAxis = chart4.yAxes.push(new am4charts.ValueAxis());
      ValueAxis.title.text = "Number of clients";
      // Create series
      let lineSeries = chart4.series.push(new am4charts.LineSeries());
      lineSeries.dataFields.valueY = "count";
      lineSeries.dataFields.dateX = "month";
      lineSeries.tooltipText = "{count}";
      lineSeries.tooltip.pointerOrientation = "vertical";
      chart4.cursor = new am4charts.XYCursor();
      chart4.cursor.snapToSeries = lineSeries;
      chart4.cursor.xAxis = dateAxis;
      //chart4.scrollbarY = new am4core.Scrollbar();
      chart4.scrollbarX = new am4core.Scrollbar();
      chart4.exporting.menu = new am4core.ExportMenu();
      // LineChart Monthly Appointment Analysis End
      this.chart4 = chart4;
    });
  };

  componentWillUnmount() {
    if (this.chart1) {
      this.chart1.dispose();
    }
    if (this.chart) {
      this.chart.dispose();
    }
    if (this.chart2) {
      this.chart2.dispose();
    }
    if (this.chart3) {
      this.chart3.dispose();
    }
    if (this.chart4) {
      this.chart4.dispose();
    }
    if (this.chart5) {
      this.chart5.dispose();
    }
    if (this.chart6) {
      this.chart6.dispose();
    }
  }
  years = getLastFiveYearsOptionList();

  fetchNoShowsStats = (date) => {
    let givenDate = moment(date);
    this.getNoShowsStats(givenDate.format("YYYY"), givenDate.format("MM"));
  };

  fetchMonthyAppointmentRecords = (year) => {
    this.getMonthlyAppointmentRecords(year);
  };
  render() {
    const { department } = this.state;

    return (
      <div id="main-wrapper" className="fix-header fix-sidebar card-no-border">
        {/* {this.state.loading && <Preloader />} */}
        <HeaderAdmin />
        <SidebarSuperAdmin />
        <div className="page-wrapper">
          <div className="container-fluid">
            <div className="row page-titles">
              <div className="col-md-5 col-8 align-self-center">
                <h3 className="text-themecolor m-b-0 m-t-0">Analytics</h3>
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <a href="#">Home</a>
                  </li>
                  <li className="breadcrumb-item active">Analytics</li>
                </ol>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6">
                <div className="pt-4">
                  <div className="card">
                    <div className="card-body">
                      <div className="graphContainer">
                        <div
                          id="chartdiv"
                          style={{ width: "100%", height: "400px" }}
                        ></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-md-6">
                <div className="pt-4">
                  <div className="card">
                    <div className="card-body">
                      <div>
                        <div
                          id="chartGenderVariation"
                          style={{ width: "100%", height: "400px" }}
                        ></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-md-12">
                <div className="">
                  <div className="card">
                    <div className="card-body">
                      <div>
                        <div
                          id="chartregistration"
                          style={{ width: "100%", height: "400px" }}
                        ></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-md-6">
                <div className="">
                  <div className="card">
                    <div className="card-body">
                      <div
                        style={{ display: "flex", justifyContent: "flex-end" }}
                      >
                        <Calendar
                          className="cursor_pointer"
                          value={this.state.date}
                          placeholder="Select a month"
                          onChange={(e) => {
                            this.setState({ date: e.value });
                            this.fetchNoShowsStats(e.value);
                          }}
                          view="month"
                          dateFormat="mm/yy"
                          yearNavigator
                          yearRange="2010:2030"
                        />
                      </div>
                      <div>
                        <div
                          id="barchartTimeslot"
                          style={{ width: "100%", height: "400px" }}
                        ></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-md-6">
                <div className="">
                  <div className="card">
                    <div className="card-body">
                      <div
                        className="graph_yearDropdown"
                        style={{ display: "flex", justifyContent: "flex-end" }}
                      >
                        <Dropdown
                          style={{ width: "32%" }}
                          value={this.state.year}
                          options={this.years}
                          onChange={(e) => {
                            this.setState({ year: e.value });
                            this.fetchMonthyAppointmentRecords(e.value);
                          }}
                          placeholder="Select  a year"
                        />
                      </div>
                      <div>
                        <div
                          id="LineChartMonthlyAppointment"
                          style={{ width: "100%", height: "400px" }}
                        ></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-md-12">
                <div className="">
                  <div className="card">
                    <div className="card-body">
                      <div>
                        <div
                          id="eventParticipants"
                          style={{ width: "100%", height: "500px" }}
                        ></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-md-12">
                <div className="">
                  <div className="card">
                    <div className="card-body">
                      <div
                        style={{
                          fontFamily: "Roboto",
                          fontSize: "18px",
                          marginBottom: "10px",
                          textAlign: "center",
                          color: "black",
                          fontWeight: "400",
                        }}
                      >
                        Client's Location
                      </div>
                      <div>
                        <div
                          id="map"
                          style={{ width: "100%", height: "400px" }}
                        ></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <FooterAdmin />
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Analytics);
