import React, { Component } from "react";
import { connect } from "react-redux";
import Preloader from "../../components/Preloader";
import HeaderAdmin from "../../components/HeaderAdmin";
import SidebarSuperAdmin from "../../components/admin/Sidebaar";
import FooterAdmin from "../../components/FooterAdmin";
import { Link } from "react-router-dom";
import { departmentService, siteLocationService } from "../../_services/admin";
import { authenticationService } from "../../_services";
import { setUserData } from "../../redux-store/action";
import Moment from "react-moment";
import { MDBDataTable } from "mdbreact";
import moment from "moment";
import DeleteConfirmation from "../../shared/DeleteConfirmation";
import swal from "sweetalert";
function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {
    setUserData: (data) => {
      dispatch(setUserData(data));
    },
  };
}

class Departments extends Component {
  constructor(props) {
    super(props);
    this.delete = this.delete.bind(this);

    this.state = {
      currentUser: authenticationService.currentUserValue,
      department: [],
      siteLocations: [],
      loading: true,
    };
  }
  componentDidMount() {
    this.getAllDepartments();
    siteLocationService
      .getAll()
      .then(
        (data) =>
          data &&
          data.Data &&
          (this.setState({ siteLocations: data.Data }) ||
            this.getAllDepartments())
      );
    this.props.setUserData(this.state.currentUser);
  }
  getAllDepartments() {
    departmentService.getAll().then((data) => {
      let rowsArray = [];
      data &&
        data.Data &&
        data.Data.map((item, i) => {
          let siteName = [];
          item.siteLocation.filter((k) => {
            this.state.siteLocations.filter((t) => {
              if (t._id.indexOf(k) !== -1) siteName.push(t.name);
            });
          });
          rowsArray.push({
            key: i + 1,
            // createdAt: <Moment format="MM/DD/YYYY">{item.createdAt}</Moment>,
            createdAt: moment(item.createdAt, "x").format("MM/DD/YYYY"),
            department: item.name,
            siteLocation: siteName.join(", "),
            subdepatment: (
              <div className="">
                <Link
                  to="/admin/sub-department/create"
                  className="btn btn-sm btn-primary"
                >
                  <i className="mdi mdi-account-plus"></i>{" "}
                  <span className="hide-menu">Create Sub Department</span>
                </Link>
              </div>
            ),
            action: !item.isStatic && (
              <React.Fragment>
                <Link
                  to={"/admin/department/edit/" + item._id}
                  data-toggle="tooltip"
                  data-original-title="Edit"
                >
                  {" "}
                  <i className="fas fa-pencil-alt text-inverse m-r-10"></i>{" "}
                </Link>
                <a
                  onClick={() => document.getElementById(item._id).click()}
                  data-toggle="tooltip"
                  data-original-title="Delete"
                >
                  {" "}
                  <i className="fas fa-trash text-danger"></i>
                </a>
                <Link
                  to={"/admin/department/" + item._id + "/sub-departments"}
                  data-toggle="tooltip"
                  data-original-title="View Sub Departments"
                >
                  {" "}
                  <i className="fas fa-eye text-inverse m-r-10"></i>{" "}
                </Link>
                <DeleteConfirmation
                  id={item._id}
                  confirm={() => this.delete(item._id)}
                ></DeleteConfirmation>
              </React.Fragment>
            ),
          });
          return true;
        });
      const tableData = {
        columns: [
          // {
          //     label: '#',
          //     field: 'key',
          //     sort: 'asc',
          //     width: 270
          // },
          {
            label: "Department",
            field: "department",
            sort: "asc",
            width: 150,
          },
          {
            label: "Site Location",
            field: "siteLocation",
            sort: "asc",
            width: 150,
          },
          {
            label: "Created At",
            field: "createdAt",
            sort: "asc",
            width: 270,
          },
          {
            label: "Sub Department",
            field: "subdepatment",
            sort: "asc",
            width: 270,
          },
          {
            label: "Action",
            field: "action",
            sort: "disabled",
            width: 270,
          },
        ],
        rows: rowsArray,
      };
      this.setState({ department: tableData, loading: false });
    });
  }
  delete(departmentId) {
    departmentService
      .deleteDepartment(departmentId, this.state.currentUser.id)
      .then((response) => {
        response.Status && this.getAllDepartments();
        swal("Department Deleted Succesfully!", "", "success");
      })
      .catch((err) => {
        console.log(err);
        swal("Oops Something Went Wrong!", "", "error");
      });
  }
  render() {
    const { department } = this.state;

    return (
      <div id="main-wrapper" className="fix-header fix-sidebar card-no-border">
        {this.state.loading && <Preloader />}
        <HeaderAdmin />
        <SidebarSuperAdmin />
        <div className="page-wrapper">
          <div className="container-fluid">
            <div className="row page-titles">
              <div className="col-md-5 col-8 align-self-center">
                <h3 className="text-themecolor m-b-0 m-t-0">Department</h3>
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <a href="#">Home</a>
                  </li>
                  <li className="breadcrumb-item active">Department</li>
                </ol>
              </div>
              <div className="col-md-7 col-4 align-self-center text-right">
                <Link to="/admin/department/create" className="btn btn-primary">
                  <i className="mdi mdi-account-plus"></i>{" "}
                  <span className="hide-menu">Create Department</span>
                </Link>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <div className="pt-4">
                  <div className="card">
                    <div className="card-body">
                      <MDBDataTable
                        responsive
                        id="department_table"
                        striped
                        bordered
                        hover
                        data={department}
                      />
                      {/* <table className="table table-bordered">
                                        <thead>
                                            <tr>
                                                <th>Department</th>
                                                <th>Access</th>
                                                <th>Created At</th>
                                                <th>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                department.map((dep, index) => 
                                                <tr key={index}>
                                                    <td>{dep.name}</td>
                                                    <td>{(dep.accessType === 1) ? 'Read' : 'ReadWrite'}</td>
                                                    <td><Moment format="YYYY-MM-DD hh:mm:ss a">{dep.createdAt}</Moment></td>
                                                    <td className="text-nowrap">
                                                        <Link to={"/admin/department/edit/"+dep._id} data-toggle="tooltip" data-original-title="Edit"> <i className="fas fa-pencil-alt text-inverse m-r-10"></i> </Link>
                                                        <a onClick={()=>this.delete(dep._id)} data-toggle="tooltip" data-original-title="Delete"> <i className="fas fa-trash text-danger"></i> </a>
                                                    </td>
                                                </tr>
                                                )
                                            }
                                        </tbody>
                                    </table> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <FooterAdmin />
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Departments);
