/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from "react";
import { connect } from "react-redux";
import Preloader from "../../components/Preloader";
import HeaderAdmin from "../../components/HeaderAdmin";
import SidebarSuperAdmin from "../../components/admin/Sidebaar";
import Sidebaar from "../../components/user/Sidebaar";
import FooterAdmin from "../../components/FooterAdmin";
import { Link } from "react-router-dom";
import {
  userService,
  departmentService,
  subDepartmentService,
} from "../../_services/admin";
import { authenticationService } from "./../../_services";
import { setUserData } from "../../redux-store/action";
import Moment from "react-moment";
import { MDBDataTable } from "mdbreact";
import { setLoader } from "../../redux-store/action";
import Loader from "react-loader-spinner";
import moment from "moment";
import DeleteConfirmation from "../../shared/DeleteConfirmation";
import { Role } from "../../_helpers";
import swal from "sweetalert";
function mapStateToProps(state) {
  return {
    isLoading: state.isLoading,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setLoader: (data) => {
      dispatch(setLoader(data));
    },
    setUserData: (data) => {
      dispatch(setUserData(data));
    },
  };
}

class RegisteredUsers extends Component {
  constructor(props) {
    super(props);
    this.delete = this.delete.bind(this);

    this.state = {
      currentUser: authenticationService.currentUserValue,
      users: [],
      loading: true,
      departments: [],
      subDepartments: [],
    };
  }
  componentDidMount() {
    this.getAllUsers();
  }

  getAllUsers() {
    this.props.setLoader(true);
    userService.getRegisteredUserList().then((data) => {
      let rowsArray = [];
      data &&
        data.Data &&
        data.Data.map((user, i) => {
          rowsArray.push({
            key: i + 1,
            firstName: user?.firstName || "",
            lastName: user?.lastName || "",
            email: user?.email || "",
            gender: user?.gender || "",
            createdAt: moment(user?.createdAt, "x").format("MM/DD/YYYY"),
            action: (
              <React.Fragment>
                <a
                  onClick={() => document.getElementById(user._id).click()}
                  data-toggle="tooltip"
                  data-original-title="Delete"
                >
                  {" "}
                  <i className="fas fa-trash text-danger"></i>{" "}
                </a>
                <DeleteConfirmation
                  id={user._id}
                  confirm={() => this.delete(user._id)}
                ></DeleteConfirmation>
              </React.Fragment>
            ),
          });

          return true;
        });
      const tableData = {
        columns: [
          {
            label: "First Name",
            field: "firstName",
            sort: "asc",
            width: 150,
          },
          {
            label: "Last Name",
            field: "lastName",
            sort: "asc",
            width: 270,
          },
          {
            label: "Email",
            field: "email",
            sort: "asc",
            width: 270,
          },
          {
            label: "Gender",
            field: "gender",
            sort: "asc",
            width: 270,
          },

          {
            label: "Created At",
            field: "createdAt",
            sort: "asc",
            width: 270,
          },
          {
            label: "Action",
            field: "action",
            sort: "disabled",
            width: 270,
          },
        ],
        rows: rowsArray,
      };
      this.setState({ users: tableData, loading: false });
      this.props.setLoader(false);
    });
  }
  delete(userId) {
    userService
      .deleteRegisteredUser(userId, this.state.currentUser.id)
      .then((response) => {
        response.Status && this.getAllUsers();
        swal("User Deleted Succesfully!", "", "success");
      })
      .catch((err) => {
        console.log(err);
        swal("Opps Something Went Wrong!", "", "error");
      });
  }
  render() {
    const { users } = this.state;
    const { isLoading } = this.props;

    return (
      <React.Fragment>
        {isLoading && (
          <div id="loaderRoot">
            <div className="loaderPositionsStyle">
              <div className="loaderPositionsStyleSub">
                <Loader
                  type="Rings"
                  sty
                  color="#6f42c1"
                  height={80}
                  width={80}
                />
              </div>
            </div>
          </div>
        )}
        {!isLoading && (
          <div
            id="main-wrapper"
            className="fix-header fix-sidebar card-no-border"
          >
            {this.state.loading && <Preloader />}
            <HeaderAdmin />
            {this.state.currentUser.role === Role.Admin ? (
              <SidebarSuperAdmin />
            ) : (
              <Sidebaar />
            )}
            <div className="page-wrapper">
              <div className="container-fluid">
                <div className="row page-titles">
                  <div className="col-md-5 col-8 align-self-center">
                    <h3 className="text-themecolor m-b-0 m-t-0">
                      Registered Users
                    </h3>
                    <ol className="breadcrumb">
                      <li className="breadcrumb-item">
                        <a href="/">Home</a>
                      </li>
                      <li className="breadcrumb-item active">
                        {" "}
                        Registered Users
                      </li>
                    </ol>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12">
                    <div className="pt-4">
                      <div className="card">
                        <div className="card-body">
                          {/* <div className="table-responsive"> */}
                          <MDBDataTable
                            responsive
                            striped
                            bordered
                            hover
                            data={users}
                          />
                          {/* </div> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <FooterAdmin />
            </div>
          </div>
        )}
      </React.Fragment>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(RegisteredUsers);
